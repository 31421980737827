import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../templates/Auth/config";

import firebase from "firebase";

export interface User {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  photoURL: string | null;
  uid: string;
  phoneNumber: string | null;
  accessToken?: string;
  metadata: firebase.auth.UserMetadata;
  multiFactor: firebase.User.MultiFactorUser;
}

export const useAuthListener = (): any => {
  // assume user to be logged out
  const [loggedIn, setLoggedIn] = useState(false);
  // assume user does not have verified email
  const [emailVerified, setEmailVerified] = useState(false);
  // assume user does not have multifactor

  // keep track to display a spinner while auth status is being checked
  const [checkingStatus, setCheckingStatus] = useState(true);
  const [user, setUser] = useState<any>();
  const [sessionUser, setSessionUser] = useState<any>();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // auth listener to keep track of user signing in and out
    auth.onAuthStateChanged(async (user) => {
      // console.log(user);

      if (user) {
        // console.log(user);
        // console.log(user.emailVerified);
        // console.log(user.multiFactor.enrolledFactors);
        setEmailVerified(user.emailVerified);
        setLoggedIn(true);

        /**Get general informations about a user */
        const {
          displayName,
          email,
          emailVerified,
          photoURL,
          uid,
          phoneNumber,
          metadata,
          multiFactor,
        }: User = user;

        /**Get ID Token user */
        user.getIdToken().then(async (idToken) => {
          setUser({
            displayName,
            email,
            emailVerified,
            photoURL,
            uid,
            phoneNumber,
            metadata,
            multiFactor,
            accessToken: idToken,
          });
        });
      } else {
        auth.signOut();
        navigate("/login");
      }

      setCheckingStatus(false);
    });

    return () => {
      setSessionUser(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Update the session user when the page is changed! */
  useEffect(() => {
    async function getSessionUser() {
      // Request to get session user
      await axios
        .get(`${process.env.REACT_APP_AUTH_SERVICE}/api/users/session`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`
          },
        })
        .then(function (response) {
          const sessionUser = response.data;
          setSessionUser(sessionUser);
        })
        .catch(function (error) {
          console.log("useAuthListener getSessionUser | Failed to get user session.")
          auth.signOut();
          navigate("/login");
        });
    }

    if (user !== undefined) {
      getSessionUser();
    }
  }, [user, location.pathname, navigate]);

  return {
    loggedIn,
    emailVerified,
    checkingStatus,
    user,
    sessionUser,
  };
};
