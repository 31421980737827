import { DragIndicator } from '@mui/icons-material';
import { SortableHandle } from 'react-sortable-hoc';

export const DragHandle = SortableHandle(()=>{
    return(
        <div className="DragIndicator">
            <DragIndicator 
                sx={{
                    fontSize: 22
                }}
            />
        </div>
    );
})