import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../routes/admin';
import * as Styled from './styles';

export const AdminFooter = () => {

    const { user } = useContext(AuthContext);

    const year = new Date().getFullYear(); 

    const handleClick = async (e:any) => {

        await axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/docusign/signedUrl/terms-of-service`,{
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res)=> {

            const { url } = res.data; 

            window.open(url, '_blank'); 

        })

    }

    return (
        <Styled.Container>
            GIS <br/>
            &copy; Casa dos Ventos {year===2021?year:"2021-"+year}<br/>
            <div id="policies" style={{cursor: 'pointer'}} onClick={handleClick}>Terms of services</div> 
            {/* <a href="/terms" target="_blank">Policies and use of data</a> */}
        </Styled.Container>
    );
};