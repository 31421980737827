import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        .MuiDataGrid-columnHeaderWrapper{
            background: ${theme.colors.grayBg};
        }

        .MuiDataGrid-root{
            background: ${theme.colors.primary};
        }
    `}
`;
