export const STYLES_LANDS:any = {
    '0':'#00cc33',
    '1':'#0000aa',
    '2':'#cccccc'
}

export const STYLES_AUT_GEO_CUSTOMERS:any = {
    "3": {
        color: '#0000ff',
        dashArray: undefined
    },
    "1": {
        color: '#ff9933',
        dashArray: undefined
    },
    "2": {
        color: '#ff9933',
        dashArray: '4 4'
    },
    "4": {
        color: '#cccccc',
        dashArray: undefined
    }
}

// export const STYLES_AUT_GEO_AS_BIG:any = {
//     "0|0": {
//         color: '#bfbfbf',
//         dashArray: undefined
//     },
//     "4|3": {
//         color: '#ff0000',
//         dashArray: undefined
//     },
//     "4|2": {
//         color: '#ff0000',
//         dashArray: '4 4'
//     },
//     "4|1": {
//         color: '#ff5555',
//         dashArray: '4 4'
//     },
//     "1|3": {
//         color: '#ff9933',
//         dashArray: undefined
//     },
//     "1|2": {
//         color: '#ff9933',
//         dashArray: '4 4'
//     },
//     "1|1": {
//         color: '#ffbb66',
//         dashArray: '4 4'
//     },
//     "2|0": {
//         color: '#0000ff',
//         dashArray: '4 4'
//     },
//     "3|0": {
//         color: '#0000ff',
//         dashArray: undefined
//     }
// }





