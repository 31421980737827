import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #user-title-info{
            font-size: ${theme.fonts.sizes.small};
            padding: 0 20px; 
        }
    `}
`;
