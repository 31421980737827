import * as Styled from './styles';

import {
    Button, 
    ButtonProps
} from '@mui/material';

import {
    DeleteOutlined
} from '@mui/icons-material';

interface DeleteButtonProps {
    onClick: ButtonProps["onClick"];
    disabled: ButtonProps["disabled"];
};

export const DeleteButton = ({
    onClick,
    disabled
}:DeleteButtonProps) => {

    return (
        <Styled.Container>
            <Button disabled={disabled} onClick={onClick} id="btn-remove">
                <DeleteOutlined /> &nbsp; Delete
            </Button>
        </Styled.Container>
    );

}