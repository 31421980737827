import * as Styled from './styles';

import {
    List,
    ListItemButton,
    ListItemText
} from '@mui/material';

import {
    Link,
    useLocation
} from 'react-router-dom';

export const Navbar = () => {

    const location = useLocation();

    const checkIfSelected = (pathname:string) => {
        return location?.pathname.includes(pathname)
    }

    return (
        <Styled.Container>
            <List style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0
            }}>
                
                <ListItemButton
                    selected={checkIfSelected('home')}
                >
                    <ListItemText primary={<Link to="/home">Home</Link>} />
                </ListItemButton>
                <ListItemButton
                    selected={checkIfSelected('map')}
                >
                    <ListItemText primary={<Link to="/map">Map</Link>} />
                </ListItemButton>
                {/* <ListItemButton
                    selected={checkIfSelected('reports')}
                >
                    <ListItemText primary={<Link to="/reports">Reports</Link>} />
                </ListItemButton> */}
                <ListItemButton
                    selected={checkIfSelected('contact')}
                >
                    <ListItemText primary={<Link to="/contact">Contact</Link>}/>
                </ListItemButton>
                <ListItemButton
                    selected={checkIfSelected('guide')}
                >
                    <ListItemText primary={<Link to="/guide">Guide</Link>} />
                </ListItemButton>
            </List>
        </Styled.Container>
    );

}