import $ from "jquery";

import firebase from 'firebase/app';
import 'firebase/auth';
import { CnivelLegend } from "./legend/cnivel";
import { AergLegend } from "./legend/aerg";
import { ContractsLegend } from "./legend/ctrt";
import { LandsLegend } from "./legend/lands";

export class LeafletControler {

    private map:any; 
    private target: string; 
    private configTxt?: string; 
    private config?:any; 
    
    private label?: string; 
    private legend?:any; 

    constructor(map:any, target: string, config?:string, label?:string){
        this.map = map;
        this.target = target; 
        this.configTxt = config; 
        this.label = label;

        if(this.configTxt!==undefined){
            this.config = JSON.parse(this.configTxt);
        }

        if(this.isActive()){
            this.addLayerToMap();
        }
    }

    public async createLegend(){

        const name = this.config?.name?this.config?.name:this.target; 

        this.legend = $(document.createElement('div'));
        this.legend.attr('id', `divLegendLayers-${name}`);
        this.legend.css({
            'margin-top':'4px',
            'padding':'6px',
            'border':'solid 1px lightgray'
        })

        switch(name){

            case 'ortofotos':
                break;
            case 'newprop':
                // $(`<b>${this.label}</b><br>`).appendTo(this.legend);
                $(this.legend).html(LandsLegend); 
                break;
            case 'ctrt':
                // $(`<b>${this.label}</b><br>`).appendTo(this.legend);
                $(this.legend).html(ContractsLegend); 
                break;
            case 'cnivel':
                $(this.legend).html(CnivelLegend);
                break;
            case 'aerg':
                // $(`<b>${this.label}</b><br>`).appendTo(this.legend);
                $(this.legend).html(AergLegend);
                break;
            default: 

                $(`<div style="background-color:lightgray;text-align:center;"><b style="padding:2px;">${this.label}</b><br><div>`).appendTo(this.legend);

                const token = await firebase.auth().currentUser?.getIdToken();

                const server:any = process.env.REACT_APP_PROXY_GEOSERVER;
                const link = `${server.replace('-{s}', '')}/wms/legend?ws=${this.config?.ws}&name=${this.config?.name}&access_token=${token}`;
                $('<img/>',{
                    id: this.config?.name,
                    src: link
                })
                .appendTo(this.legend);

                break; 
                
        }

    
    }

    public async addLayerToMap () {
        
        //Layers accessed by config name - Geoserver and general layers
        if(!this.map.hasLayer(this.map[this.config?.name])){
            if(this.map[this.config?.name]){
                const type = this.map[this.config?.name]?.wmsParams!==undefined?'wms':'wts'; 

                const token = await firebase.auth().currentUser?.getIdToken();

                switch(type) {
                    case 'wms':
                        
                        const wmsLayer = this.map[this.config?.name]; 
                    
                        wmsLayer._url = wmsLayer._url.split("?")[0]; 

                        wmsLayer._url = wmsLayer._url.concat(`?access_token=${token}`);

                        break; 
                    case 'wts':
                        
                        const wtsLayer = this.map[this.config?.name];

                        wtsLayer._url = wtsLayer._url.split("?")[0]; 

                        wtsLayer._url = wtsLayer._url.concat(`?access_token=${token}`);

                        break; 
                    default:
                        console.log("Treat exceptions!")

                }

                this.map[this.config?.name].addTo(this.map);
                /** Check if the layers is already added on the array */
                if(this.map._state.layers.indexOf(this.target)===-1){
                    this.map._state.layers.push(this.target);
                }
                this.addLegend();

            }
        }

        //Layers accessed by target (exceptions!!!)
        if(!this.map.hasLayer(this.map[this.target])){
            if (this.map[this.target]){ //Control: Wind Turbines, Lands and Contracts
                this.map[this.target].addTo(this.map);
                /** Check if the layers is already added on the array */
                if(this.map._state.layers.indexOf(this.target)===-1){
                    this.map._state.layers.push(this.target);
                }
                this.addLegend();
            }
        }

        if(this.map._state.mode === "group"){
            this.setGroupedLayersActiveOnScreen();
        }
    }

    public addLegend(){
        if(this.map.hasLayer(this.map[this.config?.name])){

            this.createLegend();

            $('#divLegenda').append(this.legend);
            $('#semLegendas').addClass('oculta'); 
        } else if (this.map[this.target]){

            this.createLegend();

            $('#divLegenda').append(this.legend);
            $('#semLegendas').addClass('oculta');

        }
    }

    public removeFromMap () {
        if(this.map.hasLayer(this.map[this.config?.name])){
            this.removeLegend();
            this.map.removeLayer(this.map[this.config?.name]);

            const index = this.map._state.layers.indexOf(this.target);
            if (index > -1){
                this.map._state.layers.splice(index, 1);
            }
        } else if (this.map.hasLayer(this.map[this.target])){
            this.removeLegend();
            this.map.removeLayer(this.map[this.target]);

            const index = this.map._state.layers.indexOf(this.target);
    
            if(index > -1){
                this.map._state.layers.splice(index, 1);
            }

        }
        if(this.map._state.mode === "group"){
            this.setGroupedLayersActiveOnScreen();
        }
    }

    public removeLegend(){
        if(this.map.hasLayer(this.map[this.config?.name])){
            $(`#divLegendLayers-${this.config?.name}`).remove();
            if($('#divLegenda').children().length === 0) {
                $('#semLegendas').removeClass('oculta');
            }
        } else if (this.map.hasLayer(this.map[this.target])){
            $(`#divLegendLayers-${this.target}`).remove();
            if($('#divLegenda').children().length === 0) {
                $('#semLegendas').removeClass('oculta');
            }
        }
    }


    /**Set the opacity when changed in the slider - number between 0 and 1*/
    public setOpacity (value:number) {

        switch (this.target){
            /**Basemap Control! */
            case "base":
                this.map[this.target].setOpacity(value);
                this.map._state.opacity[this.map.base.options.id] = value; 
                break; 
            /**Treat exception, when txtCode isn't set */
            case "newprop":
                this.map.getPane('newprop').style.opacity = value; 
                this.map._state.opacity[this.target] = value; 
                break; 
            case "ctrt":
                this.map.getPane('ctrt').style.opacity = value; 
                this.map._state.opacity[this.target] = value; 
                break; 
            case "aerg":
                this.map.getPane('aerg').style.opacity = value; 
                this.map._state.opacity[this.target] = value; 
                break; 
            /**If exist a created layer, control opacity of general layers! */
            default:
                if(this.map[this.config?.name] && this.config?.name){      
                    this.map[this.config.name].setOpacity(value);
                    this.map._state.opacity[this.config.name] = value; 
                }
        }
            
    }

    public getOpacity() {
        if(this.target === "base"){ //This must be the first because of config.name
            return this.map.base.options.opacity; 
        } else if(this.map._state.opacity[this.config.name]!==undefined){
            return this.map._state.opacity[this.config.name]; 
        } else if (this.map._state.opacity[this.target]!==undefined) {
            return this.map._state.opacity[this.target]; 
        } else {
            return 1
        }
    }

    public isActive (){

        if(this.map._state.layers.indexOf(this.target)!==-1){
            return true; 
        }else {
            return false; 
        }

    }

    public setLayerAsFavorite(value:boolean){

        if(value){
            if(this.map._state.favoriteLayers.indexOf(this.target)===-1){
                this.map._state.favoriteLayers.push(this.target);
            }
        } else {
            const index = this.map._state.favoriteLayers.indexOf(this.target);
    
            if(index > -1){
                this.map._state.favoriteLayers.splice(index, 1);
            }
        }

    }

    public isFavorite() {

        if(this.map._state.favoriteLayers.indexOf(this.target)!==-1){
            return true; 
        }else {
            return false; 
        }

    }

    public setGroupedLayersActiveOnScreen() {

        const layerTarget =  this.map._state.listOrder.find((oneLayer:any)=>oneLayer.txt_code===this.target);

        const groupId = layerTarget.group; 

        const activeLayersTxtCode = this.map._state.layers; 

        const groupedItensActive = this.map._state.groupedLayersOrder[groupId].filter((oneLayer:any)=> activeLayersTxtCode.indexOf(oneLayer.txt_code)!==-1)

        if(groupedItensActive.length > 0){
            $(`#number-active-group-${groupId}`).empty(); 
            $(`#number-active-group-${groupId}`).append(`${groupedItensActive.length}/`)
        } else {
            $(`#number-active-group-${groupId}`).empty();
        }

    }

}