import { useState } from 'react'; 
import * as Styled from './styles';

import {
    Stack,
    styled,
    Paper,
    IconButton,
    Collapse,
    Grid,
    Typography
} from '@mui/material';

import {
    ArrowRight,
    ArrowDropDown
} from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

interface CollapseComponentProps {
    title: string, 
    defaultState?: boolean,
    children: any
}

export const CollapseComponent = ({
    title,
    defaultState, 
    children
}:CollapseComponentProps) => {

    const [open, setOpen] = useState(defaultState)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Styled.Container>
            <Stack direction="row" spacing={1} id="top-tab-collapse">
                <Item id="tab-component-title" elevation={0} >
                    <IconButton
                        onClick={handleClick}
                    >
                        {open ? <ArrowDropDown /> : <ArrowRight />}
                    </IconButton>
                </Item>
                <Item id="tab-component-title" elevation={0} >
                    <Typography gutterBottom variant="subtitle1" component="div" id="user-title-component">
                        {title}
                    </Typography>
                </Item>
            </Stack>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid id="list-component" container spacing={0}>
                    <Grid item xs={12}>
                        {children?children:""}
                    </Grid>
                </Grid>
            </Collapse> 
        </Styled.Container>
    );
}