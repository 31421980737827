import * as Styled from './styles';
import { theme } from '../../styles/theme';

import { 
    Grid,
    Typography,
    Paper
} from '@mui/material';
import { AuthContext } from '../../routes/admin';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface PrivacyPolicyProps {
    ds_envelopeId?: string;
}

export const PrivacyPolicy = ({
    ds_envelopeId
}:PrivacyPolicyProps) => {

    const { user } = useContext(AuthContext);

    const userId = useParams()?.user; 

    const handleClick = async (e:any) => {

        await axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/docusign/signedUrl/${e.target.id}/${userId}`,{
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res)=> {

            const { url } = res.data; 

            window.open(url, '_blank'); 

        })

    }

    return (
        <Styled.Container>
            <Typography id="title" component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                &nbsp; Privacy and Policies
            </Typography>
                <Paper id="paper-admin" elevation={3}>
                <Grid container spacing={2}>
                    {ds_envelopeId !== 'casadosventos.com.br'?
                        <>
                            {ds_envelopeId?
                                <>
                                <Grid item xs={12}>
                                    <Typography id="content" component="div">
                                        <div id="terms-of-service" style={{cursor: 'pointer'}} onClick={handleClick}>Policies and use of data</div>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography id="content" component="div">
                                        <div id="certificate-conclusion" style={{cursor: 'pointer'}} onClick={handleClick}>Certificate of conclusion</div>
                                    </Typography>
                                </Grid>
                                {/* <Grid item xs={12}>
                                        <Typography id="content" component="div">
                                            <div id="policies" >General Data Protection Regulation (GDPR)</div>
                                        </Typography>
                                    </Grid> */}
                                </>
                                :
                                <Grid item xs={12}>
                                    <Typography id="content" component="div">
                                        <div id="terms-of-service">No envelopes assigned yet.</div>
                                    </Typography>
                                </Grid>
                            }
                        </>
                        :
                        <Grid item xs={12}>
                            <Typography id="content" component="div">
                                <div id="terms-of-service">User has domain casadosventos.com.br</div>
                            </Typography>
                        </Grid>
                    }
                </Grid>
                </Paper>
            
        </Styled.Container>
    );

}