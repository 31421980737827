import * as Styled from './styles';

import {
    Box, 
    Stepper,
    Step,
    StepLabel,
    StepperProps
} from '@mui/material';

interface HorizontalLinearStepperProps {
    steps: Array<string>;
    activeStep: StepperProps["activeStep"];
    skipped: any;
}

export const HorizontalLinearStepper = ({
    steps,
    activeStep,
    skipped
}:HorizontalLinearStepperProps) => {


    return (
        <Styled.Container>
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const labelProps = {};

                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </Styled.Container>
    );
}