import * as Styled from './styles';

import { 
            Button, 
            ButtonProps, 
            Dialog, 
            DialogActions, 
            DialogContent,
            DialogContentText,
            DialogProps,
            DialogTitle 
        } from "@mui/material";

interface AlertDialogProps {
    open: boolean;
    title: string;
    content: string | any;
    textAcceptButton?: string; 
    textRefuseButton?: string;
    textAgreeButton: string; 
    textDisagreeButton: string; 
    disableAgreeButton?: boolean;
    onClickAgree: ButtonProps["onClick"];
    onClickDisagree: ButtonProps["onClick"];
    onClose: DialogProps["onClose"];
}

export const AlertDialog = ({open, onClickAgree, onClickDisagree, onClose, title, content, textAgreeButton, textDisagreeButton, disableAgreeButton }:AlertDialogProps) => {

    return (
        <Styled.Container>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={onClickDisagree} >
                    {textDisagreeButton}
                </Button>
                <Button onClick={onClickAgree} disabled={disableAgreeButton} autoFocus>
                    {textAgreeButton}
                </Button>
                </DialogActions>
            </Dialog>
        </Styled.Container>
    );

}