import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Box,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    Divider
} from '@mui/material';

import { Navigate } from 'react-router-dom';

import axios from 'axios';
import { InfoSelectedViews, Views } from '../../models/Views';

import { CollapseComponent } from '../CollapseComponent';
import { StepsController } from '../StepsController';
import { AuthContext } from '../../routes/admin';

interface ViewRegistrationStepThreeProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info?: any;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    id: string|null; 
    edit: boolean;
}

export const ViewRegistrationStepThree = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    id,
    edit
}:ViewRegistrationStepThreeProps) => {

    const context:any = useContext(AuthContext);

    const [ token, setUser ]= useState(context.user?.accessToken);

    const [ tempValue, setTempValue ] = useState(info);
    const [ enableNext, setEnableNext ] = useState(false);

    const [roles, setRoles] = useState();
    const [views, setViews] = useState();
    const [redirect, setRedirect] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);

    const filteredLayers = tempValue?.allLayers.filter((oneLayer:any)=> tempValue?.layers?.indexOf(oneLayer.id)!== -1)

    const filteredFarms = tempValue?.allFarms.filter((oneFarm:any)=> tempValue?.farms?.indexOf(oneFarm.id)!== -1)

    const filteredComplex = tempValue?.allComplex.filter((oneComplex:any)=> tempValue?.complex?.indexOf(oneComplex.id)!== -1)

    const filteredSite = tempValue?.allSites.filter((oneSite:any)=> tempValue?.site?.indexOf(oneSite.id)!== -1)
    
    async function sendRequestCreate (){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/views';

        if(!sentRequest){
            setSentRequest(true);
            axios.post(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a view!")
                })
            ;
        }
    }

    async function sendRequestUpdate(){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/views/'+id;

        if(!sentRequest){
            setSentRequest(true);

            axios.put(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a view!")
                    setSentRequest(false)
                });
        }
    }

    return (
        <Styled.Container>
            <Box
                component="form"
                autoComplete="off"
                noValidate
            >
            <div id='title-create-group'>Review your created view</div>
            <Paper elevation={3} id="form-admin">
               <Grid container spacing={2}>
                    <Grid item xs>
                        <Typography gutterBottom component="div">
                            General Info
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            ID: {tempValue?.id}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Title: {tempValue?.title}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Description: {tempValue?.description}
                        </Typography>
                        <div id="space"/>
                        <Divider />
                        <div id="space"/>
                        <Typography gutterBottom component="div">
                            Permissions
                        </Typography>
                        <CollapseComponent 
                            title="Layers"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {filteredLayers!==undefined&&filteredLayers.length>0?filteredLayers.map((permission:any)=>{
                                                return <ListItem key={`filtered-layers-${permission.name}`}>{permission.name}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}                     
                        />
                        <CollapseComponent 
                            title="Complex"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {filteredComplex!==undefined&&filteredComplex.length>0?filteredComplex?.map((permission:any)=>{
                                                return <ListItem key={`filtered-complex-${permission.name}`}>{permission.name}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />
                        <CollapseComponent 
                            title="Farms"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {filteredFarms!==undefined&&filteredFarms.length>0?filteredFarms?.map((permission:any)=>{
                                                return <ListItem key={`filtered-farms-${permission.name}`}>{permission.name}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />
                        <CollapseComponent 
                            title="Wind Turbines"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {filteredSite!==undefined&&filteredSite.length>0?filteredSite?.map((permission:any)=>{
                                                return <ListItem key={`filtered-aergs-${permission.aerg1CompName}`}>{permission.aerg1CompName}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />
                    </Grid>
                </Grid> 
            </Paper>
            </Box> 
            <StepsController 
                edit={edit}
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                enableCreate={!redirect}
                tempValue={tempValue}
                sendRequestCreate={edit?sendRequestUpdate:sendRequestCreate}
            />
            {redirect?
                <Navigate to={hrefOrig}/>
                :
                ""
            }
        </Styled.Container>
    );

}