import { useContext, useState, useEffect } from "react";

import { bigFarms, Farms } from "../../models/Farms";

import axios from 'axios';
import { Button, Grid, Typography } from "@mui/material";
import { Table } from "../Table";
import { bigComplex } from "../../models/Complex";
import { bigLayers } from "../../models/Layers";
import { AuthContext } from "../../routes/admin";

const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    {
        field: 'name',
        headerName: 'Name',
        width: 280,
        editable: false,
        renderCell : (params:any) => (
            <span title={params.value}>
                {params.value}
            </span>
        )
    }
];

interface ListTransferProps {
    target: "farms" | "complex" | "layers";
    reload?: boolean;
    setReload?: Function; 
}

export const ListTransfer = ({
    target,
    reload,
    setReload
}:ListTransferProps) => {

    const context:any = useContext(AuthContext);

    const [farms, setFarms] = useState<Array<Farms>>();
    const [customersFarms, setCustomersFarms] = useState<Array<Farms>>();

    const [exportEntities, setExportEntity] = useState<Array<number|undefined>>([]);
    const [deleteEntities, setDeleteEntity] = useState<Array<number|undefined>>([]);

    useEffect(()=> {

        async function contentFarms() {
        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/farms`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigFarms)=> {

                        const { parq1Cod, parq1Nom } = oneData;

                        return {
                            id: parq1Cod,
                            name: parq1Nom,
                            key_big: parq1Cod
                        }

                    })

                    setFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/farms/customers`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigFarms)=> {

                        const { parq1Cod, parq1Nom } = oneData;

                        return {
                            id: parq1Cod,
                            name: parq1Nom,
                            key_big: parq1Cod
                        }

                    })

                    setCustomersFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        async function contentComplex() {
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/complex`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigComplex)=> {

                        const { compl1Cod, compl1Nom } = oneData;

                        return {
                            id: compl1Cod,
                            name: compl1Nom,
                            key_big: compl1Cod
                        }

                    })

                    setFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/complex/customers`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigComplex)=> {

                        const { compl1Cod, compl1Nom } = oneData;

                        return {
                            id: compl1Cod,
                            name: compl1Nom,
                            key_big: compl1Cod
                        }

                    })

                    setCustomersFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        async function contentLayers() {
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/layers`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigLayers)=> {

                        const { cama3CodTxt, cama3Nom } = oneData;

                        return {
                            id: cama3CodTxt,
                            name: cama3Nom,
                            key_big: cama3CodTxt
                        }

                    })

                    setFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });

            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/layers/customers`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {
                    

                    const farms = response.data?.map((oneData:bigLayers)=> {

                        const { cama3CodTxt, cama3Nom } = oneData;

                        return {
                            id: cama3CodTxt,
                            name: cama3Nom,
                            key_big: cama3CodTxt
                        }

                    })

                    setCustomersFarms(farms);

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        if(context.user?.accessToken){

            switch (target){
                case "farms":
                    contentFarms(); 
                    break; 
                case "complex":
                    contentComplex();
                    break;
                case "layers":
                    contentLayers();
                    break;
            }

        }

        if(setReload !== undefined){
            if(reload===true){
                setReload(false);
            }
        }
            
    }, [context.user?.accessToken, reload, setReload, target])

    const handleSelectedEntitiesExport = (e:any) => {
        /**Send a request to auth-service to import layers, complex and farms in database of big for customers */
        setExportEntity(e);
        console.log(e);
    }

    const handleSelectedEntitiesDelete = (e:any) => {
        /**Send a request to auth-service to import layers, complex and farms in database of big for customers */
        setDeleteEntity(e);
    }

    const handleExportEntity = () =>{

        async function exportFarms(){
            axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/farms/import`, {
                ids: exportEntities
            },
            {
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/farms/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigFarms)=> {
        
                                const { parq1Cod, parq1Nom } = oneData;
        
                                return {
                                    id: parq1Cod,
                                    name: parq1Nom,
                                    key_big: parq1Cod
                                }
        
                            })
        
                            setCustomersFarms(farms);
                            if(setReload!==undefined){
                                setReload(true);
                            }
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Erro exportando!"))
        }

        async function exportComplex(){
            axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/complex/import`, {
                ids: exportEntities
            },
            {
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/complex/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigComplex)=> {
        
                                const { compl1Cod, compl1Nom } = oneData;
        
                                return {
                                    id: compl1Cod,
                                    name: compl1Nom,
                                    key_big: compl1Cod
                                }
        
                            })
        
                            setCustomersFarms(farms);
                            if(setReload!==undefined){
                                setReload(true);
                            }
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Erro exportando!"))
        }

        async function exportLayers(){
            axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/layers/import`, {
                ids: exportEntities
            },
            {
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/layers/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigLayers)=> {
        
                                const { cama3CodTxt, cama3Nom } = oneData;
        
                                return {
                                    id: cama3CodTxt,
                                    name: cama3Nom,
                                    key_big: cama3CodTxt
                                }
        
                            })

                            setCustomersFarms(farms);
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Erro exportando!"))
        }

        switch (target){
            case "farms":
                exportFarms(); 
                break; 
            case "complex":
                exportComplex();
                break;
            case "layers":
                exportLayers();
                 break;
        }
        
    }

    const deleteExportedEntity = () => {

        async function deleteFarms(){
            axios.delete(`${process.env.REACT_APP_AUTH_SERVICE}/api/farms/import`,{
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                },
                data: {
                    ids: deleteEntities
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/farms/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigFarms)=> {
        
                                const { parq1Cod, parq1Nom } = oneData;
        
                                return {
                                    id: parq1Cod,
                                    name: parq1Nom,
                                    key_big: parq1Cod
                                }
        
                            })
        
                            setCustomersFarms(farms);
                            if(setReload!==undefined){
                                setReload(true);
                            }
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Error delete!"))
        }

        async function deleteComplex(){
            axios.delete(`${process.env.REACT_APP_AUTH_SERVICE}/api/complex/import`,{
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                },
                data: {
                    ids: deleteEntities
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/complex/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigComplex)=> {
        
                                const { compl1Cod, compl1Nom } = oneData;
        
                                return {
                                    id: compl1Cod,
                                    name: compl1Nom,
                                    key_big: compl1Cod
                                }
        
                            })
        
                            setCustomersFarms(farms);
                            if(setReload!==undefined){
                                setReload(true);
                            }
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Error delete!"))
        }

        async function deleteLayers(){
            axios.delete(`${process.env.REACT_APP_AUTH_SERVICE}/api/layers/import`,{
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                },
                data: {
                    ids: deleteEntities
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);
                
                async function contentFarms() {
        
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/layers/customers`, {
                            headers: {
                                Authorization: 'Bearer '+context.user?.accessToken
                            }
                        })
                        .then(function (response) {
                            
        
                            const farms = response.data?.map((oneData:bigLayers)=> {
        
                                const { cama3CodTxt, cama3Nom } = oneData;
        
                                return {
                                    id: cama3CodTxt,
                                    name: cama3Nom,
                                    key_big: cama3CodTxt
                                }
        
                            })
        
                            setCustomersFarms(farms);
        
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                };
        
                contentFarms();
            })
            .catch(()=>alert("Error delete!"))
        }

        switch (target){
            case "farms":
                deleteFarms(); 
                break; 
            case "complex":
                deleteComplex();
                break;
            case "layers":
                deleteLayers();
                 break;
        }
    }

    const customersFarmsIds:Array<number>|undefined = customersFarms?.map((farm)=>{
        return farm.id;
    })

    const filteredBigFarms = farms?.filter((farm)=>!customersFarmsIds?.includes(farm.id));

    return (

            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{padding:"20px"}}>
                <Grid item xs={12} sm={12} md={5}>  
                    <Typography><b>BIG</b></Typography>
                    {/* {filteredBigFarms!==undefined? */}
                        <Table
                            columns={columns} 
                            rows={filteredBigFarms}
                            onSelectionModelChange={handleSelectedEntitiesExport}
                            loading={filteredBigFarms===undefined}
                        />
                        {/* :
                        <CircularProgress color="inherit"/>
                    } */}
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            aria-label="move selected right"
                            onClick={handleExportEntity}
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={deleteExportedEntity}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Typography><b>Customers</b></Typography>
                        <Table
                            columns={columns} 
                            rows={customersFarms}
                            onSelectionModelChange={handleSelectedEntitiesDelete}
                            loading={customersFarms===undefined}
                        />
                </Grid>
            </Grid>

    );

}