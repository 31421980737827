import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #paper-admin{
            /* min-height: 171px;  */
            padding: 20px; 
            max-height: 200px; 
            overflow-y: auto; 
            display: flex;
            flex-direction: column;
        } 

        #terms-of-service, #certificate-conclusion{
            text-decoration: none; 
            color: ${theme.colors.secondary}
        }
    `}
`;
