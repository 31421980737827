import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        form{ 
            opacity: 0.75;
            background-color: ${theme.colors.grayBg};
        }

        button, input{
            color: ${theme.colors.dark};
            height: 12px;
        }

        div{
            height: 40px; 
        }

        width: 200px; 
    `} 

    padding: 0px 40px; 
`;
