import { useState } from 'react';
import * as Styled from './styles';

import { 
    Collapse,
    List,
    ListItemButton,
    Typography,
} from '@mui/material';

import {
    ArrowRight,
    ArrowDropDown
} from '@mui/icons-material';

interface CollapseItemProps {
    title: string, 
    id: number, 
    description: string
};

export const CollapseItem = ({
    title, 
    id,
    description
}: CollapseItemProps) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Styled.Container>
            <ListItemButton
                aria-label="expand role"
                onClick={handleClick}
            >
                {open ? <ArrowDropDown /> : <ArrowRight />}
                <Typography variant="body2" align="center">
                    &nbsp; {title}
                </Typography>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" id="collapse-description" disablePadding>
                    <Typography variant="body2" color="text.secondary">
                        {description?description: "No description"}
                    </Typography>
                </List>
            </Collapse>
        </Styled.Container>
    );
}