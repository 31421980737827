//@ts-nocheck
import { LoadingButton } from '@mui/lab';
import { Alert, Box, FormControl, InputLabel, LinearProgress, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuthListener } from '../../hooks';
import './styles.css';
import axios from 'axios';
import { useEffect } from 'react';

interface AboutProps {
    context_locale: string;
}

/**
 * Table of types
 * 1. Improvement sugestions; 
 * 2. Feedbacks; 
 * 3. Bugs; 
 * 4. Others. 
 */

 const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
};

export const ContactUs = ({context_locale}:AboutProps) => {

    const { user } = useAuthListener();

    const [valuesForms, setValuesForms] = useState({
        subjectId: 0,
        message: '',
        contactSendmail: true
    });

    const [subjects, setSubjects] = useState([]);

    const [error, setError] = useState(false)
    
    /**GET all the subjects */
    useEffect(()=> {
        if(user && subjects) {
            axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/api/users/reports/subjects`,{
                headers: {
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            })
            .then((res)=>{
                setSubjects(res.data);
                setValuesForms({
                    subjectId: res.data[0].id,
                    message: '',
                    contactSendmail: true
                })
            })
            .catch((e)=>{
                setSubjects([]);
            })
        }

    }, [user])

    const handleChangeSelect = (event: SelectChangeEvent) => {

        const { name, value } = event.target; 
        // console.log(value);
        setValuesForms(prevState => ({...prevState, [name]: value as string}));

    };

    const handleChangeText = (event: any) => {

        const { name, value } = event.target; 
        setValuesForms(prevState => ({...prevState, [name]: value as string}));

    }

    const [request, setRequest] = useState(false);

    const handleRequestSent = () => {
        setTimeout(()=>{
            setRequest(false);
        }, 3000)
    }

    const [errorMessage, setErrorMessage] = useState(false);

    const handleRequestError = () => {
        setTimeout(()=>{
            setErrorMessage(false);
        }, 3000)
    }

    const saveReport = () => {

        const { accessToken } = user;

        if(valuesForms.message.trim()!== ""){
            axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/users/reports`,{
                ...valuesForms
            },
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            })
            .then(()=> {
                setRequest(true);
                handleRequestSent();
                setValuesForms({
                    subjectId: subjects.length>0?subjects[0].id:1,
                    message: '',
                    contact_sendmail: true
                });
            })
            .catch(()=>{
                setValuesForms({
                    subjectId: subjects.length>0?subjects[0].id:1,
                    message: '',
                    contact_sendmail: true
                });
            })
        } else {
            setErrorMessage(true);
            handleRequestError();
            setError(true);
        }
    }

    const handleError = (event) => {

        // const { name, value } = event.target; 

        if(valuesForms.message=== ""){
            setError(true);
        } else {
            setError(false);
        }
    }

    return (
        <div id="background">
            <div id="loading-container">
                {/* <img src="${process.env.REACT_APP_PUBLIC_BUCKET_URL}/background-images/color.svg" style={{maxWidth:"250px" ,width:"auto", height:"auto", padding: "20px 30px"}} alt="Logo CdV" /> */}
                <Box
                    sx={{
                        width: '80vw',
                        paddingBottom: '20px',
                        fontSize: 15,
                        textAlign: 'center',
                        maxWidth: '800px'
                    }}
                >
                    <h1>
                        Would you like to report:
                    </h1>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-filled-label">Subject</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            label="Subject"
                            name="subjectId"
                            value={valuesForms.subjectId}
                            sx={{
                                width: '60vw',
                                textAlign: 'start',
                                maxWidth: '600px'
                            }}
                            onChange={handleChangeSelect}
                        >
                            {subjects.length>0&&subjects.map((item)=>{
                                return(
                                    <MenuItem value={item.id}>{item.text}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Box
                        component="form"
                        sx={{
                        '& .MuiTextField-root': { m: 1, minWidth: 120 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            name="message"
                            multiline
                            rows={4}
                            onChange={handleChangeText}
                            defaultValue=""
                            value={valuesForms.message}
                            onBlur={handleError}
                            error={error}
                            sx={{
                                width: '60vw',
                                textAlign: 'start',
                                maxWidth: '600px'
                            }}
                        />
                    </Box>
                    <LoadingButton
                        size="small"
                        loadingPosition="end"
                        variant="contained"
                        onClick={saveReport}
                    >
                        Send
                    </LoadingButton>
                    {request&&
                        <Alert 
                            severity="success"
                            sx={{
                                marginTop: 3
                            }}
                        >
                            Request sent!
                        </Alert>
                    }
                    {errorMessage&&
                        <Alert 
                            severity="error"
                            sx={{
                                marginTop: 3
                            }}
                        >
                            Please make sure that your text field is filled.
                        </Alert>
                    }
                </Box>
            </div>
        </div>
    )

}