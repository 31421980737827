import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Box,
    Stack,
    Paper,
    styled
} from '@mui/material';

import axios from 'axios';

import useQuery from '../../hooks/query';

import { HorizontalLinearStepper } from '../../components/HorizontalLinearStepper';
import { ViewRegistration } from '../../components/ViewRegistration';
import { Layers } from '../../models/Layers';
import { Complex } from '../../models/Complex';
import { Farms } from '../../models/Farms';
import { InfoSelectedViews } from '../../models/Views';
import { AuthContext } from '../../routes/admin';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const CreateViews = () => {

    const context:any = useContext(AuthContext);

    const idValue = useQuery().get("id"); 
    const [error, setError] = useState(false);
    const edit = idValue === null || idValue === "" ? false : true; 

    const [views, setViews] = useState<InfoSelectedViews>({
        id: null,
        title: "",
        description: "",
        layers: [],
        complex: [],
        farms: [],
        site: []
    });

    useEffect(()=> {
        
        if(edit){
            const endpoint = process.env.REACT_APP_AUTH_SERVICE + '/api/views/'+idValue; 
            axios.get(endpoint, {
                    params: {
                        ID: 12345
                    },
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {

                    const data = response.data; 

                    setViews({
                        id: data.id,
                        title: data.title, 
                        description: data.description,
                        layers: data.layers.map((layer:Layers)=> layer.id),
                        complex: data.complex.map((complex:Complex) => complex.id),
                        farms: data.farms.map((farm:Farms) => farm.id),
                        site: data.site.map((site:any) => site.id)
                    })
                })
                .catch(function (error) {
                    setError(true);
                });
        }
        
    }, [])

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const steps = ['Informations about your views', 'Select permissions for your view', 'Review your created view'];

    if(error){
        return(
            <Styled.Container>
                <Box sx={{ width: '100%' }} >
                    <Stack direction="row" spacing={2} id="top-tab-admin">
                        <Item id="tab-admin-title" elevation={0} >No views found to edit</Item>
                    </Stack>
                </Box>
            </Styled.Container>
        )
    }
    
    return (
        <Styled.Container>
            <Box sx={{ width: '100%' }} >
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >{edit?"Edit":"Create"} Views</Item>
                </Stack>
                <HorizontalLinearStepper 
                    steps={steps}
                    activeStep={activeStep}
                    skipped={skipped}
                />
                <ViewRegistration 
                    id={idValue}
                    edit={edit}
                    steps={steps}
                    hrefOrig="/admin/views"
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    info={views}
                    setInfo={setViews}
                    skipped={skipped}
                    setSkipped={setSkipped}
                />
            </Box>
        </Styled.Container>
    );

}