import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    CircularProgress,
    styled,
    Paper,
    Box,
    Stack,
    Grid
} from '@mui/material';

import { theme } from '../../styles/theme';
import { columns } from './table';

import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';

import { CreateButton } from '../../components/CreateButton';
import { DeleteButton } from '../../components/DeleteButton';
import { Table } from '../../components/Table';
import { AlertDialog } from '../../components/AlertDialog';
import { AuthContext } from '../../routes/admin';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const Users = () => {

    const context:any = useContext(AuthContext);

    const [users, setUsers] = useState();
    const [selected, setSelected] = useState([]);
    const [enableDelete, setEnableDelete] = useState(false); 
    const [openDialog, setOpenDialog] = useState(false); 

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const navigate = useNavigate()

    const handleDeleteUsers = async () => {

        /*Action to delete a user here!!! */
        const deleteAction = await axios.delete(process.env.REACT_APP_AUTH_SERVICE+'/api/users', {
            data: {
                ids: selected
            },
            headers: {
                Authorization: `Bearer ${context.user?.accessToken}`
            }
        })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false; 
        });

        setOpenDialog(false)

        if(deleteAction){
            navigate(0)
        }

    }

    const handleSelected = (data:any) => {
        setSelected(data)
    }

    useEffect(()=> {

        if(selected.length > 0){
            setEnableDelete(true)
        }else{
            setEnableDelete(false)
        }

    }, [selected])

    useEffect(() => {
        
        async function contentUsers() {

            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/users';

            const users = await axios.get(endpoint, {
                    //  params: {
                    //     email: 'leonardo.silva@casadosventos.com.br'
                    //  },
                     headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                     }
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });

            setUsers(users)
        }

        if(context.user?.accessToken){
            contentUsers();
        }
    
    }, [context.user?.accessToken])

    if(!users){
        return(
            <Styled.Container>
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >Users</Item>
                    <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/users/create"><CreateButton type={"groups"} /></Link></Item>
                    <Item id="tab-admin-btn" elevation={0} >
                        <DeleteButton 
                            onClick={()=> console.log("clicked!")}
                            disabled={!enableDelete}
                        />
                    </Item>
                </Stack>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >Users</Item>
                <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/users/create"><CreateButton type={"groups"} /></Link></Item>
                <Item id="tab-admin-btn" elevation={0} ><DeleteButton onClick={handleOpenDialog} disabled={!enableDelete}/></Item>
            </Stack>
            <Grid id="username-details-admin" container spacing={4}>
                <Grid item xs={12}>
                    <Table rows={users} columns={columns} onSelectionModelChange={handleSelected}/>
                </Grid>
            </Grid>  
            <AlertDialog 
                open={openDialog}
                onClose={handleCloseDialog}
                onClickDisagree={handleCloseDialog}
                onClickAgree={handleDeleteUsers}
                textAgreeButton="Agree"
                textDisagreeButton="Disagree"
                title="Delete Access of Users Selected?"
                content=" "
            />
        </Styled.Container>
    );
}  