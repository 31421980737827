import styled, { css } from 'styled-components';

const opacity = 0.37*255; /*40% de opacidade*/

export const Container = styled.div`
    ${({ theme }) => css`
        nav{ 
            background: ${theme.colors.primary}!important;
            display: flex; 
            flex-direction: column; 
        }

        .Mui-selected{
            background-color: ${theme.colors.secondary+opacity.toString(16)}!important;
            border-radius: 10px!important;
            /* padding: 10px;  */
        }
        div{
            margin: 2px 0;
            color: ${theme.colors.dark};
            /* padding: 5px;  */
        }

        a{
            text-decoration: none; 
        }
        
        padding: 10px; 
    `}
`;
