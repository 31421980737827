import * as Styled from './styles';

import { 
    DataGrid, 
    GridToolbarContainer, 
    GridToolbarExport, 
    GridToolbarFilterButton, 
    GridToolbarDensitySelector, 
    DataGridProps,
    GridRowParams
} from '@mui/x-data-grid';

const Toolbar = () => {

    return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarExport />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      );

}

interface TableProps {
    rows?: DataGridProps["rows"];
    columns: DataGridProps["columns"];
    onSelectionModelChange: DataGridProps["onSelectionModelChange"];
    selectionModel?: any,
    loading?: DataGridProps["loading"],
    complexSelected?: any
}

export const Table  = ({
    rows,
    columns, 
    onSelectionModelChange,
    selectionModel,
    loading,
    complexSelected
}:TableProps) => {

    return (
        <Styled.Container>
          <div style={{ height: 450}}>
            <DataGrid
              rows={rows?rows:[]}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              loading={loading}
              isRowSelectable={(params: GridRowParams)=> {
                if(complexSelected===undefined){
                  return true
                } else{
                  return (complexSelected.indexOf(params.row.complexId) === -1)
                }
                
              }}
              components={{
                Toolbar: Toolbar,
              }}
              selectionModel={selectionModel}
              onSelectionModelChange={onSelectionModelChange}
            />
          </div>
        </Styled.Container>
      );

}