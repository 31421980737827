import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../routes/security";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    ButtonProps,
    Paper,
    Grid,
    IconButton,
    Snackbar,
    Alert
} from '@mui/material';

import Draggable from 'react-draggable';
import { MultipleSelectChip, Option } from "../MultipleSelectChip";
import { CollapseComponent } from "../CollapseComponent";

import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FeedIcon from '@mui/icons-material/Feed';
import SearchIcon from '@mui/icons-material/Search';
import { FilterCenterFocus, FileDownload } from '@mui/icons-material';

import { CircularProgress } from "@material-ui/core";
import { GlobalMapContext } from "../../routes";
import { LeafletCenterGeometry } from "../../templates/Map/utils/leaflet-center-geometry";

import _ from 'lodash';

function PaperComponent(props:any) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

interface DialogSearchProps {
    open: boolean; 
    handleClose: ButtonProps["onClick"]; 
    searchText: string; 
}

interface ResultsSearch {
    lands: any; 
    contracts: any; 
    owners: any; 
}

const options:Array<Option> = [
    {
        id: 'owners',
        title: 'Owners'
    },
    {
        id: 'lands',
        title: 'Lands'
    },
    {
        id: 'contracts',
        title: 'Contracts'
    }
]

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const DialogSearch = ({
    open,
    handleClose, 
    searchText
}:DialogSearchProps) => {

    const { user, sessionUser } = useContext(AuthContext);

    const map:any = useContext(GlobalMapContext);

    const [filter, setFilter] = useState<Array<string>>([]); 

    const handleChange = (event:any) => {
        setFilter(event.target.value);
    };

    const [leafletCenterGeometry, setLeafletCenterGeometry] = useState<LeafletCenterGeometry>()

    useEffect(()=>{
        if(map){
            setLeafletCenterGeometry(new LeafletCenterGeometry(map));
        }
    }, [map])

    const [data, setData] = useState<ResultsSearch|undefined>();

    /**ERROR MESSAGES */
    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showErrorMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    useEffect(()=>{

        async function contentSearch (){
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/layers/search?text=${searchText}`,{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then((res)=>{

                const data:ResultsSearch = res.data; 
                setData(data)
                
                let filter = [];
                if(data['owners'].length > 0){
                    filter.push('owners')
                }

                if(data['lands'].length > 0){
                    filter.push('lands')
                }

                if(data['contracts'].length > 0){
                    filter.push('contracts')
                }

                setFilter(filter); 

            })
            .catch((error)=>setData({
                lands: [], 
                contracts: [], 
                owners: []
            })); 
        }

        if(open){
            contentSearch();
        }

        return (()=> setData(undefined))

    }, [open, searchText, user?.accessToken])

    if(!data){
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move', }} id="draggable-dialog-title">
                    <Grid 
                        container 
                        spacing={0}
                        alignContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item xs={12} textAlign={'center'} alignContent={'center'} alignSelf={'center'}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </DialogTitle>
            </Dialog>
        )
    }

    const centerById = async (e:any) => {

        /**
         * [land-[id], ctrt-[id]]
         */
        const idComponent = e.target.id; 

        if(idComponent){
            const [target, id] = idComponent.split('-');

            await axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands${target==='contracts'?`/${target}`:''}/${id}`,{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then((res)=>{

                const geometry = res.data.prop1BoundingBox; 
                // const name = res.data.prop1Nom?res.data.prop1Nom:"N/A";

                if(geometry && leafletCenterGeometry){
                    leafletCenterGeometry.centerByGeometry(geometry);
                }

                if(handleClose!==undefined){
                    handleClose(e);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }

    }

    /**Checking permissions to download files and show checkbox */
    const permissions = sessionUser?.permissions?sessionUser?.permissions:[]; 

    const checkIfHasPermission = (target:string, resource:string, verb: string) => {
        return permissions?.findIndex((permission:any)=>{
            return permission === `${target}.${resource}.${verb}`; 
        }) !== -1; 
    }

    const downloadOwnersFiles = async (e:any) => {

        const { id } = e.target; 

        if(id){

            const [, code] = id.split('-');

            
            await axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/files/owners/${code}`,{
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                }
            })
            .then((res)=>{
                return res.data;
            })
            .then((data)=>{

                const { url } = data; 

                window.open(url, '_blank'); 

            })
            .catch((res)=>{

                showErrorMessage({
                    text: "No documents found!",
                    severity: 'warning'
                });

            })

        }
        
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <b>Search Results</b> &nbsp;<SearchIcon sx={{fontSize: 18, marginLeft: -1}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <MultipleSelectChip
                                title={"Filter"}
                                name={"Filter"}
                                options={options}
                                onChange={handleChange}
                                value={filter}
                                error={false}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: 500,
                        maxHeight: 500
                    }}
                >
                {filter.indexOf('owners')!==-1&&
                    <CollapseComponent
                        title = {"Owners"}
                        children={
                            <DialogContentText>
                                {data && data['owners'].length>0?
                                    data['owners'].map((owner:any)=>{
                                        return(
                                            <tr>
                                                <td
                                                    style={{
                                                        width:400
                                                    }}
                                                >
                                                    <div>  
                                                        <div>
                                                            <PersonIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                            <b style={{fontSize: '0.8em', color: 'black'}}>{`${owner.prio1Cod} - ${owner.prio1Nom?owner.prio1Nom:"Não definido"}`}</b>
                                                        </div>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Document: ${owner.prio1Doc?owner.prio1Doc:"Não definido"}`}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {checkIfHasPermission('owners', 'files', 'download') &&
                                                        <IconButton
                                                            id={`owner-${owner.prio1Cod}-btn`}
                                                            onClick={downloadOwnersFiles}
                                                        > 
                                                            <FileDownload 
                                                                id={`owner-${owner.prio1Cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={data && data['owners'].length>0?true:false}
                    />
                }
                {filter.indexOf('lands')!==-1&&
                    <CollapseComponent
                        title = {"Lands"}
                        children={
                            <DialogContentText>
                                {data && data['lands'].length>0?
                                    <table
                                        style={{width: 450}}
                                    > 
                                        <tbody>
                                        {data['lands'].map((land:any)=>{
                                            return(
                                                <tr>
                                                    <td
                                                        style={{
                                                            width:400
                                                        }}
                                                    >
                                                        <LocationOnIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                        <b style={{fontSize: '0.8em', color: 'black'}}>{`${land.prop1Cod} - ${land.prop1Nom?land.prop1Nom:"Não definido"}`}</b><br/>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Owner: ${land.prop1PrioCache?land.prop1PrioCache:"Não definido"}`}</span>
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            onClick={centerById}
                                                            id={`lands-${land.prop1Cod}-btn`}
                                                        > 
                                                            <FilterCenterFocus 
                                                                id={`lands-${land.prop1Cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={data && data['lands'].length>0?true:false}
                    />
                }
                {filter.indexOf('contracts')!==-1&&
                    <CollapseComponent
                        title = {"Contracts"}
                        children={
                            <DialogContentText>
                                {data && data['contracts'].length>0?
                                    <table
                                        style={{width: 450}}
                                    > 
                                        <tbody>
                                        {data['contracts'].map((contracts:any)=>{
                                            return(
                                                <tr>  
                                                    <td
                                                        style={{
                                                            width:400
                                                        }}
                                                    >
                                                        <FeedIcon sx={{fontSize: 15, padding: 0, color: 'black'}}/> &nbsp; 
                                                        <b style={{fontSize: '0.8em', color: 'black'}}>{`${contracts.ctrt1CodTxt?contracts.ctrt1CodTxt:contracts.ctrt1Cod}`}</b><br/>
                                                        <span style={{fontSize: '0.6em', lineHeight: '0.6em', color: 'gray'}}>{`Status: ${contracts.ctrt1StatusCtrt?contracts.ctrt1StatusCtrt:"Não definido"}`}</span>
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            onClick={centerById}
                                                            id={`contracts-${contracts.ctrt1Cod}-btn`}
                                                        > 
                                                            <FilterCenterFocus 
                                                                id={`contracts-${contracts.ctrt1Cod}`}
                                                                sx={{fontSize: 15}}
                                                            />
                                                        </IconButton> 
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    :
                                    'No results found'
                                }
                            </DialogContentText>
                        }
                        defaultState={data && data['contracts'].length>0?true:false}
                    />
                }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
                <Snackbar
                    open={errorMessage}
                    autoHideDuration={3000}
                    onClose={handleCloseErrorMessage}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Alert onClose={handleCloseErrorMessage} severity={message.severity} sx={{ width: '100%' }}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Dialog>
        </>
    )

}