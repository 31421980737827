
import styled, { css } from 'styled-components';

export const TokenDiv = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        margin: 10px 0;
        padding:20px 50px;
        flex-direction: column;
        #btnToken{
            margin:0 0 20px 0;
            color: white;
            background-color: #3C8DBC;
            
            :hover{
                background-color: #0D5E8D;
            }
        }
    `}
`