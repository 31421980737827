import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import {
    Stack,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    List,
    ListItem,
    CircularProgress,
    Box,
    styled
} from '@mui/material';

import { theme } from '../../styles/theme';

import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import { CreateButton } from '../../components/CreateButton';
import { CollapseComponent } from '../../components/CollapseComponent';
import { EditButton } from '../../components/EditButton';
import { Views } from '../../models/Views';
import { AuthContext } from '../../routes/admin';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const View = () => {

    const context:any = useContext(AuthContext);

    const { id } = useParams();

    const [data, setData] = useState<Views>();
    const [numberViews, setNumberViews] = useState ();

    useEffect(()=> {

        async function contentUser() {
        
            await axios.get(process.env.REACT_APP_AUTH_SERVICE+'/api/views/'+id, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {

                    const {data} = response; 

                    setData(data);

                    const { complex, farms, layers, site } = data; 

                    const complexLength = complex !== null? complex.length: 0; 
                    const farmsLength = farms !== null? farms.length: 0; 
                    const layersLength = layers !== null? layers.length: 0; 
                    const sitesLength = site !== null ? site.length: 0; 

                    setNumberViews(complexLength+farmsLength+layersLength+sitesLength); 
                })
                .catch(function (error) {
                    alert("Error trying loading a role!")
                });

            
        }

        if(context.user?.accessToken){
            contentUser()
        }

    }, [context.user?.accessToken, id])

    if(!data){
        return(
            <Styled.Container>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >{data?.title}</Item>
                <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/roles/create"><CreateButton type={"roles"} /></Link></Item>
                <Item id="tab-admin-btn" elevation={0} >
                    <Link id="edit-button" to={"/admin/views/create?id="+id}>
                        <EditButton />
                    </Link>
                </Item>
            </Stack>
            <Paper elevation={3} id="form-admin">
                <Grid container spacing={2}>
                    <Grid item xs id="info-role">
                        <Divider />
                        <Table>
                            <TableBody>
                                <TableRow id="info-content">
                                   <TableCell>
                                       <b>ID</b>
                                   </TableCell>
                                   <TableCell>
                                       {id}
                                   </TableCell>
                                </TableRow>
                                <TableRow id="info-content">
                                   <TableCell>
                                        <b>Created by</b>
                                   </TableCell>
                                   <TableCell>
                                       {data?.created_by?data.created_by:"No user registered"}
                                   </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container spacing={2} id="content-role">
                    <Grid item xs>
                        <Divider />
                            <Typography gutterBottom>
                                Description
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {data?.description}
                            </Typography>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2} id="content-role">
                    <Grid item xs>
                        <Typography gutterBottom>
                            {numberViews} assigned views
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            <CollapseComponent 
                                title="Layers"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data?.layers?.length>0?data?.layers.map((layer)=>{
                                                    return <ListItem key={`${layer.id}-layers`}>{layer.name}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}                     
                            />
                            <Divider />
                            <CollapseComponent 
                                title="Complex"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data?.complex?.length>0?data?.complex.map((complex)=>{
                                                    return <ListItem key={`${complex.id}-complex`}>{complex.name}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />
                            <Divider />
                            <CollapseComponent 
                                title="Farms"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data?.farms?.length>0?data?.farms.map((farm)=>{
                                                    return <ListItem key={`${farm.id}-farms`}>{farm.name}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />
                            <Divider />
                            <CollapseComponent 
                                title="Wind Turbines"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data?.site?.length>0?data?.site.map((site)=>{
                                                    return <ListItem key={`${site.id}-sites`}>{site.complex}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Styled.Container>
    );

}