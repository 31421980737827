import { 
    useContext, 
    useEffect, 
    useState 
} from 'react';

import { arrayMove } from 'react-sortable-hoc';

import { SortableList } from '../SortableList';
import { SortableItem } from '../SortableItem';
import { Controller } from '../Controller';

import * as _ from 'lodash';
import { Layers } from '../../models/Layers';

import { LeafletListLayers } from './leaflet-list-layers';
import { GlobalMapContext } from '../../routes';

interface ListLayersProps {
    search?: string; 
    groupId?: string | null;
    onlyActives?: boolean;
    onlyFavorites?: boolean; 
}

export const ListLayers = ({
    search,
    groupId,
    onlyActives,
    onlyFavorites
}:ListLayersProps) => {
  
    const map:any = useContext(GlobalMapContext);

    const [listController, setListController] = useState<LeafletListLayers>();
    const [list, setList] = useState<Array<Layers>|[]>();

    const [refreshList, setRefreshList] = useState(false); 

    useEffect(()=>{
        if(map?._state!==undefined) {
            setListController(new LeafletListLayers(map, groupId)); 
        }
    },[groupId, map, map?._state])

    /** Refresh the list to show on the menu */
    useEffect(()=>{
        if(listController!==undefined){

            listController.setZIndex();
            setList(listController.getListLayers(onlyActives, onlyFavorites));
            setRefreshList(false);

        }
    }, [listController, onlyActives, onlyFavorites, refreshList])

    const [filteredList, setFilteredList] = useState<Array<Layers>|[]>();

    /**Search layer by name */
    useEffect(()=> {

        if(list!==undefined){
            setFilteredList(list.filter((layer:any)=>layer.name.toLowerCase().search(search)>=0));
        }

    }, [list, search])

    const onSortEnd = ({oldIndex, newIndex}:any) => {
        if(list!==undefined && listController!==undefined){
            setList(arrayMove(list, oldIndex, newIndex));
            listController.setGroupLayers(arrayMove(list, oldIndex, newIndex));
        }
    }

    return(
        <SortableList
            helperClass="SortableHelper"
            axis="y"
            lockAxis="y"
            onSortEnd={onSortEnd} 
            lockToContainerEdges
            useDragHandle
        >
            {list && list?.length > 0?
                filteredList && filteredList.length < list.length ?
                    filteredList.length > 0 ?
                        filteredList?.map((value:Layers|undefined, index:number) => (
                            <SortableItem key={`item-${value?.txt_code}`} index={index}>
                                <Controller 
                                    label={value?.name?value?.name:""}
                                    config={value?.config}
                                    txtCode={value?.txt_code}
                                    setRefreshList={setRefreshList}
                                />
                            </SortableItem>
                        ))
                        :
                        `No results found.`
                    :
                    list?.map((value:Layers|undefined, index:number) => (
                        <SortableItem key={`item-${value?.txt_code}`} index={index}>
                            <Controller 
                                label={value?.name?value?.name:""}
                                config={value?.config}
                                txtCode={value?.txt_code}
                                setRefreshList={setRefreshList}
                            />
                        </SortableItem>
                    ))
                :
                `No results found.`
            }
        </SortableList>       
    )

}