import { theme } from '../../styles/theme';
import { styled } from '@mui/material/styles';

interface MainProps {
    open: boolean;
}

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ open }:MainProps) => ({
      flexGrow: 1,
      marginLeft: `-${theme.sizes.sidebar}`,
      ...(open && {
        marginLeft: 0,
      }),
      width: `calc(100vw - ${theme.sizes.sidebar})`
    }),
);
  