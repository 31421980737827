import { MapTwoTone } from "@mui/icons-material";
import { Fade, Grid, IconButton, Link, Menu, Typography, useMediaQuery } from "@mui/material";
import { LatLng, Map } from "leaflet"
import { useCallback, useEffect, useState } from "react"
import { theme } from "../../styles/theme";
import { LeafletTransformations } from "../Toolbar/utils/leaflet-transformations";

import ReportIcon from '@mui/icons-material/Report';
import CircularProgress from '@mui/material/CircularProgress';

const formatLatLng:Array<string> = ['default', 'deg', 'utm'];

interface FooterProps {
    map?: Map;
    open: boolean; 
};

export const Footer = ({
    map,
    open
}:FooterProps) => {

    const leafletTransformations = new LeafletTransformations();

    const [format, setFormat] = useState(0);

    const handleFormat = () => {
        if(format + 1 > (formatLatLng.length - 1)){
            setFormat(0);
        }else{
            setFormat(format+1);
        }
    }

    const matches = useMediaQuery('(min-width:1280px)');

    const [zoom, setZoom] = useState<number|undefined>(); 
    const [position, setPosition] = useState<LatLng|undefined>();

    const onMouseMove = useCallback((e) => {
        setPosition(e.latlng);
    }, []);

    useEffect(() => {
        map?.on('mousemove', onMouseMove);
        return () => {
            map?.off('mousemove', onMouseMove);
        }
    }, [map, onMouseMove])

    const onZoomEnd = useCallback(()=>{
        setZoom(map?.getZoom());
    }, [map])

    useEffect(() => {
        map?.on('zoomend', onZoomEnd);
        return () => {
            map?.off('zoomend', onZoomEnd);
        }
    }, [map, onZoomEnd])

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Grid 
            container 
            spacing={0} 
            sx={{
                fontSize: '0.8rem',
                background: theme.colors.primary,
                height: 25
            }}
        >
            {matches?
                <>
                    <Grid item xs={3} sx={{paddingLeft: 5}}>
                            <Typography variant="body2" color="textSecondary" align="left">
                                {'GIS © '}
                                    <Link color="inherit" href="https://casadosventos.com.br/" target="_blank">
                                        Casa dos ventos 
                                    </Link>{' '}
                                {new Date().getFullYear()}
                                {'.'}
                            </Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={3} 
                        sx={{
                            textAlign: 'center',
                            paddingTop: 0.5
                        }}
                        onClick={handleFormat}
                    >   
                        <b style={{cursor: 'pointer'}}>Lat/Lng:</b>
                        {position!==undefined?
                            ` ${leafletTransformations.formatLatLng(formatLatLng[format], position)}`
                            :
                            <>
                            </>
                        }
                    </Grid>
                    <Grid 
                        item 
                        xs={3} 
                        sx={{
                            textAlign: 'center',
                            paddingTop: 0.5,
                            paddingLeft: 2
                        }}
                    >   
                        <b>Zoom:</b>
                        {zoom!==undefined?
                            ` ${zoom}`
                            :
                            <>
                            </>
                        }
                    </Grid>
                    <Grid 
                        item 
                        xs={3} 
                        sx={{
                            paddingTop: 0.1,
                            textAlign: 'center',
                        }}
                    >   
                        <ReportIcon 
                            id="leaflet-tile-error"
                            style={{
                                display: `none`
                            }}
                        /> &nbsp;&nbsp;
                        <CircularProgress
                            id="leaflet-tile-loading"
                            style={{
                                width: 22,
                                height: 22,
                                display: `none`
                            }}
                        />
                    </Grid>
                </>
                :
                <>
                    <Grid 
                        xs={6} 
                        item
                        sx={{
                            textAlign: 'start',
                            paddingTop: -10
                        }}
                    > 
                        <ReportIcon 
                            id="leaflet-tile-error"
                            style={{
                                display: `none`
                            }}
                        /> &nbsp;&nbsp;
                        <CircularProgress
                            id="leaflet-tile-loading"
                            style={{
                                width: 22,
                                height: 22,
                                display: `none`
                            }}
                        />
                    </Grid>
                    <Grid 
                        xs={6} 
                        item
                        sx={{
                            textAlign: 'end',
                            paddingTop: 0
                        }}
                    >  
                        <IconButton
                            sx={{
                                padding: 0
                            }}
                            onClick={handleClick}
                        >
                            <MapTwoTone />
                        </IconButton>
                    </Grid>
                    <Menu
                        MenuListProps={{
                            'aria-labelledby': 'menu-mobile',
                            'id':'menu-mobile'
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <Grid 
                            container 
                            spacing={0} 
                            sx={{
                                fontSize: '0.8rem',
                                // background: theme.colors.primary
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    fontSize: '0.8rem',
                                    // textAlign: 'start',
                                    padding: 2
                                }}
                                onClick={handleFormat}
                            >
                                <b style={{cursor: 'pointer'}}>Lat/Lng:</b>
                                {position!==undefined?
                                    ` ${leafletTransformations.formatLatLng(formatLatLng[format], position)}`
                                    :
                                    <>
                                    </>
                                }
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    height: 10,
                                    fontSize: '0.8rem',
                                    // textAlign: 'start',
                                    padding: 2
                                }}
                                xs={12}
                            >
                                <b>Zoom: </b>
                                {zoom!==undefined?
                                    ` ${zoom}`
                                    :
                                    <>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Menu>
                </>
            }
        </Grid>
    )

}