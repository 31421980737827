import * as Styled from './styles';

import {
    Box
} from '@mui/material';
import { RoleRegistrationStepOne } from '../RoleRegistrationStepOne';
import { RoleRegistrationStepTwo } from '../RoleRegistrationStepTwo';
import { RoleRegistratioStepThree } from '../RoleRegistrationStepThree';
import { InfoSelectedRoles } from '../../models/Roles';



interface RoleRegistrationProps {
    steps: Array<string>;
    hrefOrig: string; 
    activeStep: number;
    setActiveStep: Function;
    info?: InfoSelectedRoles,
    setInfo: Function,
    skipped: Set<any>,
    setSkipped: Function,
    edit: boolean,
    id: string | null
}

export const RoleRegistration = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    edit,
    id
}:RoleRegistrationProps) => {

    const Step = () => {

        let step; 
        switch(activeStep) {
            case 0:
                step = <RoleRegistrationStepOne 
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break; 
            case 1:
                step = <RoleRegistrationStepTwo
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break; 
            case 2:
                step = <RoleRegistratioStepThree
                            id={id}
                            edit={edit}
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break;   
            default:
                step = <h1>Not found!</h1>
        }

        return step; 
    }

    return (
        <Styled.Container>
            <Box>
                <Step />
            </Box>
        </Styled.Container>
    );
}