import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import {
    Box,
    Stack,
    Paper,
    styled
} from '@mui/material';

import { HorizontalLinearStepper } from '../../components/HorizontalLinearStepper';
import { UserRegistration } from '../../components/UserRegistration';

import axios from 'axios';

import useQuery from '../../hooks/query';
import { Roles } from '../../models/Roles';
import { Views } from '../../models/Views';
import { AuthContext } from '../../routes/admin';
import { ApiCountry, Country } from '../../models/Country';
import { InfoSelectedUserSession } from '../../models/Users';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const CreateUsers = () => {

    const context:any = useContext(AuthContext);

    const idValue = useQuery().get("id"); 
    const [error, setError] = useState(false);
    const edit = idValue === null || idValue === "" ? false : true; 
        
    const [users, setUsers] = useState<InfoSelectedUserSession>({
        name: "",
        email: "",
        phone: "",
        isAdmin: false, 
        roles: [],
        views: [],
        country: undefined 
    });

    useEffect(()=> {
        
        if(edit && context.user?.accessToken){
            const endpoint = process.env.REACT_APP_AUTH_SERVICE + '/api/users/'+idValue; 
            axios.get(endpoint, {
                    params: {
                        ID: 12345
                    },
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    const data = response.data; 

                    const country:ApiCountry|null|undefined = data.country; 

                    const formatedCountry:Country|undefined = country? {
                        id: country.id,
                        code: country.code_txt,
                        label: country.name,
                        flagUrl: country.flag_url
                    }
                    :
                    undefined

                    setUsers({
                        name: data.name, 
                        email: data.email, 
                        phone: data.phone, 
                        isAdmin: data.is_admin, 
                        roles: data.roles.map((role:Roles)=> role.id),
                        views: data.views.map((view:Views)=> view.id),
                        country: formatedCountry
                    })
                })
                .catch(function (error) {
                    setError(true);
                });
        }
        
    }, [context.user?.accessToken, edit, idValue])

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const steps = ['Informations about your user', 'Review your created user'];

    if(error){
        return(
            <Styled.Container>
                <Box sx={{ width: '100%' }} >
                    <Stack direction="row" spacing={2} id="top-tab-admin">
                        <Item id="tab-admin-title" elevation={0} >No users found to edit</Item>
                    </Stack>
                </Box>
            </Styled.Container>
        )
    }

    return (
        <Styled.Container>
            <Box sx={{ width: '100%' }} >
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >{edit?"Edit":"Create"} Users</Item>
                </Stack>
                <HorizontalLinearStepper 
                    steps={steps}
                    activeStep={activeStep}
                    skipped={skipped}
                />
                <UserRegistration 
                    id={idValue}
                    edit={edit}
                    steps={steps}
                    hrefOrig="/admin/users"
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    info={users}
                    setInfo={setUsers}
                    skipped={skipped}
                    setSkipped={setSkipped}
                />
            </Box>
        </Styled.Container>
    );

}