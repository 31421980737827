declare const L: any;

export class LeafletCenterGeometry{

    private map: any; 

    constructor(map:any){
        this.map = map; 
    }

    public centerByGeometry(geometry:any) {

        const map = this.map; 

        var polygon = L.GeoJSON.geometryToLayer(geometry);

        polygon.setStyle({color:'black',opacity:1,fillColor:'green',fillOpacity:0.2,weight:2, dashArray:"4"});
        // polygon.text = name; 
        // polygon.bindTooltip(polygon.text);
        // polygon.id = id; 
        // polygon.name = name; 

        const boundindBox = polygon.getBounds();
        const zoom = map.getZoom();
        const boundindBoxZoom = map.getBoundsZoom(boundindBox);
        const size = map.getSize();

        var pointX=Math.floor(size.x*0.6/2);
		var pointY=Math.floor(size.y*0.6/2);

        if (map.getBounds().intersects(boundindBox)){ // a propriedade está dentro da janela atual, mesmo que parcialmente
            if (boundindBoxZoom-zoom>3){ // o zoom para fechar na propriedade é mais do que 3 níveis maior (mais fechado)
                map.fitBounds(boundindBox, {padding:[pointX,pointY]}); // foca na propriedade, com folga lateral de 100 pixels
            }else if (boundindBoxZoom-zoom>=0){ // o zoom é um pouco maior, mas dentro do limite
                map.panTo(boundindBox.getCenter());// só centraliza na propriedade
            }else{
                map.fitBounds(boundindBox); // zoom mais aberto - enquadra
            }
        }else{ // a propriedade está fora da janela atual - por estar fora, também evitamos mudar muito o zoom para não confundir o usuário
            if (boundindBoxZoom-zoom>2){ // o zoom para fechar na propriedade é mais do que 2 níveis maior (mais fechado)
                map.fitBounds(boundindBox, {padding:[pointX,pointY]}); // foca na propriedade, com folga lateral de 100 pixels
            }else if (boundindBoxZoom-zoom>=0){ // o zoom é um pouco maior, mas dentro do limite
                map.panTo(boundindBox.getCenter());// só centraliza na propriedade
            }else{
                map.fitBounds(boundindBox); // zoom mais aberto - enquadra
            }
        }

        polygon.addTo(map);

        setTimeout(()=>{polygon.removeFrom(map)}, 3000)

    }

}