import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #paper-admin{
            /* min-height: 171px;  */
            padding: 20px; 
            height: 200px; 
            overflow-y: auto; 
            display: flex;
            flex-direction: column;
        } 
    `}
`;
