import * as Styled from './styles';
import { theme } from '../../styles/theme'; 

import {
    Drawer, IconButtonProps
} from '@mui/material';

import { AdminDrawer } from '../AdminDrawer';

interface SidebarProps{
    open: boolean; 
    handleDrawerClose: IconButtonProps["onClick"];
}

export const Sidebar = ({
    open,
    handleDrawerClose
}:SidebarProps) => {

    return (
        <Styled.Container>
            <Drawer
                sx={{
                width: theme.sizes.sidebar,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: theme.sizes.sidebar,
                    boxSizing: 'border-box',
                },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <AdminDrawer
                    handleDrawerClose={handleDrawerClose}
                />
            </Drawer>
        </Styled.Container>
    );

}

