//@ts-nocheck
import styled from 'styled-components';

export const Container = styled.h1`
    display: block;
    width: 50rem;
    height: 20rem;
`;

export const SlideTextLeft = styled.div`
    color: ${props => props.color ? props.color : '#fff'};
    font-size: ${props => props.mobile_first_title_font_size ? props.mobile_first_title_font_size : '5rem'};
    margin: 0px 0 0 ${props => props.isTitle === 'true' ? '-0px' : '-20px'};
    font-weight: 300;
    line-height: 1.5;
    width: 100rem;
    box-sizing: border-box;
    transform: translateX(-100%);
    @media (min-width: ${props => props.theme.screenSizes.for_tablet_landscape_up}) {        
        font-size: ${props => props.font_size1 ? props.font_size1 : '7.4rem'};
        margin: -13px 0 0 90px;
    }
`;

export const SlideTextRight = styled.div`
    text-align: left;
    color: ${props => props.color ? props.color : '#a3dee6'};
    font-weight: 300;
    font-size: ${props => props.mobile_second_title_font_size ? props.mobile_second_title_font_size : '5rem'};
    margin: ${props => props.second_title_margin_top ? props.second_title_margin_top : '-66px'} 0 -115px ${props => props.mobile_second_title_margin_left ? props.mobile_second_title_margin_left : '120px'};
    line-height: 2.3;
    width: max-content;
    /* background-color: pink; */
    box-sizing: border-box;
    transform: translateX(100%);
    @media (min-width: ${props => props.theme.screenSizes.for_tablet_landscape_up}) {        
        font-size: ${props => props.font_size2 ? props.font_size2 : '7.4rem'};
        line-height: 1.5;
        margin: ${props => props.second_title_margin_top ? props.second_title_margin_top : '-66px'} 0 -115px ${props => props.second_title_margin_left ? props.second_title_margin_left : '261px'};
    }
`;

export const SlideTextLeft2 = styled.div`
    color: ${props => props.color ? props.color : '#fff'};
    font-size: ${props => props.mobile_first_title_font_size ? props.mobile_first_title_font_size : '5.2rem'};
    margin: 70px 0 0 ${props => props.isTitle === 'true' ? '-0px' : '-20px'};
    font-weight: 300;
    line-height: 1.5;
    width: 100rem;
    box-sizing: border-box;
    transform: translateX(-100%);
    @media (min-width: ${props => props.theme.screenSizes.for_tablet_landscape_up}) {        
        font-size: ${props => props.font_size1 ? props.font_size1 : '10.4rem'};
        margin: -13px 0 0 90px;
    }
`;

export const SlideSubtitle = styled.div`
    color: ${props => props.color ? props.color : '#fff'};;
    font-size: 32px;
    margin: 0px 0 0 90px;
    font-weight: 300;
    line-height: 1;
    box-sizing: border-box;
`;