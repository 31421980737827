import styled, {
    css
} from "styled-components";

export const PageControls = styled.div`
    ${({theme}) => css`

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: gray;
        opacity: 0;

        button:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        span {
            font: inherit;
            font-size: 0.8em;
            padding: 0 0.5em;
        }

    `}
`;

export const Document = styled.div`

    width: fit-content;
    max-width: 1110px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    max-height: calc(100vh - (80px + 20px));
    max-width: 90vw;
    overflow-y: hidden;
    overflow-x: auto;
    font-size: 19px;
    border-radius: 5px;

    :hover {
        overflow-y: auto;
    }
    
`;

export const Background = styled.div`
    width: 100vw;
    height: 100vh; 
    ${({ theme }) => {
        const background = `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/background-images/background-image.jpg`
        return (
            css`
                background-image: url(${background});
            `
        ); 
    }}

    :hover .document-controller-top {
        position: absolute;
        top: 105px;
        left: 50%;
        background: white;
        transform: translateX(-50%);
        transition: opacity ease-in-out 0.2s;
        box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
        border-radius: 4px;
        opacity: 1;
        transition: opacity ease-in-out 0.2s;
    }

    :hover .document-controller-bottom {
        position: absolute;
        bottom: 10%;
        left: 50%;
        background: white;
        transform: translateX(-50%);
        transition: opacity ease-in-out 0.2s;
        box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
        border-radius: 4px;
        opacity: 1;
        transition: opacity ease-in-out 0.2s;
    }
    
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`