import * as Styled from './styles';

import WhiteIcon from './white.svg';
import ColorIcon from './color.svg';

interface LogoProps {
    whiteColor?:boolean;
};

export const Logo = ({ 
    whiteColor 
}:LogoProps) => {

    return (
        <Styled.Container>
            {whiteColor?
                <img 
                    src={WhiteIcon} 
                    alt="logo" 
                />
            :
                <img 
                    src={ColorIcon}
                    alt="logo" 
                />
            }
        </Styled.Container>
    );

};