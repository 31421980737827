import * as Styled from './styles';

import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';

import {
    PersonOutlineOutlined,
    SecurityOutlined,
    MapOutlined,
    // AnalyticsOutlined,
    Upload,
    MenuBook,
    ExpandLess,
    ExpandMore,
    Layers
} from '@mui/icons-material';

import { 
    useLocation,
    useNavigate 
} from 'react-router-dom';
import { useState } from 'react';
import { useAuthListener } from '../../hooks';

import _ from 'lodash';

/* 
    See the integration with react-router in: https://mui.com/pt/components/breadcrumbs/
*/

export const ListItemAdmin = () => {

    const { sessionUser } = useAuthListener();

    const navigate = useNavigate();

    const location = useLocation();

    const [openDocs, setOpenDocs] = useState(false);

    const checkIfSelected = (pathname:string) => {
        return location?.pathname.includes(pathname)
    }

    const permissions = sessionUser?.permissions?sessionUser?.permissions:[]; 
    
    const checkIfHasPermission = (target:string, resource:string, verb: string) => {
        return permissions?.findIndex((permission:any)=>{
            return permission === `${target}.${resource}.${verb}`; 
        }) !== -1; 
    }
    
    return (
        <Styled.Container>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton
                    selected={checkIfSelected('users')}
                    onClick={() => navigate("/admin/users")}
                >
                    <ListItemIcon>
                        <PersonOutlineOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItemButton>
                {/* 
                <ListItemButton
                    selected={location?.pathname.includes('groups')}
                >
                    <ListItemIcon>
                        <GroupOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Groups" />
                </ListItemButton>
                */}

                <ListItemButton
                    selected={checkIfSelected('roles')}
                    onClick={() => navigate("/admin/roles")}
                >
                    <ListItemIcon>
                        <SecurityOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                </ListItemButton>
                <ListItemButton
                    selected={checkIfSelected('views')}
                    onClick={() => navigate("/admin/views")}
                >
                    <ListItemIcon>
                        <MapOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Views" />
                </ListItemButton>
                <ListItemButton
                    selected={checkIfSelected('layers')}
                    onClick={() => navigate("/admin/layers")}
                >
                    <ListItemIcon>
                        <Layers />
                    </ListItemIcon>
                    <ListItemText primary="Layers" />
                </ListItemButton>
                <ListItemButton
                    selected={checkIfSelected('import')}
                    onClick={() => navigate("/admin/import")}
                >
                    <ListItemIcon>
                        <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Import" />
                </ListItemButton>

                {checkIfHasPermission('api', 'documentation', 'list') &&
                    <>
                        <ListItemButton
                            onClick={(e) => setOpenDocs(!openDocs)}
                        >
                            <ListItemIcon>
                                <MenuBook />
                            </ListItemIcon>
                            <ListItemText primary="Documentation" />
                            {openDocs?<ExpandLess />:<ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openDocs} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <ListItemButton
                                    selected={checkIfSelected('docs-auth')}
                                    onClick={(e) => navigate("/admin/docs-auth")}
                                >
                                    <ListItemText 
                                        primary="Auth Service" 
                                        primaryTypographyProps={{fontSize: '12px'}} 
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    selected={checkIfSelected('docs-big')}
                                    onClick={(e) => navigate("/admin/docs-big")}
                                >
                                    <ListItemText 
                                        primary="BIG Service" 
                                        primaryTypographyProps={{fontSize: '12px'}} 
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    selected={checkIfSelected('docs-images')}
                                    onClick={(e) => navigate("/admin/docs-images")}
                                >
                                    <ListItemText 
                                        primary="Images Service" 
                                        primaryTypographyProps={{fontSize: '12px'}} 
                                    />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {/* <ListItemButton
                    selected={checkIfSelected('analytics')}
                    onClick={() => navigate("/admin/analytics")}
                >
                    <ListItemIcon>
                        <AnalyticsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                </ListItemButton> */}
            </List>
        </Styled.Container>
    );
};