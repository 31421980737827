import { 
    useCallback, 
    useContext, 
    useEffect, 
    useState 
} from "react";

import {
    Grid,
    Collapse
} from '@mui/material';

import { 
    ExpandLess, 
    ExpandMore
} from '@mui/icons-material';

import { DragHandle } from '../DragHandle';
import { SortableItem } from '../SortableItem';
import { ListLayers } from '../ListLayers';
import { theme } from "../../styles/theme";
import { GlobalMapContext } from "../../routes";
import { LeafletGroupItem } from "./leaflet-group-item";

interface GroupItemProps {
    value: string;
    index: number; 
    id: string | null; 
    onlyActives?: boolean; 
    onlyFavorites?: boolean; 
    search?:string; 
}

export const GroupItem = ({
    value, 
    index,
    id,
    onlyActives,
    onlyFavorites,
    search
}:GroupItemProps) => {

    const [hover, setHover] = useState(false);

    const handleChangeHover = useCallback((value)=>{
        setHover(value);
    }, [])
    
    const map:any = useContext(GlobalMapContext);

    const [groupController, setGroupController] = useState<LeafletGroupItem>();
    const [list, setList] = useState<boolean>();

    useEffect(()=> {
        if(map?._state!==undefined){
            setGroupController(new LeafletGroupItem(map, id));
        }
    },[id, map, map?._state])

    useEffect(()=>{
        if(groupController!==undefined){
            setList(groupController.getGroupState());
        }
    },[groupController])

    const handleClick = () => {
        if(list!==undefined && groupController!==undefined){
            groupController.setGroupState(!list);
            setList(!list);
        }
    }

    /**Include total of layers in a group! */

    return(
        <SortableItem key={`item-${value}`} index={index}>
            <Grid 
                sx={{width: '100%', marginBottom: '2px', borderTop:`solid ${theme.colors.gray} 2px`, borderBottom: `solid ${theme.colors.gray} 1px`, borderRadius: '8px'}}
                onMouseEnter={() => handleChangeHover(true)}
                onMouseLeave={() => handleChangeHover(false)}
            >
                <Grid container sx={{background: theme.colors.secondaryRgba, borderRadius: 2, padding: '5px'}}>
                    <Grid item xs={1}>
                        {!list&&hover&&<DragHandle />}
                    </Grid>
                    <Grid item xs={10}>
                        <span>
                            <b>
                                {value}
                            </b>
                            &nbsp; 
                            <span id={`number-active-group-${id}`} style={{fontSize: "13px"}}></span>
                            <span style={{fontSize: "13px"}}>{groupController?.getGroupSize()}</span>
                        </span>
                    </Grid>
                    <Grid item xs={1} onClick={handleClick}>
                        {list?<ExpandLess /> : <ExpandMore />}
                    </Grid>
                </Grid>
                <Collapse in={list} unmountOnExit>
                    <Grid
                        sx={{padding: '8px'}}
                    >
                        <ListLayers 
                            search={search}
                            groupId={id}
                            onlyActives={onlyActives}
                            onlyFavorites={onlyFavorites}
                        />
                    </Grid>
                </Collapse>
            </Grid>
        </SortableItem>
    );

}