import firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);

  // // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
  // // site key and pass it to activate().
  // const appCheck = firebase.appCheck();
  // appCheck.activate(
  //   "6LeP_OseAAAAAKLP6LyNG5O5jZuJ_PuFMBI56jzZ",

  //   // Optional argument. If true, the SDK automatically refreshes App Check
  //   // tokens as needed.
  //   true
  // );
}

export const auth = firebase.auth();
