
export class LeafletDrawerController {

    private map:any; 

    constructor(map:any){
        this.map = map; 
    }

    public setModeMap(mode:"list"|"group"){
        this.map._state.mode = mode; 
    }

    public getModeMap(){
        if(this.map._state.mode!==undefined){
            return this.map._state.mode; 
        } else {
            return 'group';
        }
    }

    public toggleOnlyActiveLayers(value:boolean) {
        if(this.map._state?.onlyActiveLayers!==undefined){
            this.map._state.onlyActiveLayers = value;
        }
    }

    public getOnlyActiveLayers():boolean {
        if(this.map._state.onlyActiveLayers!==undefined){
            return this.map._state.onlyActiveLayers;
        }

        return false; 
    }

    public toggleOnlyFavoriteLayers(value:boolean){
        if(this.map._state?.onlyFavoriteLayers!==undefined){
            this.map._state.onlyFavoriteLayers = value;
        }
    }

    public getOnlyFavoriteLayers():boolean {
        if(this.map._state.onlyFavoriteLayers!==undefined){
            return this.map._state.onlyFavoriteLayers;
        }

        return false; 
    }

}