import { Layers } from "../../models/Layers";


export class LeafletListLayers {

    private map: any; 
    private groupId?: string | null; 

    constructor(map:any, groupId?: string | null){
        this.map = map; 
        this.groupId = groupId;
    }

    public getListLayers (onlyActives?:boolean, onlyFavorites?:boolean){
        
        const groupId:any = this.groupId;

        const checkCondition = (layer:any) => {

            const onlyActiveCondition = this.map._state.layers.indexOf(layer.txt_code)!== -1; 

            const onlyFavoritesCondition = this.map._state.favoriteLayers.indexOf(layer.txt_code) !== -1;

            if(onlyActives&&onlyFavorites){
                return onlyActiveCondition && onlyFavoritesCondition;
            }

            if(onlyActives){
                return onlyActiveCondition;
            }

            if(onlyFavorites){
                return onlyFavoritesCondition;
            }

        }

        /**If group is needed to pass the group ID */
        if(groupId===undefined){

            if(onlyActives&&onlyFavorites){
                return this.map._state.listOrder.filter(checkCondition); //Order filtered by list
            }

            if(onlyActives){
                return this.map._state.listOrder.filter(checkCondition); //Order filtered by list
            } 

            if(onlyFavorites){
                return this.map._state.listOrder.filter(checkCondition); //Order filtered by list
            } 
                
            return this.map._state.listOrder; //Order by list 
        }else{

            if(onlyActives&&onlyFavorites){
                return this.map._state.groupedLayersOrder[groupId].filter(checkCondition);; //Order by group
            }

            if(onlyFavorites){
                return this.map._state.groupedLayersOrder[groupId].filter(checkCondition);; //Order by group
            }

            if(onlyActives){
                return this.map._state.groupedLayersOrder[groupId].filter(checkCondition);; //Order by group
            }
                
            return this.map._state.groupedLayersOrder[groupId];
        }
    }

    public setGroupLayers (listOrder:Array<Layers>){

        const groupId:any = this.groupId;

        /**If group is needed to set in specific group ID */
        if(groupId===undefined){
            this.map._state.listOrder = listOrder; 
        } else {
            this.map._state.groupedLayersOrder[groupId] = listOrder; 
        }

        this.setZIndex();
        
    }

    public setZIndex (){

        if(this.groupId===undefined){ //Order by list
            this.map._state.listOrder?.map((layer:any)=>{

                const txtCode = layer?.txt_code; 
                const config = JSON.parse(layer.config);

                const zIndex = 400 - this.map._state.listOrder.findIndex((layer:any)=> layer.txt_code === txtCode);

                switch (txtCode){

                    case "ctrt":
                        this.map.getPane('ctrt').style.zIndex = zIndex;
                        break;
                    case "newprop":
                        this.map.getPane('newprop').style.zIndex = zIndex;
                        break;
                    case "aerg":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('aerg').style.zIndex = zIndex;
                        break;
                    case "midia":
                        console.log("Treat exception!");
                        break;
                    default:
                        if(this.map.getPane(config.name)!==undefined){
                            this.map.getPane(config.name).style.zIndex = zIndex;
                        }

                }

                return true;
            })
        } else { //Order by group

            /**This controller only deal with elements inside a group */
            const groupId:any = this.groupId;

            this.map._state.groupedLayersOrder[groupId].map((layer:any)=>{

                const txtCode = layer?.txt_code; 
                const config = JSON.parse(layer.config);

                /**Index inside a group! */
                const indexInsideGroup = this.map._state.groupedLayersOrder[groupId].findIndex((layer:any)=> layer.txt_code === txtCode);

                /**Index of group! */
                const indexOfGroup = this.map._state.groupOrder.findIndex((group:any)=> group.id === groupId);

                /**Array of ordened lengths of groups */
                const ordenedLengths = this.map._state.groupOrder.map((group:any)=>{
                    return this.map._state.groupedLayersOrder[group.id].length;
                });
                
                /**Acummulate only the values in range of a index group */
                const accumulatedValues = ordenedLengths.slice(0, indexOfGroup+1);

                /**Sum all the values of an array of numbers */
                const sumAccumulatedValues = accumulatedValues.reduce((accum:number, a:number)=>accum + a);

                /**Length of group */
                const lengthOfGroup = this.map._state.groupedLayersOrder[groupId].length;

                /**Calculate the zIndex of layers */
                const zIndex = 400 - sumAccumulatedValues + ( lengthOfGroup - indexInsideGroup );

                switch (txtCode){

                    case "ctrt":
                        this.map.getPane('ctrt').style.zIndex = zIndex;
                        break;
                    case "newprop":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('newprop').style.zIndex = zIndex;
                        break;
                    case "aerg":
                        /**Set the zIndex value by value of pane*/
                        this.map.getPane('aerg').style.zIndex = zIndex;
                        break;
                    case "midia":
                        console.log("Treat exception!");
                        break;
                    default:    
                        /**Set the zIndex value by value of pane*/
                        if(this.map.getPane(config.name)!==undefined){
                            this.map.getPane(config.name).style.zIndex = zIndex;
                        }
                }

                return true;

            })

        }

    }
}