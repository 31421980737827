import {
    useState
} from 'react';
import * as Styled from './styles';

import {
    Paper,
    IconButton,
    TextField
} from '@mui/material';

import {
    Search,
    Clear
} from '@mui/icons-material';
import { DialogSearch } from '../DialogSearch';
import { useLocation } from 'react-router-dom';

interface SearchProps {
    children: any;
    width?: string;
}

export const SearchArea = ({
    children,
    width='100%'   
}:SearchProps) =>{

    const location = useLocation();

    const [value, setValue] = useState<string>("");

    const handleValue = (e:any) => {
        setValue(e.target.value)
    }

    const clearSearch = () => [
        setValue("")
    ]

    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = (e:any) => {
        if(location.pathname==='/map'){
            e.preventDefault();
            setOpen(true);
        }
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Styled.Container>
            <Paper
                component="form"
                onSubmit={handleClickOpen}
                sx={{display: 'flex', alignItems: 'center', width: width}}
                elevation={1}
            >    
                <TextField
                    sx={{ flex: 1 }}
                    placeholder={children}
                    onChange={handleValue}
                    value={value}
                    id="search"
                    autoComplete='off'
                    disabled={location.pathname!=='/map'}
                    InputProps={{
                        startAdornment: (
                            <IconButton 
                                sx={{ p: '10px' }} 
                                aria-label="search"
                                onClick={handleClickOpen}
                            >
                                <Search />
                            </IconButton>
                        ),
                        endAdornment: (
                            <IconButton
                              title="Clear"
                              aria-label="Clear"
                              size="small"
                              style={{ visibility: value ? 'visible' : 'hidden' }}
                              onClick={clearSearch}
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Paper>
            <DialogSearch 
                open={open}
                handleClose={handleClose}
                searchText={value}
            />
        </Styled.Container>
    );
}