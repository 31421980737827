import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.fonts.sizes.small};
        padding: 20px; 

        .MuiPaper-root > svg[data-testid="AccountCircleIcon"]{
            height: 140px;
            width: 140px;
            color: ${theme.colors.primary}
        }

        #edit-button {
            text-decoration: none;
            width: auto;
        }

        #photo, #edit-profile-button{
            display: flex; 
            justify-content: center;
            background: transparent; 
        }

        #info {
            min-width: 50px;
            overflow-x: auto;
            display: flex;
            align-items: center;
            color: ${theme.colors.primary}
        }

        #user-details{
            border-radius: 10px;
            font-size: 12px;
            min-width: 50px;
            overflow-x: auto;
            height: 180px;
        }
    `}
`;
