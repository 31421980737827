import { LeafletTileLayers } from "./leaflet-tilelayer";

declare const L:any; 

export class LayersControllerClass{

    private map: any; 
    private overlays: any;

    constructor(map:any){
        this.map = map; 
        this.overlays = null;
        this.config();
    }

    private config () {

        /**Create tile layers to insert on the DOM! */
        const first = new LeafletTileLayers(
            "default", 
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", 
            "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
            ['a', 'b', 'c']
        ).tileLayer();
        
        const blackAndWhite = new LeafletTileLayers(
            "blackwhite", 
            "https://stamen-tiles{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png", 
            `&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>`,
            ['', '-a', '-b', '-c', '-d']
        ).tileLayer();

        const white = new LeafletTileLayers(
            "white", 
            "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png", 
            `&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>`,
            ['a', 'b', 'c', 'd']
        ).tileLayer();

        const dark = new LeafletTileLayers(
            "dark", 
            "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png", 
            `&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>`,
            ['a', 'b', 'c', 'd']
        ).tileLayer();

        const ground = new LeafletTileLayers(
            "ground", 
            "https://stamen-tiles{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png", 
            `&copy; Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>`,
            ['', '-a', '-b', '-c', '-d']
        ).tileLayer();

        const topography = new LeafletTileLayers(
            "topography", 
            "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png", 
            `Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)`,
            ['a', 'b', 'c']
        ).tileLayer();
        
        first.setZIndex(1);
        blackAndWhite.setZIndex(2);
        white.setZIndex(3);
        dark.setZIndex(4); 
        ground.setZIndex(5);
        topography.setZIndex(6); 

        this.map.baseLayersArray = [first, blackAndWhite, white, dark, ground, topography];

        const map = this.map; 

        /**Changing the mapbase variable to manipulate in the DOM */
        this.map.baseLayersArray.map((base:any)=>{
            base.on('add', function(){
                map.base = base;
            });
            return true; 
        })

        /**Create a object to attribute at control */
        const baseLayers = {
            "Open Street Map": first,
            "Black and White": blackAndWhite,
            "White Background": white, 
            "Dark": dark, 
            "Ground": ground, 
            "Topography": topography
        }

        /**Create a controller */
        this.map.layersControl = L.control.layers(baseLayers, this.overlays, { position: 'topleft' });

        /**Add to map */
        this.map.layersControl.addTo(this.map);

    }

}