import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #user-title-info{
            font-size: ${theme.fonts.sizes.small};
            padding: 0 20px; 
        }

        #tab-admin-title{
            background: transparent!important;
        }

        #top-tab-admin{
            margin-top: 0;
        }

        #list-permissions{
            padding: 0 30px;
            margin-bottom: 30px;
        }
    `}
`;