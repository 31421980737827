import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Box,
    TextField,
    Paper,
    Grid,
    CircularProgress,
    Typography,
    FormControlLabel,
    Checkbox,
    Autocomplete
} from '@mui/material';

import axios from 'axios';

import { MultipleSelectChip } from '../MultipleSelectChip';
import { InfoSelectedUserSession } from '../../models/Users';
import { StepsController } from '../StepsController';
import { AuthContext } from '../../routes/admin';

interface UserRegistrationStepOneProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info: InfoSelectedUserSession;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    edit: boolean;
}

export const UserRegistrationStepOne = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    edit
}:UserRegistrationStepOneProps) => {

    const context:any = useContext(AuthContext);
    
    const [ tempValue, setTempValue ] = useState(info);
    const [ enableNext, setEnableNext ] = useState(false);
    const [ validValue, setValidValue ] = useState({
        name: false, 
        email: false,
        phone: false,
        roles: false, 
        views: false, 
    })

    const [ errors, setErrors ] = useState({
        name: false,
        email: false, 
        phone: false,
        roles: false, 
        views: false, 
    })

    const handleCheckboxValue = (e:any) => {
        setTempValue({...tempValue, [e.target.name]:e.target.checked})
    }

    console.log(tempValue)

    const handleTextValue = (e:any) => {
        setTempValue({...tempValue, [e.target.name]:e.target.value})
    }

    const checkIfNameIsValid = () => {
        if(tempValue.name){
            setValidValue({...validValue, name: true})
            setErrors({...errors, name: false})
        }else{
            setValidValue({...validValue, name: false})
            setErrors({...errors, name: true})
        }
    }

    const checkIfEmailIsValid = () => {
        if(tempValue.email){
            setValidValue({...validValue, email: true})
            setErrors({...errors, email: false})
        }else{
            setValidValue({...validValue, email: false})
            setErrors({...errors, email: true})
        }
    }

    const checkIfRoleIsValid = () => {

        if(tempValue.roles.length > 0) {
            setValidValue({...validValue, roles: true})
            setErrors({...errors, roles: false})
        }else{
            setValidValue({...validValue, roles: false})
            setErrors({...errors, roles: true})
        }
    }

    const checkIfViewsIsValid = () => {

        if(tempValue.views.length > 0) {
            setValidValue({...validValue, views: true})
            setErrors({...errors, views: false})
        }else{
            setValidValue({...validValue, views: false})
            setErrors({...errors, views: true})
        }
    }

    useEffect(() => {
        if(tempValue.name && tempValue.email && tempValue.views.length > 0 && tempValue.roles.length > 0 && tempValue.country) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [tempValue])

    const [roles, setRoles] = useState();
    const [views, setViews] = useState();
    const [countries, setCountries] = useState<Array<any>>();

    async function contentRoles() {

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles';

        await axios.get(endpoint, {
                 headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                 }
            })
            .then(function (response) {
                setRoles(response.data);
            })
            .catch(function (error) {
                alert("Error trying load roles!");
            });

    }

    
    async function contentViews() {

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/views';

        await axios.get(endpoint, {
                 headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                 }
            })
            .then(function (response) {
                setViews(response.data);
            })
            .catch(function (error) {
                alert("Error trying load views!");
            });
    }

    async function contentCountries () {

        await axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/api/country`, {
                        headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                        }
                })
                .then((res)=>{
                    const countries = res.data.map((country:any)=>{

                        return({
                            id: country.id, 
                            code: country.code_txt,
                            label: `${country.name}`,
                            flagUrl: `${country.flag_url}`
                        })

                    })
                    setCountries(countries)
                })
                .catch(()=>{

                })

    }

    useEffect(() => {

        if(context.user?.accessToken && !roles && !views && !countries){
            contentRoles();
            contentViews();
            contentCountries();
        }

        return () => {
            setViews(undefined);
            setRoles(undefined);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.user?.accessToken])

    return (
        <Styled.Container>
            <Box
                component="form"
                autoComplete="off"
                noValidate
            >
                <div id='title-create-group'>User registration</div>
                <Paper elevation={3} id="form-admin">
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom component="div" id="user-title-info">General Informations</Typography>
                        </Grid>
                    </Grid>
                    <form  id="form-create-group" noValidate>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            required
                            autoComplete='off'
                            onChange={handleTextValue}
                            onBlur={checkIfNameIsValid}
                            error={errors.name}
                            defaultValue={tempValue.name}
                            helperText={errors.name && "This value is required."}
                        /> 
                        <FormControlLabel
                            control={<Checkbox 
                                        value={tempValue.isAdmin===true}
                                        name={'isAdmin'}
                                        onChange={handleCheckboxValue}
                                        defaultChecked={tempValue.isAdmin===true}
                                        // disabled={basemap}
                                        size={"small"}
                                    />} 
                            label={<Typography variant="body2" color="textSecondary">IS ADMIN?</Typography>}
                            sx={{fontSize: '7px', paddingTop: '2px'}}
                        />
                    </form >
                    <form  id="form-create-group" noValidate>
                        {countries!==undefined?
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: '100%' }}
                                options={countries}
                                autoComplete
                                onChange={(event: any, newValue: any | null) => {
                                    setTempValue({...tempValue, country: newValue})
                                }}
                                onClose={()=> console.log("Teste")}
                                defaultValue={tempValue.country}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`${option.flagUrl}`}
                                            srcSet={`${option.flagUrl} 2x`}
                                            alt=""
                                        />
                                        {option.label}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Choose a country"
                                        autoComplete='off'
                                        required
                                        error={tempValue?.country===undefined}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                          />
                        :
                        <CircularProgress/>
                        }
                    </form>
                    <form id="form-create-group" noValidate>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            disabled={edit}
                            required
                            autoComplete='off'
                            onChange={handleTextValue}
                            onBlur={checkIfEmailIsValid}
                            error={errors.email}
                            defaultValue={tempValue.email}
                            helperText={errors.email && "This value is required."}
                        /> 
                    </form>
                    <form id="form-create-group" noValidate> 
                        <TextField
                            fullWidth
                            label="Phone"
                            name="phone"
                            autoComplete='off'
                            onChange={handleTextValue}
                            defaultValue={tempValue.phone}
                        />         
                    </form>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom component="div" id="user-title-info">Roles and Views</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} sx={{textAlign: 'center'}}>
                                <MultipleSelectChip 
                                    title="Roles"
                                    name="roles" 
                                    options={roles}
                                    onChange={handleTextValue} 
                                    onClose={checkIfRoleIsValid}
                                    value={tempValue.roles}
                                    error={errors.roles}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
                                <MultipleSelectChip 
                                    title="Views"
                                    name="views" 
                                    options={views}
                                    onChange={handleTextValue} 
                                    onClose={checkIfViewsIsValid}
                                    value={tempValue.views}
                                    error={errors.views}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>     
            <StepsController
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                tempValue={tempValue}
            />
        </Styled.Container>
    );

}
