//@ts-nocheck
import { Fade, Grid, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { Header } from "../../components/Header";
import { styled, Paper } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { Public } from "@mui/icons-material";

// import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useState } from "react";

import ReactMarkdown from 'react-markdown';
import TitleSideSlide from "../../components/TitleSideSlide";
import { HomeTopPageBox } from "./HomeContent";

import './styles.css';

const height = 40; 

const Item = styled(Paper)(({ theme }) =>{ 

    return ({
        ...theme.typography.body1,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        height: '100%',
        boxShadow: theme.shadows[0],
        borderRadius: 0
    });

});

export const Home = () => {

    const year = new Date().getFullYear(); 

    const matches = useMediaQuery('(min-width:850px)');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const linkedin = 'https://www.linkedin.com/company/casa-dos-ventos-energias-renov-veis';
    const instagram = 'https://www.instagram.com/casadosventos/?hl=pt-br';
    // const facebook = 'https://www.facebook.com/casadosventosenergiasrenovaveis';
    const youtube = 'https://www.youtube.com/channel/UCSfgnyHSVLaYt5N8g1HmT5Q';
    // const twitter = 'https://twitter.com/acasadosventos';

    return(
        <>
            <Header 
                open={false} 
                omitOpenButton={true}
            />
            <Grid container>
            
                <div id="background-home" style={{height: `calc(100vh - ${height}px)`}}>
                    <div
                        style={{
                            width: '100vw'
                        }}
                    >
                        <Toolbar/>
                        <div
                            id="content-home"
                        >
                            <HomeTopPageBox>
                                <TitleSideSlide className='title' title_list={['CdV', 'GIS']} isTitle={'true'} />
                                <div className={true ? 'animate__animated animate__slideInUp content' : 'content'}>
                                    <ReactMarkdown>
                                        **Welcome to Casa dos Ventos GIS Platform!**
                                    </ReactMarkdown>
                                    <ReactMarkdown>
                                        All geographic data for your renewable project available to you in one place.
                                        You can follow up important updates about land lease management or any other GIS data. 
                                        Select the desired view on the [Map](/map) tab and navigate to find all information you need.
                                    </ReactMarkdown>
                                    <ReactMarkdown>
                                        Any doubt, contact our team [here](/contact).
                                    </ReactMarkdown>
                                </div>
                            </HomeTopPageBox>
                        </div>
                    </div>

                </div>
                <Grid container
                    sx={{
                        height: height, 
                        fontSize: '1.2rem', 
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <Grid item xs md sx={{height: '100%', }}>
                        <Item/>
                    </Grid>
                    <Grid item xs={8} sm={8} sx={{height: '100%', fontSize: 10}}>
                        <Item>
                            <span style={{fontSize: 14}}>GIS</span> &copy; <span style={{fontSize: 12}}><a href="https://casadosventos.com.br/" style={{color: '#088BAE', textDecoration: 'none'}} target='_blank' rel="noreferrer">Casa dos Ventos</a> {year===2021?year:"2021-"+year}.</span>
                        </Item>
                    </Grid>
                    <Grid item xs md sx={{height: '100%'}}>
                        <Item>
                    {matches?
                        <Grid container spacing={0}>
                            <Grid item xs>
                                <LinkedInIcon
                                    sx={{
                                        color:'#088BAE'
                                    }}
                                    onClick={()=> window.open(linkedin)}
                                />
                            </Grid>
                            <Grid item xs>
                                <InstagramIcon
                                    sx={{
                                        color:'#088BAE'
                                    }}
                                    onClick={()=> window.open(instagram)}
                                />
                            </Grid>
                            {/* <Grid item xs>
                                <FacebookIcon
                                    sx={{
                                        color:'#088BAE'
                                    }}
                                    onClick={()=> window.open(facebook)}
                                />
                            </Grid> */}
                            {/* <Grid item xs>
                                <TwitterIcon
                                    sx={{
                                        color:'#088BAE'
                                    }}
                                    onClick={()=> window.open(twitter)}
                                />
                            </Grid> */}
                            <Grid item xs>
                                <YouTubeIcon
                                    sx={{
                                        color:'#088BAE'
                                    }}
                                    onClick={()=> window.open(youtube)}
                                />
                            </Grid>
                        </Grid>
                        :
                        <>
                        <Grid 
                            item 
                            xs={12} 
                            sx={{
                                textAlign: 'end',
                                paddingTop: 0
                            }}
                        >  
                            <IconButton
                                sx={{
                                    padding: 0
                                }}
                                onClick={handleClick}
                            >
                                <Public />
                            </IconButton>
                            <Menu
                                MenuListProps={{
                                    'aria-labelledby': 'menu-mobile',
                                    'id':'menu-mobile'
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <LinkedInIcon
                                        sx={{
                                            color:'#088BAE'
                                        }}
                                        onClick={()=> window.open(linkedin)}
                                    />
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <InstagramIcon
                                        sx={{
                                            color:'#088BAE'
                                        }}
                                        onClick={()=> window.open(instagram)}
                                    />
                                </MenuItem>
                                {/* <MenuItem
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <FacebookIcon
                                        sx={{
                                            color:'#088BAE'
                                        }}
                                        onClick={()=> window.open(facebook)}
                                    />
                                </MenuItem> */}
                                {/* <MenuItem
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <TwitterIcon
                                        sx={{
                                            color:'#088BAE'
                                        }}
                                        onClick={()=> window.open(twitter)}
                                    />
                                </MenuItem> */}
                                <MenuItem
                                    sx={{
                                        height: 10
                                    }}
                                >
                                    <YouTubeIcon
                                        sx={{
                                            color:'#088BAE'
                                        }}
                                        onClick={()=> window.open(youtube)}
                                    />
                                </MenuItem>
                            </Menu>
                            </Grid>
                        </>
                    }
                        </Item>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}