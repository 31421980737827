import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        header{
            background: ${theme.colors.primary};
            color: ${theme.colors.dark};
            display: flex; 
            justify-content: space-between!important;
            width: '100vw';
        }

        .opened-menu{
            transition: width 1s; 
            width: ${"calc(100% - "+theme.sizes.sidebar+")"};
        }

        ul{
            max-width: 650px; 
        }
    `}
`;

export const Logo = styled.div`
    max-width: 197px; 
`;