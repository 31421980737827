import firebase from 'firebase';
import { Alert, Button, InputAdornment, Snackbar, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { AccountCircle } from '@mui/icons-material';

import {
    useNavigate
} from 'react-router-dom';

import axios from 'axios'; 
import { DeviceVerification } from '../DeviceVerification';

interface LoginProps {
    defaultEmail:string; 
    handleBackToLogin: any;
}

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const Login = ({
    defaultEmail,
    handleBackToLogin
}:LoginProps) => {

    const [login, setLogin] = useState({
        email: defaultEmail,
        password: ""
    });

    /**ERROR MESSAGES */
    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showErrorMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    const [disableForms, setDisableForms] = useState(false);

    const navigate = useNavigate();

    const signInWithEmailAndPassword = (email:string, password:string) => {

        firebase.auth().signInWithEmailAndPassword(email, password)
                        .then(async (userCredential)=>{

                            // User signed in. No 2nd factor challenge is needed.
                            const { user } = userCredential;

                            await checkEmailVerified(user);
                            
                        })
                        .catch((e)=>{

                            const { code, message } = e; 
                            showErrorMessage({
                                text: message,
                                severity: 'error'
                            })
                            setDisableForms(true);
                            setTimeout(handleBackToLogin, 3500);
                            
                        })

    }

    // Time to delete user before email verification (seconds)

    const [resendEmailVerification, setResendEmailVerification] = useState(false);
    const [activeSendNewVerificationEmail, setActiveSendNewVerificationEmail] = useState(false);

    const handleDisableVerificationEmail = (error:boolean) => {

        setActiveSendNewVerificationEmail(false);
        if(!error)
            showErrorMessage({
                text: "A verification email was sent, please await 30 seconds to send a new email.",
                severity: 'info'
            });
        setTimeout(()=>{
            setActiveSendNewVerificationEmail(true);
            showErrorMessage({
                text: "Please check you inbox to see if you receive a verification email.",
                severity: 'warning'
            });
        }, 30000)

    }

    const [deviceVerification, setDeviceVerification] = useState<any>();

    const checkEmailVerified = async (user: firebase.User | null) => {

        if(!user){
            console.log("checkEmailVerified | User not found!");
        } else if (!user.emailVerified){

            user?.sendEmailVerification();
            showErrorMessage({
                text: "You need to verify you email to access the application.",
                severity: 'error'
            });
            setResendEmailVerification(true);

        } else {

            if(user){
                const accessToken = await user?.getIdToken();

                await axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/api/users/session`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((res)=>{
                    const {
                        device_verified
                    } = res.data; 

                    const sessionUser = res.data;

                    if(!device_verified){
                        setDeviceVerification({
                            sessionUser,
                            accessToken
                        })
                    }
                })
            }

        }

    }

    /**Resend email verification */
    const sendEmailVerify = () => {
        const user = firebase.auth().currentUser;
        if(user)
            user?.sendEmailVerification()
                    .then(()=>handleDisableVerificationEmail(false))
                    .catch((e:any)=>{
                        const { message } = e;

                        showErrorMessage({
                            text: message,
                            severity: 'error'
                        })

                        handleDisableVerificationEmail(true);
                        
                    });
    }

    return (
        <>
        {deviceVerification===undefined?
        <>
            <Formik
                enableReinitialize={true}
                initialValues={login}
                validateOnChange={false}
                onSubmit={(values, actions) => {
                    const { email, password } = values;

                    if (
                        password !== undefined
                    ) {
                        setLogin({
                            ...login, 
                            password
                        });

                        signInWithEmailAndPassword(email, password);
                        handleDisableVerificationEmail(true);

                    }
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Invalid Email.").required("Required field."),
                    password: Yup.string().required("Required field."),
                })}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur
                }) => (
                    <Form 
                        noValidate
                    >
                        <TextField
                            required
                            disabled
                            autoComplete='off'
                            id="email"
                            name="email"
                            label="Email Adress"
                            variant="standard"
                            className="txt-field"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={handleBackToLogin}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle 
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                cursor: 'pointer'
                            }}
                            value={values.email}
                            helperText={
                                errors.email && touched.email ? errors.email : ""
                            }
                            error={errors.email && touched.email ? true : false}
                        />
                        <TextField
                            required
                            autoFocus
                            disabled={disableForms}
                            autoComplete='off'
                            className="txt-field"
                            variant="standard"
                            label="Password"
                            id="password"
                            name="password"
                            type={"password"}
                            value={values.password}
                            helperText={
                                errors.password && touched.password ? errors.password : ""
                            }
                            error={errors.password && touched.password ? true : false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <Button
                            disabled={disableForms}
                            variant="contained"
                            id='btn-next'
                            className="btn-login"
                            type='submit'
                        >
                            Next
                        </Button>
                        {resendEmailVerification&&
                            <Button
                                disabled={!activeSendNewVerificationEmail}
                                variant="outlined"
                                id='btn-email-verified'
                                className="btn-second"
                                onClick={sendEmailVerify}
                            >
                                Send Email 
                            </Button>
                        }
                    </Form>
                )}
            </Formik>
            <Snackbar
                open={errorMessage}
                autoHideDuration={3000}
                onClose={handleCloseErrorMessage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert onClose={handleCloseErrorMessage} severity={message.severity} sx={{ width: '100%' }}>
                    {message.text}
                </Alert>
            </Snackbar>
        </>
        :
        <DeviceVerification 
            accessToken={deviceVerification?.accessToken} 
            sessionUser={deviceVerification?.sessionUser}     
        />
        }
        </>
    )

}