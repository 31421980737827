import './leaflet-legend.css';
import 'leaflet-control-custom/Leaflet.Control.Custom';

declare const L: any;

export class LeafletLegend {

    private map: any;

    constructor(map:any, pos:string){

        this.map = map; 
        this.config(pos);

    }

    private config (pos:string) {

        const map = this.map;

        this.map.legend = L.control.custom({
            position: pos,
            content : '<a href="#" id="linkLegenda" class="linkLegenda" title="Show legend" style="width:19px;height:19px"></a>'
                    +'<div id="divLegenda"></div><div id="semLegendas">No active layers&nbsp;&nbsp;</div>',
            classes : 'leaflet-control legenda-control',
            style   : {
            },
            id:'wrapperLegenda',
            datas   :{
            },
            events:
            {
                //toggle legend
                click: function(){ 
                    map.legend.open = !map.legend.open;
                    const element = document.getElementById('wrapperLegenda');
                    
                    if(map.legend.open){
                        element?.classList.add('legendaAberta');
                    }
                }
                // open legend but don't mark as open, so you can close in mouseout
                ,mouseover: function(){
                    const element = document.getElementById('wrapperLegenda');
                    element?.classList.add('legendaAberta');
                }
                // close if the legend wasn't open
                ,mouseout: function(){

                    const element = document.getElementById('wrapperLegenda');
                    if(!map.legend.open){
                        element?.classList.remove('legendaAberta');
                    }
                }
            }
        });

        this.map.legend.open = false; //Setting the default value to legend
        this.map.legend.layers = []; //Number of active layers
        this.map.legend.addTo(map);

    }

    public setContent (text:string) {

        console.log(this.map.legend);
        // this.map.legend.content = text; 
    }

}