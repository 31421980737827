import { 
    Alert, 
    Button, 
    InputAdornment, 
    Snackbar, 
    TextField 
} from "@mui/material";
import React, { useState } from "react";
import firebase from "firebase";
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { 
    Form, 
    Formik 
} from "formik";
import * as Yup from "yup";
import { AccountCircle } from "@mui/icons-material";

interface RecoveryProps {
    defaultEmail?: string; 
    handleBackToLogin: React.MouseEventHandler;
}

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const Recovery = ({defaultEmail, handleBackToLogin}:RecoveryProps) => {

    const [email, setEmail] = useState(defaultEmail);
    const [sent, setSent] = useState(false);

    /**ERROR MESSAGES */
    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showErrorMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    const handleSendRecoveryEmail = (email:string) => {

        firebase.auth().sendPasswordResetEmail(email)
                        .then((res)=>{
                            setSent(true);
                        })
                        .catch((e)=>{
                            const { code, message } = e; 

                            if(code === "auth/user-not-found"){
                                showErrorMessage({
                                    text: message, 
                                    severity: 'error'
                                });
                            }
                            setSent(false);
                        })
        
    }

    return(
        !sent?
        <>
            <div
                id="not-found-title"
            >
                Password Recovery
            </div>
            <ScheduleSendOutlinedIcon 
                className="user-not-found-error"
                sx={{
                    fontSize: '204px',
                    padding: '10px'
                }}
            />
            <p
                id="not-found-text"
            >
                Upon confirmation you will receive a password recovery email.
            </p>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: email
                }}
                validateOnChange={false}
                onSubmit={(values, actions) => {
                    const { email } = values;

                    if (
                        email !== undefined
                    ) {
                        handleSendRecoveryEmail(email);
                    }
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                    .email("Invalid Email.")
                    .required("Invalid email.")
                })}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur
                }) => (
                    <Form 
                        noValidate
                    >
                        <TextField
                            required
                            autoComplete='off'
                            defaultValue={defaultEmail}
                            id="email"
                            name="email"
                            label="Email Adress"
                            variant="standard"
                            className="txt-field"
                            value={email}
                            onChange={(e:any) => { 

                                const { value } = e.target; 

                                setEmail(value);

                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle 
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            helperText={
                                errors.email && touched.email ? errors.email : ""
                            }
                            error={errors.email && touched.email ? true : false}
                        />
                        <Button
                            variant="contained"
                            id='btn-next'
                            className="btn-login"
                            type="submit"
                        >
                            Send
                        </Button>
                        <Button
                            variant="outlined"
                            id='btn-second'
                            className="btn-second"
                            onClick={handleBackToLogin}
                        >
                            Back To Login
                        </Button>
                    </Form>
                )}
            </Formik>
            <Snackbar
                open={errorMessage}
                autoHideDuration={3000}
                onClose={handleCloseErrorMessage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert onClose={handleCloseErrorMessage} severity={message.severity} sx={{ width: '100%' }}>
                    {message.text}
                </Alert>
            </Snackbar>
        </>
        :
        <>
            <div
                id="not-found-title"
            >
                Email sent
            </div>
            <MarkEmailReadOutlinedIcon
                className="user-not-found-error"
                sx={{
                    fontSize: '204px',
                    padding: '10px'
                }}
            />
            <p
                id="not-found-text"
            >
                Your password recovery email has been sent, please verify your inbox.
            </p>
            <Button
                variant="contained"
                id='btn-next'
                className="btn-login"
                onClick={handleBackToLogin}
            >
                Back To Login
            </Button>
        </>
    )

}