import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import { 
    Stack,
    Paper,
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    List,
    ListItem,
    CircularProgress,
    Box,
    styled,
    Divider
} from '@mui/material';

import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import { theme } from '../../styles/theme';

import { CreateButton } from '../../components/CreateButton';
import { EditButton } from '../../components/EditButton';
import { CollapseComponent } from '../../components/CollapseComponent';

import { Roles } from '../../models/Roles';
import { AuthContext } from '../../routes/admin';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const Role = () => {

    const context:any = useContext(AuthContext);

    const { id } = useParams();

    const [data, setData] = useState<Roles>();
    const [numberPermissions, setNumberPermissions] = useState<number>();

    useEffect(()=> {

        async function contentUser() {
        
            await axios.get(process.env.REACT_APP_AUTH_SERVICE+'/api/roles/'+id, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    setData(response.data);
                    setNumberPermissions(response.data.permissions.length);
                })
                .catch(function (error) {
                    alert("Error trying loading a role!")
                });

        }

        if(context.user?.accessToken){
            contentUser()
        }

    }, [context.user?.accessToken, id])

    if(!data){
        return(
            <Styled.Container>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >{data?.title}</Item>
                <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/roles/create"><CreateButton type={"roles"} /></Link></Item>
                <Item id="tab-admin-btn" elevation={0} >
                    <Link id="edit-button" to={"/admin/roles/create?id="+id}>
                        <EditButton />
                    </Link>
                </Item>
            </Stack>
            <Paper elevation={3} id="form-admin">
                <Grid container spacing={2}>
                    <Grid item xs id="info-role">
                        <Divider />
                        <Table>
                            <TableBody>
                                <TableRow id="info-content">
                                   <TableCell>
                                       <b>ID</b>
                                   </TableCell>
                                   <TableCell>
                                       {id}
                                   </TableCell>
                                </TableRow>
                                <TableRow id="info-content">
                                   <TableCell>
                                        <b>Created by</b>
                                   </TableCell>
                                   <TableCell>
                                       {data?.created_by}
                                   </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container spacing={2} id="content-role">
                    <Grid item xs>
                        <Divider />
                            <Typography gutterBottom>
                                Description
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                {data?.description}
                            </Typography>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2} id="content-role">
                    <Grid item xs>
                        <Typography gutterBottom >
                            {numberPermissions} assigned permissions
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            <CollapseComponent 
                                title="List"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data.permissions.length>0?data&&data.permissions.map((permission)=>{
                                                    return <ListItem>{`${permission.target}.${permission.resource}.${permission.verb}`}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}                     
                            />
                            {/* <Divider />
                            <CollapseComponent 
                                title="Lands"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data.permissions.land.length>0?data&&data.permissions.land.map((permission)=>{
                                                    return <ListItem>{permission}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />
                            <Divider />
                            <CollapseComponent 
                                title="Farms"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data.permissions.farm.length>0?data&&data.permissions.farm.map((permission)=>{
                                                    return <ListItem>{permission}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />
                            <Divider />
                            <CollapseComponent 
                                title="Owners"
                                defaultState={false}
                                children={  <List
                                                sx={{ width: '100%', fontSize: '15px' }}
                                            >
                                                {data&&data.permissions.owner.length>0?data.permissions.owner.map((permission)=>{
                                                    return <ListItem>{permission}</ListItem>
                                                }):<ListItem>No permissions found</ListItem>}
                                            </List>}
                            />  */}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Styled.Container>
    );

}