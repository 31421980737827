import { SortableElement } from "react-sortable-hoc";

export const SortableItem = SortableElement(({children}:any)=> {

    return(
        <div 
            className="SortableItem"
            style={{fontSize: '15px'}}
        >   
            {children}
        </div>
    )

})