import { AuthTemplate } from "../Auth"
import { Container } from "./styles"

import firebase from "firebase";
import { useState } from "react";

import cdvLogo from "../../assets/logo-cdv-padrao.png";
import { Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Recovery } from "./components/Recovery";
import { Login } from "./components/Login";

export const SignIn = () => {

    const [status, setStatus] = useState({
        notFound: false, 
        recovery: false, 
        registered: false
    })

    const [login, setLogin] = useState({
        email: ""
    });

    const fetchSignInMethodsForEmail = async (email:string) => {

        const options:any = firebase.app().options; 
        const { apiKey } = options; 

        const url = window.location.href;

        axios.post(`https://www.googleapis.com/identitytoolkit/v3/relyingparty/createAuthUri?key=${apiKey}`,{
            identifier: email,
            continueUri: url
        })
        .then(res=>{

            const data = res.data; 
            const { registered } = data; 
            /**Check if the user is registered */
        
            if(!registered){
                /**1. User doesn't exists on the firebase */
                setLogin({
                    email: ""
                });

                setStatus({
                    ...status, 
                    notFound: true
                })

            } else if(
                !data?.allProviders &&
                !data?.signinMethods
            ) {
                /**2. User exists but don't have password setted on the firebase */
                setStatus({
                    ...status, 
                    recovery: true
                })

                
            } else {
                /**3. User has a authentic provider PASSWORD */
                setStatus({
                    ...status, 
                    registered: true
                })
                console.log('show the login and password page!')

            }    
            
        })
        .catch(e => {
            console.log(e);
        })
    }

    const handleBackToLogin = (e:any) => {
        setStatus({
            notFound: false, 
            recovery: false, 
            registered: false
        });
    };

    const handleRecoveryPassword = (e:any) => {
        setStatus({
            notFound: false, 
            recovery: true, 
            registered: false
        });
    }

    const { notFound, registered, recovery } = status; 
    const checkLogin = !notFound && !registered && !recovery;

    return(
        <AuthTemplate>
            <div id="recaptcha-container" />
            <Container>
                <div id="user-container">
                    <img id="cdv-logo" src={cdvLogo} alt="Logo Casa dos Ventos" />
                    {/**Verify the status of email */}
                    {checkLogin &&
                        <>
                            <Formik
                                enableReinitialize={true}
                                initialValues={login}
                                validateOnChange={false}
                                onSubmit={(values, actions) => {
                                    const { email } = values;

                                    if (
                                        email !== undefined
                                    ) {
                                        setLogin({
                                            ...login, 
                                            email
                                        })
                                        fetchSignInMethodsForEmail(email);
                                    }
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                    .email("Invalid Email.")
                                    .required("Required field.")
                                })}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur
                                }) => (
                                    <Form 
                                        noValidate
                                    >
                                        <TextField
                                            autoFocus
                                            required
                                            autoComplete='off'
                                            id="email"
                                            name="email"
                                            label="Email Adress"
                                            variant="standard"
                                            className="txt-field"
                                            onChange={(e:any)=>{
                                                const { value } = e.target; 

                                                setLogin({
                                                    ...login, 
                                                    email: value
                                                })

                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            helperText={
                                                errors.email && touched.email ? errors.email : ""
                                            }
                                            error={errors.email && touched.email ? true : false}
                                        />
                                        <Button
                                            variant="contained"
                                            id='btn-next'
                                            className="btn-login"
                                            type='submit'
                                        >
                                            Next
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                            <div
                                className="forgot-password"
                                onClick={handleRecoveryPassword}
                            >
                                Forgot Password?
                            </div>
                        </>
                    }
                    {notFound &&
                        <>
                            <div
                                id="not-found-title"
                            >
                                User not Found
                            </div>
                            <ErrorOutlineIcon 
                                className="user-not-found-error"
                                sx={{
                                    fontSize: '204px',
                                    padding: '10px'
                                }}
                            />
                            <Button
                                variant="contained"
                                id='btn-next'
                                className="btn-login"
                                onClick={handleBackToLogin}
                            >
                                Back To Login
                            </Button>
                            <p
                                id="not-found-text"
                            >
                                Please contact the system administrator via email accounts.cdvgis@casadosventos.com.br
                            </p>
                        </>
                    }
                    {recovery &&
                        <Recovery
                            defaultEmail={login.email}
                            handleBackToLogin = {handleBackToLogin}
                        />
                    }
                    {registered &&
                        <>
                            <Login
                                defaultEmail={login.email}
                                handleBackToLogin={handleBackToLogin}
                            />
                        </>
                    }
                    <div
                        className="copyright"
                    >
                        Copyright &copy; Casa dos Ventos 
                    </div>
                </div>
            </Container>
        </AuthTemplate>
    )

}