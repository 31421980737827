import { Box } from '@mui/material';
import './styles.css';

export const Unauthorized = () => {

    return (
        <div id="background">
            <div id="loading-container">
                <img src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}/background-images/color.svg`} style={{maxWidth:"250px" ,width:"auto", height:"auto", padding: "20px 30px"}} alt="Logo CdV" />
                <Box
                    sx={{
                        width: '100%',
                        paddingBottom: '20px',
                        fontSize: 15,
                        textAlign: 'center'
                    }}
                >
                    You can't access this session!
                </Box>
            </div>
        </div>
    )

}