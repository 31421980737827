import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import {
    Stack,
    Paper,
    styled,
    Grid,
    CircularProgress,
    Box
} from '@mui/material';

import { theme } from '../../styles/theme';

import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { CreateButton } from '../../components/CreateButton';
import { DeleteButton } from '../../components/DeleteButton';
import { AlertDialog } from '../../components/AlertDialog';
import { Table } from '../../components/Table';
import { AuthContext } from '../../routes/admin';

const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    {
        field: 'name',
        headerName: 'Title',
        width: 250,
        editable: false,
        filterable: false,
        renderCell : (params:any) => (
            <div>
                <a href={`layers/create?id=${params.id}`} title={params.value}>{params.value}</a>
            </div>
        )
    },
    {
        field: 'group',
        headerName: 'Group',
        width: 120,
        editable: false,
    },
    {
        field: 'is_public',
        headerName: 'Public?',
        type: 'boolean',
        width: 120,
        editable: false,
    },
    {
        field: 'is_active',
        headerName: 'Active?',
        type: 'boolean',
        width: 120,
        editable: false,
    },
    {
        field: 'is_filterable',
        headerName: 'Filterable?',
        type: 'boolean',
        width: 120,
        editable: false,
    },
    {
        field: 'filterable_column',
        headerName: 'Column Filterable?',
        type: 'string',
        width: 150,
        editable: false,
        renderCell : (params:any) => (
            <div title={params.value}>
                {params.value}
            </div>
        )
    }
    
];

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const Layers = () => {

    const context:any = useContext(AuthContext);

    const [createGroup, setCreateGroup] = useState(false);
    const [selected, setSelected] = useState([]);
    const [enableDelete, setEnableDelete] = useState(false); 
    const [openDialog, setOpenDialog] = useState(false); 

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const navigate = useNavigate();

    const handleDeleteUsers = async () => {

        /*Action to delete a user here!!! */

        const deleteAction = await axios.delete(process.env.REACT_APP_AUTH_SERVICE+'/api/layers', {
            data: {
                ids: selected
            },
            headers: {
                Authorization: `Bearer ${context.user?.accessToken}`
            }
        })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false; 
        });

        setOpenDialog(false)

        if(deleteAction){
            navigate(0);
        }
    }

    const handleSelected = (data:any) => {
        setSelected(data)
    }

    useEffect(()=> {

      if(selected.length > 0){
          setEnableDelete(true)
      }else{
          setEnableDelete(false)
      }

    }, [selected])

    const handleOpenCreateGroup = () => {
        setCreateGroup (true); 
    }

    const handleCloseCreateGroup = () => {
        setCreateGroup (false); 
    }

    const [views, setViews] = useState();

    useEffect(()=> {
            async function contentRoles() {
                
                const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/layers';

                axios.get(endpoint, {
                        headers: {
                            Authorization: `Bearer ${context.user?.accessToken}`
                        }
                    })
                    .then(function (response) {
                        setViews(response.data)
                    })
                    .catch(function (error) {
                        alert("Error when trying to get views!")
                    });

            }

            if(context.user?.accessToken){
                contentRoles()
            }
            
    }, [context.user?.accessToken])

    if(!views) {
        return(
            <Styled.Container>
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >Layers</Item>
                    <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/layers/create"><CreateButton type={"layers"} /></Link></Item>
                    <Item id="tab-admin-btn" elevation={0} >
                        <DeleteButton 
                            disabled={!enableDelete}
                            onClick={()=>console.log("Deleted")}
                        />
                    </Item>
                </Stack>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }
    
    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >Layers</Item>
                <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/layers/create"><CreateButton type={"layers"} onClick={handleOpenCreateGroup}/></Link></Item>
                <Item id="tab-admin-btn" elevation={0} ><DeleteButton onClick={handleOpenDialog} disabled={!enableDelete}/></Item>
            </Stack>
            <Grid id="username-details-admin" container spacing={4}>
                <Grid item xs={12}>
                    <Table rows={views} columns={columns} onSelectionModelChange={handleSelected}/>
                </Grid>
            </Grid>  
            <AlertDialog 
                open={openDialog}
                onClose={handleCloseDialog}
                onClickDisagree={handleCloseDialog}
                onClickAgree={handleDeleteUsers}
                textAgreeButton="Agree"
                textDisagreeButton="Disagree"
                title="Delete Views Selected?"
                content=" "
            />
        </Styled.Container>
    );

};