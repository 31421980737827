import { useContext, useState, useEffect } from "react";

import { bigFarms, Farms } from "../../models/Farms";

import axios from 'axios';
import { Button, Grid, Typography } from "@mui/material";
import { Table } from "../Table";
import { bigComplex } from "../../models/Complex";
import { bigLayers } from "../../models/Layers";
import { AuthContext } from "../../routes/admin";

const columns = [
    { 
        field: 'id', 
        headerName: 'Site ID', 
        width: 80 
    },
    {
        field: 'aerg1Count',
        headerName: 'Count',
        width: 75,
        editable: false
    },
    {
        field: 'aerg1Cena',
        headerName: 'Scenery',
        width: 80,
        editable: false,
    },
    {
        field: 'aerg1CompName',
        headerName: 'Complex',
        width: 100,
        editable: false,
        renderCell : (params:any) => (
            <span title={params.value}>
                {params.value}
            </span>
        )
    },
    {
        field: 'aerg1Proj',
        headerName: 'Project',
        width: 85,
        editable: false,
        renderCell : (params:any) => (
            <span title={params.value}>
                {params.value}
            </span>
        )
    },
    {
        field: 'aerg1Portfolio',
        headerName: 'Port.',
        type: 'boolean',
        width: 60,
        editable: false
    },
    {
        field: 'stag1Cod',
        headerName: 'Status',
        width: 70,
        editable: false
    }
];

interface ListTransferProps {
    reload?: boolean;
    setReload?: Function; 
}

export const ListTransferAergs = ({
    reload,
    setReload
}:ListTransferProps) => {

    const context:any = useContext(AuthContext);

    const [farms, setFarms] = useState<Array<Farms>>();
    const [customersFarms, setCustomersFarms] = useState<Array<Farms>>();

    const [exportEntities, setExportEntity] = useState<Array<number|undefined>>([]);
    const [deleteEntities, setDeleteEntity] = useState<Array<number|undefined>>([]);

    useEffect(()=> {

        async function contentAergs() {
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/site`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {

                    setFarms(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        async function contentAergsCustomers() {
            axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/site/customers`, {
                    headers: {
                        Authorization: 'Bearer '+context.user?.accessToken
                    }
                })
                .then(function (response) {

                    setCustomersFarms(response.data);

                })
                .catch(function (error) {
                    console.log(error);
                });
        };

        if(context.user?.accessToken!==undefined){
            contentAergs();
            contentAergsCustomers();
        }
            
    }, [context.user?.accessToken, reload, setReload])

    const handleSelectedEntitiesExport = (e:any) => {
        /**Send a request to auth-service to import layers, complex and farms in database of big for customers */
        setExportEntity(e);
    }

    const handleSelectedEntitiesDelete = (e:any) => {
        /**Send a request to auth-service to import layers, complex and farms in database of big for customers */
        setDeleteEntity(e);
    }

    const handleExportEntity = () =>{

        async function exportSites() {

            axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/sites/import`, {
                ids: exportEntities
            },{
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);

                async function contentFarms() {
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/site/customers`, {
                        headers: {
                            Authorization: 'Bearer '+context.user?.accessToken
                        }
                    })
                    .then(function (response) {
    
                        setCustomersFarms(response.data);
                        if(setReload!==undefined){
                            setReload(true);
                        }
    
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }

                contentFarms();
            })
            .catch(()=>alert("Erro exportando!"))

        }
        
        exportSites();
    }

    const deleteExportedEntity = () => {

        async function exportSites() {

            axios.delete(`${process.env.REACT_APP_AUTH_SERVICE}/api/sites/import`, {
                headers: {
                    Authorization: 'Bearer '+context.user?.accessToken
                },
                data: {
                    ids: deleteEntities
                }
            })
            .then(()=>{
                setCustomersFarms(undefined);

                async function contentFarms() {
                    axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/aergs/site/customers`, {
                        headers: {
                            Authorization: 'Bearer '+context.user?.accessToken
                        }
                    })
                    .then(function (response) {
                        
                        setCustomersFarms(response.data);
                        if(setReload!==undefined){
                            setReload(true);
                        }
    
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }

                contentFarms();
            })
            .catch(()=>alert("Erro exportando!"))

        }
        
        exportSites();
    }


    const customersEditedSiteIds = customersFarms?.map((farm:any) => {
        
        const siteId = {
            id: farm.aerg1SiteId,
            ...farm
        }

        return (siteId)
    }); 

    const customersSiteIds = customersEditedSiteIds?.map((site)=> site.id); 

    const editedSiteIds = farms?.map((farm:any) => {
        
        const siteId = {
            id: farm.aerg1SiteId,
            ...farm
        }

        return (siteId)
    })


    const filteredBigFarms = editedSiteIds?.filter((farm)=>!customersSiteIds?.includes(farm.id));

    return (

            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{padding:"20px"}}>
                <Grid item xs={12} sm={12} md={5}>  
                    <Typography><b>BIG</b></Typography>
                    {/* {filteredBigFarms!==undefined? */}
                        <Table
                            columns={columns} 
                            rows={filteredBigFarms}
                            onSelectionModelChange={handleSelectedEntitiesExport}
                            loading={filteredBigFarms===undefined}
                        />
                        {/* :
                        <CircularProgress color="inherit"/>
                    } */}
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            aria-label="move selected right"
                            onClick={handleExportEntity}
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={deleteExportedEntity}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <Typography><b>Customers</b></Typography>
                        <Table
                            columns={columns} 
                            rows={customersEditedSiteIds}
                            onSelectionModelChange={handleSelectedEntitiesDelete}
                            loading={customersEditedSiteIds===undefined}
                        />
                </Grid>
            </Grid>

    );

}