import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {   
    Box,
    Grid,
    Paper,
    Typography,
    CircularProgress
} from '@mui/material';

import { Navigate } from 'react-router-dom';

import axios from 'axios';

import { StepsController } from '../StepsController';
import { InfoSelectedUserSession } from '../../models/Users';
import { CollapseItem } from '../CollapseItem';

import { Roles } from '../../models/Roles';
import { Views } from '../../models/Views';
import { AuthContext } from '../../routes/admin';

interface UserRegistrationStepTwoProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info: InfoSelectedUserSession;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    edit: boolean;
    id: string | null;
}

export const UserRegistrationStepTwo = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    edit,
    id
}:UserRegistrationStepTwoProps) => {

    const context:any = useContext(AuthContext);

    const [ tempValue, setTempValue ] = useState(info);
    const [ enableNext, setEnableNext ] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);

    async function sendRequestCreate (){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/users';

        if(!sentRequest){
            setSentRequest(true);
            axios.post(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a user!")
                    setSentRequest(false)
                })
            ;
        }
    }

    async function sendRequestUpdate(){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/users/'+id;

        if(!sentRequest){
            setSentRequest(true);

            axios.put(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a user!")
                    setSentRequest(false)
                });
        }
    }

    /*Loading roles and views */
    const [roles, setRoles] = useState<Array<Roles>>();
    const [views, setViews] = useState<Array<Views>>();

    useEffect(() => {
        
        const roles = tempValue.roles.map((roleId)=>{

            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles/'+roleId;

            return axios.get(endpoint, {
                    params: {
                        ID: 12345
                    },
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then((value)=>{return value.data})
                .catch(function (error) {
                    console.log(error);
                });
            })
        
            const views = tempValue.views.map((viewId)=>{

                const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/views/'+viewId;
    
                return axios.get(endpoint, {
                        params: {
                            ID: 12345
                        },
                        headers: {
                            Authorization: `Bearer ${context.user?.accessToken}`
                        }
                    })
                    .then((value)=>{return value.data})
                    .catch(function (error) {
                        console.log(error);
                    });
                })

        Promise.all(roles).then((role) => {
            setRoles(role);
        });

        Promise.all(views).then((view) => {
            setViews(view);
        });

    }, [])

    return (
        <Styled.Container>
            <Box
                component="form"
                autoComplete="off"
                noValidate
            >
                <div id='title-create-group'>Review your created user</div>
                <Paper elevation={3} id="form-admin">
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom component="div">
                                <b>General Info</b>
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Name: {tempValue.name}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Country: {tempValue?.country?.label}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Is Admin? {tempValue.isAdmin?"Yes": "No"}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Email: {tempValue.email}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Phone: {tempValue.phone}
                            </Typography>
                            <Typography gutterBottom component="div">
                                <b>Roles</b>
                            </Typography>
                            {roles?
                                roles.map((role)=>{
                                    return <CollapseItem 
                                                id={role.id}
                                                title={role.title}
                                                description={role.description}
                                            />
                                })
                                :
                                <CircularProgress color="inherit"/>
                            }
                            <Typography gutterBottom component="div">
                                <b>Views</b>
                            </Typography>
                            {views?
                                views.map((view)=>{
                                    return <CollapseItem 
                                                title={view.title}
                                                description={view.description}
                                                id={view.id}
                                            />
                                })
                                :
                                <CircularProgress color="inherit"/>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Box> 
            <StepsController 
                edit={edit}
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                enableCreate={!redirect}
                tempValue={tempValue}
                sendRequestCreate={edit?sendRequestUpdate:sendRequestCreate}
            />
            {redirect?
                <Navigate to={hrefOrig}/>
                :
                ""
            }
        </Styled.Container>
    );

}