import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #tab-admin-title {
            background: ${theme.colors.mainBg}!important;
            font-size: ${theme.fonts.sizes.small};
        }

        #top-tab-admin{
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 0px;
            width: 400px; 
        }
    `}
`;
