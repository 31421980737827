import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
    `}

    max-height: 48px;
    /* overflow: hidden; */
    display: flex;
    justify-content: center;

    img {
        display: block; 
        /* margin: -20% -10%; */
        width: 100%;
        height: 32px;
    }
`;
