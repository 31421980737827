export const glepColors = [
    '#cccccc',
	'#d0e3ff',
	'#bbddff',
	'#4c8bca',
	'#2f5092',
	'#ffe699',
	'#c65911',
	'#338255',
	'#9c9ca6'
]
