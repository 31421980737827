
export class LeafletGroupItem {

    private map:any; 
    private groupId:string|null;

    constructor(map:any, groupId:string|null){
        this.map = map;
        this.groupId = groupId;
    }

    public getGroupState(){

        const groupId:any = this.groupId;

        const activeLayers = this.map._state.layers; 

        const activeLayersGroup = this.map._state.groupedLayersOrder[groupId].filter((layer:any)=>activeLayers.indexOf(layer.txt_code)!==-1)

        return this.map._state.groupOpened[groupId] || activeLayersGroup.length > 0;

    }

    public setGroupState(state:boolean){

        const groupId:any = this.groupId;

        this.map._state.groupOpened[groupId] = state;

    }

    public getGroupSize(){
        const groupId:any = this.groupId;

        return this.map._state.groupedLayersOrder[groupId].length; 
    }

}