import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.fonts.sizes.xsmall};
        #policies {
            color: ${theme.colors.secondary};
            padding-top: 8px;
        }
        padding: 20px;
    `}
`;