import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.fonts.sizes.small};
        #tab-admin-title, #tab-admin-btn{
            font-size: ${theme.fonts.sizes.small};
            background: transparent; 
        }
        #top-tab-admin{
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 0px;
            width: 400px; 
        }

        #username-details-admin{
            padding: 50px; 
        }
    `}
`;
