import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Grid,
    Stack,
    styled,
    Paper,
    CircularProgress,
    Box
} from '@mui/material';

import { theme } from '../../styles/theme';

import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';

import { CreateButton } from '../../components/CreateButton';
import { DeleteButton } from '../../components/DeleteButton';
import { AlertDialog } from '../../components/AlertDialog';
import { Table } from '../../components/Table';
import { AuthContext } from '../../routes/admin';

const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    {
      field: 'title',
      headerName: 'Title',
      width: 180,
      editable: false,
      renderCell : (params:any) => (
        <div>
          <a href={`roles/${params.id}`}>{params.value}</a>
        </div>
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      editable: false,
    }
];

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const Roles = () => {

    const context:any = useContext(AuthContext);

    const [createGroup, setCreateGroup] = useState(false);
    const [selected, setSelected] = useState([]);
    const [enableDelete, setEnableDelete] = useState(false); 
    const [openDialog, setOpenDialog] = useState(false); 

    const [roles, setRoles] = useState();

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const navigate = useNavigate()
    
    const handleDeleteRoles = async () => {

        /*Action to delete a user here!!! */

        const deleteAction = await axios.delete(process.env.REACT_APP_AUTH_SERVICE+'/api/roles', {
            data: {
                ids: selected
            },
            headers: {
                Authorization: `Bearer ${context.user?.accessToken}`
            }
        })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false; 
        });

        setOpenDialog(false)

        if(deleteAction){
            navigate(0)
        }

    }

    const handleSelected = (data:any) => {
        setSelected(data)
    }

    useEffect(()=> {

      if(selected.length > 0){
          setEnableDelete(true)
      }else{
          setEnableDelete(false)
      }

    }, [selected])

    const handleOpenCreateGroup = () => {
        setCreateGroup (true); 
    }

    useEffect(()=> {
        async function contentRoles() {
            
            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles';

            axios.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    setRoles(response.data)
                })
                .catch(function (error) {
                    alert("Error when trying to get roles!")
                });

        }

        if(context.user?.accessToken){
            contentRoles();
        };
        
    }, [context.user?.accessToken])

    if(!roles) {
        return(
            <Styled.Container>
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >Roles</Item>
                    <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/roles/create"><CreateButton type={"roles"} /></Link></Item>
                    <Item id="tab-admin-btn" elevation={0} >
                        <DeleteButton 
                            onClick={()=>console.log("Delete")}
                            disabled={!enableDelete}
                        />
                    </Item>
                </Stack>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }
    
    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >Roles</Item>
                <Item id="tab-admin-btn" elevation={0} ><Link to="/admin/roles/create"><CreateButton type={"roles"} onClick={handleOpenCreateGroup}/></Link></Item>
                <Item id="tab-admin-btn" elevation={0} ><DeleteButton onClick={handleOpenDialog} disabled={!enableDelete}/></Item>
            </Stack>
            <Grid id="username-details-admin" container spacing={4}>
                <Grid item xs={12}>
                    <Table rows={roles} columns={columns} onSelectionModelChange={handleSelected}/>
                </Grid>
            </Grid>  
            <AlertDialog 
                open={openDialog}
                onClose={handleCloseDialog}
                onClickDisagree={handleCloseDialog}
                onClickAgree={handleDeleteRoles}
                textAgreeButton="Agree"
                textDisagreeButton="Disagree"
                title="Delete Roles Selected?"
                content=" "
            />
        </Styled.Container>
    );

}