import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #tab-admin-title, #tab-admin-btn{
            font-size: ${theme.fonts.sizes.small};
            background: transparent; 
        }
        #top-tab-admin{
            margin-top: 20px;
            margin-left: 20px;
            margin-bottom: 0px;
            width: 500px; 
        }

        #username-details-admin{
            padding: 50px; 
        }

        #form-admin {
            font-size: ${theme.fonts.sizes.small};
            margin: 20px; 
            margin-top: 50px;
            padding: 20px; 
        }

        #info-role{
            max-width: 300px;
        }

        #info-content > td{
            padding: 5px !important;
        }

        #content-role{
            padding: 50px 0 0 0; 
        }
    `}
`;