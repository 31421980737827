import { useState } from 'react';
import * as Styled from './styles';

import {
    Box,
    Button,
    ButtonProps
} from '@mui/material';

import { 
    Link, 
    Navigate 
} from 'react-router-dom';

import { InfoSelectedViews } from '../../models/Views';
import { InfoSelectedRoles } from '../../models/Roles';
import { InfoSelectedUserSession } from '../../models/Users';

interface StepsControllerProps{
    activeStep: number;
    hrefOrig: string;
    info?: InfoSelectedUserSession | InfoSelectedViews | InfoSelectedRoles;
    steps: Array<string>;
    skipped: Set<any>;
    setInfo: Function;
    setActiveStep: Function;
    setSkipped: Function; 
    enableNext: boolean;
    tempValue?: InfoSelectedUserSession | InfoSelectedViews | InfoSelectedRoles;
    sendRequestCreate?: ButtonProps["onClick"];
    enableCreate?: boolean;
    edit?: boolean;
}

export const StepsController = ({
    activeStep,
    hrefOrig,
    info,
    steps,
    skipped,
    setInfo,
    setActiveStep,
    setSkipped,
    enableNext,
    tempValue,
    sendRequestCreate,
    enableCreate,
    edit
}:StepsControllerProps) => {

    const [error, setError] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const isStepSkipped = (step:number) => {
        return skipped.has(step);
    };

    const handleNext = () => {

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setInfo({...info, ...tempValue})

        setActiveStep((prevActiveStep:any) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep:any) => prevActiveStep - 1);
    };

    return (
        <Styled.Container> 
            {error?
                <Box sx={{display: 'flex', height:500, justifyContent: 'center', alignItems: 'center'}}>
                    Error creating a user!
                </Box>
                :
                ""
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button >
                    <Link to={hrefOrig}>
                        Cancel
                    </Link>
                </Button>
                {activeStep === steps.length - 1 ? 
                    <Button disabled={!enableCreate} onClick={sendRequestCreate}>
                        {edit?"Edit":"Create"}
                    </Button>
                    : 
                    <Button disabled={!enableNext} onClick={handleNext}>
                        Next
                    </Button>
                }
            </Box>
            {redirect?
                <Navigate to={hrefOrig}/>
                :
                ""
            }
        </Styled.Container>
    );
}