import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import {
    Divider,
    Grid,
    CircularProgress,
    Box
} from '@mui/material';

import { theme } from '../../styles/theme';

import { useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Username } from '../../components/Username';
import { LoginActivity } from '../../components/LoginActivity';
import { PrivacyPolicy } from '../../components/PrivacyPolicy';
import { TableContainerPaper } from '../../components/TableContainerPaper';
import { AlertDialog } from '../../components/AlertDialog';
import { MultipleSelectChip } from '../../components/MultipleSelectChip';
import { Roles } from '../../models/Roles';
import { Views } from '../../models/Views';
import { AuthContext } from '../../routes/admin';

export const User = () => {

    const context:any = useContext(AuthContext);
    
    let { user } = useParams();

    const [data, setData] = useState<any>();

    /* Dialog Roles Controller */
    const [openDialog, setOpenDialog] = useState(false); 
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [roles, setRoles] = useState();

    useEffect(()=> {

        async function contentUser() {
        
            await axios.get(process.env.REACT_APP_AUTH_SERVICE+'/api/users/'+user, {
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {

                    const { email, country, is_admin, name, phone, roles, views, ds_envelopeId, last_access, first_login} = response.data; 

                    setData({
                        country, 
                        is_admin, 
                        name, 
                        phone, 
                        roles,
                        views,
                        email,
                        ds_envelopeId,
                        last_access,
                        first_login
                    })

                    setSelectedRoles(roles.map((role:Roles)=>role.id));
                    setSelectedViews(views.map((view:Views)=>view.id));

                    return response.data;
                })
                .catch(function (error) {
                    alert("Error trying loading a user!")
                });
        }

        if(context.user?.accessToken){
            contentUser()
        }

    }, [context.user?.accessToken, user])


    const handleTextValue = (e:any) => {
        setSelectedRoles(e.target.value)

    }

    const handleOpenDialogRoles = async () => {
        setOpenDialog(true)

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles';
        await axios.get(endpoint, {
                 params: {
                    ID: 12345
                 },
                 headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                 }
            })
            .then(function (response) {
                setRoles(response.data);
            })
            .catch(function (error) {
                setOpenDialog(false);
                alert("Error trying to load roles!");
            });
    }

    const handleCloseDialogRoles = () => {
        setOpenDialog(false)
    }

    const navigate = useNavigate();
    const handleAddRoles = () => {

        async function sendRequestUpdate(){

            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/users/'+user;
    
            if(data){
                axios.put(endpoint, {
                            name: data.name, 
                            phone: data.phone, 
                            roles: selectedRoles, 
                            isAdmin: data.is_admin,
                            views: selectedViews,
                            country: data.country
                        },
                        {
                        headers: {
                            Authorization: `Bearer ${context.user?.accessToken}`
                        }
                    })
                    .then(function (response) {
                        // history.go(0)
                    })
                    .catch(function (error) {
                        alert("Error trying to insert a user!")
                    });
            }
        }

        sendRequestUpdate();

        setOpenDialog(false)

        navigate(0)
    }

    /* Dialog Views Controller */

    const [openDialogViews, setOpenDialogViews] = useState(false); 
    const [selectedViews, setSelectedViews] = useState([]);
    const [views, setViews] = useState();

    const handleTextValueViews = (e:any) => {
        setSelectedViews(e.target.value)
    }

    const handleOpenDialogViews = () => {
        setOpenDialogViews(true)

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/views';
        axios.get(endpoint, {
                 headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                 }
            })
            .then(function (response) {
                setViews(response.data);
            })
            .catch(function (error) {
                setOpenDialogViews(false);
                alert("Error trying to load roles!");
            });
    }

    const handleCloseDialogViews = () => {
        setOpenDialogViews(false)
    }

    const handleAddViews = () => {

        async function sendRequestUpdate(){

            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/users/'+user;
    
            if(data){
                axios.put(endpoint, {
                            name: data.name, 
                            phone: data.phone, 
                            isAdmin: data.is_admin,
                            roles: selectedRoles, 
                            views: selectedViews,
                            country: data.country
                        },
                        {
                        headers: {
                            Authorization: `Bearer ${context.user?.accessToken}`
                        }
                    })
                    .then(function (response) {
                        // history.go(0)
                    })
                    .catch(function (error) {
                        alert("Error trying to insert a user!")
                    });
            }
        }

        sendRequestUpdate();

        setOpenDialogViews(false)

        navigate(0)
    }


    // console.log(roles)

    if(!data){
        return(
            <Styled.Container>
                <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', color: theme.colors.secondary }}>
                    <CircularProgress color="inherit"/>
                </Box>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Username 
                username={data.name}
                // company={data?.company}
                email={data.email}
                phone={data?.phone}
                url={data?.picture}
                isAdmin={data?.is_admin}
                country={data?.country}
                id={user}
                onClickAddRole = {handleOpenDialogRoles}
                onClickAddView = {handleOpenDialogViews}
            />
            <Divider />
            <Grid id="username-details-admin" container spacing={4}>
                <Grid item xs={12} md={6}>
                    <TableContainerPaper 
                        target="roles"
                        info={data?.roles}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainerPaper 
                        target="views"
                        info={data?.views}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LoginActivity 
                        firstLoginDate={data.first_login}
                        lastAccessDate={data.last_access}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PrivacyPolicy
                        ds_envelopeId={data.ds_envelopeId}
                    />
                </Grid>
            </Grid>
            <AlertDialog 
                open={openDialog}
                onClose={handleCloseDialogRoles}
                onClickDisagree={handleCloseDialogRoles}
                onClickAgree={handleAddRoles}
                textAgreeButton="Agree"
                textDisagreeButton="Disagree"
                title="Add Roles"
                disableAgreeButton={selectedRoles.length<1}
                content={roles?<MultipleSelectChip 
                                    title="Roles"
                                    name="roles" 
                                    options={roles}
                                    onChange={handleTextValue} 
                                    value={selectedRoles}
                                    error={selectedRoles.length<1}
                                />
                                :
                                <CircularProgress color="inherit"/>
                        }
            />
            <AlertDialog 
                open={openDialogViews}
                onClose={handleCloseDialogViews}
                onClickDisagree={handleCloseDialogViews}
                onClickAgree={handleAddViews}
                textAgreeButton="Agree"
                textDisagreeButton="Disagree"
                title="Add Views"
                disableAgreeButton={selectedViews.length<1}
                content={views?<MultipleSelectChip 
                                    title="Views"
                                    name="views" 
                                    options={views}
                                    onChange={handleTextValueViews} 
                                    value={selectedViews}
                                    error={selectedViews.length<1}
                                />
                                :
                                <CircularProgress color="inherit"/>
                        }
            />
        </Styled.Container>
    );
};