import * as Styled from './styles';

import {
    Box
} from '@mui/material';
import { InfoSelectedViews } from '../../models/Views';
import { ViewRegistrationStepTwo } from '../ViewRegistrationStepTwo';
import { ViewRegistrationStepOne } from '../ViewRegistrationStepOne';
import { ViewRegistrationStepThree } from '../ViewRegistrationStepThree';

interface ViewRegistrationProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info?: InfoSelectedViews;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    edit: boolean;
    id: string|null;
};

export const ViewRegistration = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    edit,
    id
}:ViewRegistrationProps) => {

    const Step = () => {

        let step; 
        switch(activeStep) {
            case 0:
                step = <ViewRegistrationStepOne 
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break; 
            case 1:
                step = <ViewRegistrationStepTwo
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break; 
            case 2:
                step = <ViewRegistrationStepThree
                            id={id}
                            edit={edit}
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break;   
            default:
                step = <h1>Not found!</h1>
        }

        return step; 
    }

    return (
        <Styled.Container>
            <Box>
                <Step />
            </Box>
        </Styled.Container>
    );

}

