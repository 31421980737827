import * as Styled from './styles';

import {
    Box
} from '@mui/material';

import { InfoSelectedUserSession } from '../../models/Users';
import { UserRegistrationStepOne } from '../UserRegistrationStepOne';
import { UserRegistrationStepTwo } from '../UserRegistrationStepTwo';

interface UserRegistrationProps {
    steps: Array<string>;
    hrefOrig: string; 
    activeStep: number; 
    setActiveStep: Function;
    info: InfoSelectedUserSession;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    edit: boolean;
    id: string|null;
}

export const UserRegistration = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    edit,
    id
}:UserRegistrationProps)=>{
    
    const Step = () => {
        
        let step; 
        switch(activeStep) {
            case 0: 
                step = <UserRegistrationStepOne 
                            edit={edit}
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break; 
            case 1:
                step = <UserRegistrationStepTwo 
                            id={id}
                            edit={edit}
                            steps={steps}
                            hrefOrig={hrefOrig}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            info={info}
                            setInfo={setInfo}
                            skipped={skipped}
                            setSkipped={setSkipped}
                        />
                break;
            default:
                step = <h1>Not found!</h1>
        }

        return step; 
    }
    
    return (
        <Styled.Container>
            <Box>
                <Step />
            </Box>
        </Styled.Container>
    );

}