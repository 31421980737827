import { DefaultTheme } from "styled-components"

export const theme:DefaultTheme = {
    colors: {
        mainBg: '#EDEDED',
        primary: '#FAFAFA',
        secondary: '#088BAE',
        secondaryRgba: 'rgba(8, 139, 174, 0.2)',
        blue: '#015D90',
        dark: '#2C2F30',
        gray: '#6A7580',
        grayBg: '#C4C4C4',
        notFavorite: '#EFEFEF',
        drawerBg: "#0098D6",
        darkBlue: "#0D5E8D",
        yellow: "#F2C02B",
        darkYellow: "#F39D36",
        pink: "#F4837D",
        darkPink: "#F4837D",
        purple: "#AC70C6",
        darkPurple: "#AC70C6",
        green: "#3EA036",
    },
    fonts: {
        family: {
            default: "'Montserrat', sans-serif"
        },
        sizes: {
            xsmall: '0.8rem',
            small: '1.6rem',
            medium: '2.4rem',
            large: '3.2rem',
            xlarge: '4.0rem',
            xxlarge: '4.8rem',
            huge: '5.6rem',
            xhuge: '6.4rem',
        },
    },
    svg: {
        scales: {
            medium: 1.8,
        }
    },
    spacings: {
        xsmall: '8rem',
        small: '1.6rem',
        medium: '2.4rem',
        large: '3.2rem',
        xlarge: '4.0rem',
        xxlarge: '4.8rem',
        huge: '5.6rem',
        xhuge: '6.4rem',
    },
    media: {
        lteMedium: `(max-width: 768px)`,
    },
    sizes: {
        sidebar: '266px'
    },
    screenSizes: {
        for_phone_only: '599px',
        for_tablet_portrait_up: '600px',
        for_tablet_landscape_up: '900px',
        for_desktop_up: '1200px',
        for_big_desktop_up: '1800px'
    }
}