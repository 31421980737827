import { Button, LinearProgress, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import SwaggerUi from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useAuthListener } from "../../hooks";
import { TokenDiv } from "./styles";

export const SwaggerImages = () => {

    const { user } = useAuthListener();

    const [swagger, setSwagger] = useState();
    const [token, setToken] = useState<any>('');

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${user?.accessToken}`
        }

        axios.get(`${process.env.REACT_APP_PROXY_GEOSERVER?.replace("{s}", "1")}/api-docs/json`, { headers })
            .then((response) => {
                setSwagger(response.data);
                console.log(response.data);
            })

    }, []);

    function copyToken() {
        navigator.clipboard.writeText(token);
    }

    if(!swagger){
        return <LinearProgress />
    }

    return (
        <>
            <TokenDiv>
                <Tooltip title="Copy to Clipboard">
                    <Button id='btnToken'
                        onClick={() => {
                            setToken(user?.accessToken)
                            copyToken()
                        }}
                    >
                        Copy Token 
                    </Button>
                </Tooltip>

                {token !== '' ?
                    <TextField
                        id='myInput'
                        multiline
                        fullWidth={true}
                        value={token}
                        label={'Token'}
                    />
                    :
                    null
                }
            </TokenDiv>
            <SwaggerUi spec={swagger} />
        </>
    )
}