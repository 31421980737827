import styled, { css } from 'styled-components';

export const Map = styled.div`
    ${({ theme }) => css`
        @media (min-width:0px) and (orientation: landscape){
            height: calc(100vh - 48px - 25px);
        };
        
        @media (min-width:600px){
            height: calc(100vh - 64px - 25px);
        }

        height: calc(100vh - 56px - 25px); 
        width: calc(100% - 25px);

        .leaflet-pane .leaflet-popup-pane{
            z-index: 1200; 
        }
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`

        flex-flow: row;
        display: flex;

        button {
            margin: 3px;
            background: ${theme.colors.primary};
            border-radius: 2px;
            padding: 0px;
        }

        button:hover {
            background: ${theme.colors.grayBg}
        }

    `}
`;

