import 'leaflet-draw/dist/leaflet.draw-src';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import { LeafletTransformations } from './leaflet-transformations';

declare const L: any;

export class CircleRuler {

    private map:any; 
    private leafletTransformations: LeafletTransformations;

    constructor(map:any){
        this.map = map; 
        this.config();
        this.leafletTransformations = new LeafletTransformations();
    }

    private config (){

        this.map.createPane('reguacirculo');
        const reguaCirculoPane = this.map.getPane('reguacirculo');
        reguaCirculoPane.style.zIndex = '1001';

        this.map.createPane('ttreguacirculo');
        const ttReguaCirculoPane = this.map.getPane('ttreguacirculo');
        ttReguaCirculoPane.style.zIndex = '1002';

        this.map.newLayer = new L.featureGroup([],{pane:'reguacirculo'});
        this.map.addLayer (this.map.newLayer);
        this.map.circleRuler = false;
    }

    public toggleCircleRuler(){

        const map:any = this.map; 
        const leafletTransformations = this.leafletTransformations;

        map.circleRuler = !map.circleRuler;

        if(map.circleRuler){
            map.bloqInfo=true;
            if(typeof map.reguacirculo == 'undefined') {
                map.newLayer.bringToFront();
                map.newLayer.setZIndex(998);

                map.reguacirculo = new L.Draw.Circle(map, {featureGroup:map.newLayer, pane:'reguacirculo'}); 
                map.reguacirculo.setOptions({pane:'reguacirculo'});

                const shapeOptions = {
                    clickable: true,
                    color: "blue",
                    dashArray: "3,6",
                    fill: true,
                    fillColor: null,
                    fillOpacity: 0.2,
                    opacity: 1,
                    stroke: true,
                    weight: 3,
                    pane:'reguacirculo'
                };
                map.reguacirculo.setOptions({pane:'reguacirculo',shapeOptions:shapeOptions, showArea: true});

                map.on(L.Draw.Event.DRAWVERTEX,function(){
                    debugger;
                });

                map.on(L.Draw.Event.CREATED, function (event:any) {

                    var llc=event.layer.getLatLng();
                    var options:any={};
                    for (var k in event.layer.options) options[k]=event.layer.options[k];
                    options.pane='reguacirculo';
                    map.newcirclerule=L.circle(llc, options);
                    
                    map.reguacirculo.disable();
                    map.newLayer.addLayer(map.newcirclerule);
                    var cbb=map.newcirclerule.getBounds();
                    var centroOptions={
                        icon:L.divIcon(),
                        draggable:true,
                        pane:'reguacirculo',
                        zIndexOffset:999
                    }

                    map.newcirclerule.centro=L.marker(llc, centroOptions);
                    map.newcirclerule.centro.addTo(map);
                    var raioOptions={
                        icon:L.divIcon(),
                        draggable:true,
                        pane:'reguacirculo',
                        zIndexOffset:999
                    };
                    var lng=cbb.getEast();
                    var lat=llc.lat;
                    map.newcirclerule.raio=L.marker([lat, lng], raioOptions);
                    
                    var cont=map.newcirclerule.centro.getLatLng().toString()+'<br/><b>Raio:</b>'
                        cont+=leafletTransformations.formatDistance(map.newcirclerule.getRadius());
                    var r=map.newcirclerule.getRadius();
                        cont='Lat/lng: '+map.newcirclerule.centro.getLatLng().lat.toFixed(6)+'/'+map.newcirclerule.centro.getLatLng().lng.toFixed(6);
                        cont+='<br/><b>Radius:</b>'+leafletTransformations.formatDistance(r);
                        cont+='<br/><b>Area:</b>'+leafletTransformations.formatArea(Math.PI*Math.pow(r,2));
                    map.newcirclerule.raio.bindTooltip(cont,{pane:'ttreguacirculo',permanent:true,direction:'top',offset:[0,-6]}).openTooltip();

                    map.newcirclerule.centro.on('dragstart', function(){
                        //toggleTooltips(false);
                        // map.newcirclerule.raio.bindTooltip(cont,{pane:'ttreguacirculo',permanent:true,direction:'top',offset:[0,-6]}).openTooltip();
                    });
                    map.newcirclerule.centro.on('dragend', function(){
                        //toggleTooltips(map.reguacirculo.origtton);
                    });
                    map.newcirclerule.centro.on('drag', function(ev:any){
                        map.newcirclerule.setLatLng(ev.latlng);
                        var cbb=map.newcirclerule.getBounds();
                        var lng=cbb.getEast();
                        var lat=ev.latlng.lat;
                        map.newcirclerule.raio.setLatLng([lat,lng]);
                        var r=map.newcirclerule.getRadius();
                        var cont='Lat/lng: '+map.newcirclerule.centro.getLatLng().lat.toFixed(6)+'/'+map.newcirclerule.centro.getLatLng().lng.toFixed(6);
                            cont+='<br/><b>Radius:</b>'+leafletTransformations.formatDistance(r);
                            cont+='<br/><b>Area:</b>'+leafletTransformations.formatArea(Math.PI*Math.pow(r,2));
                        map.newcirclerule.raio.setTooltipContent(cont);
                    });
                    map.newcirclerule.raio.on('dragstart', function(){
                        // toggleTooltips(false);
                    });
                    map.newcirclerule.raio.on('dragend', function(){
                        // toggleTooltips(map.reguacirculo.origtton);
                    });
                    map.newcirclerule.raio.on('drag', function(ev:any){
                        // toggleTooltips(false);
                        var raio=ev.latlng.distanceTo(map.newcirclerule.getLatLng());
                        map.newcirclerule.setRadius(raio);
                        var cont=map.newcirclerule.centro.getLatLng().toString()
                            cont+= '<br/><b>Radius:</b>'+leafletTransformations.formatDistance(map.newcirclerule.getRadius());
                        var r=map.newcirclerule.getRadius();
                        cont = 'Lat/lng: '+map.newcirclerule.centro.getLatLng().lat.toFixed(6)+'/'+map.newcirclerule.centro.getLatLng().lng.toFixed(6);
                        cont+='<br/><b>Radius:</b>'+leafletTransformations.formatDistance(r);
                        cont+='<br/><b>Area:</b>'+leafletTransformations.formatArea(Math.PI*Math.pow(r,2));
                        map.newcirclerule.raio.setTooltipContent(cont);
                    });
                    map.newcirclerule.raio.addTo(map);
                    // if (map.reguacirculo.origtton==true) toggleTooltips(true);
                })
            }
            map.reguacirculo.origtton=map.tton;
            // if (map.tton==true) toggleTooltips(false);
            map.reguacirculo.enable();
        }else{
            // if (map.reguacirculo.origtton==true) toggleTooltips(true);
            if (map.newcirclerule){
                if (map.hasLayer(map.newcirclerule)) map.removeLayer(map.newcirclerule);
                if (map.newcirclerule.centro){
                    map.newcirclerule.centro.off('drag');
                    if (map.hasLayer(map.newcirclerule.centro)) map.removeLayer(map.newcirclerule.centro);
                } 
                if (map.newcirclerule.raio){
                    map.newcirclerule.raio.off('drag');
                    map.newcirclerule.raio.unbindTooltip();
                    if (map.hasLayer(map.newcirclerule.raio)) map.removeLayer(map.newcirclerule.raio);
                } 
            }
            map.bloqInfo=false;
            delete(map.newcirclerule);
            //map.off(L.Draw.Event.CREATED)
            map.reguacirculo.disable();
            //delete(map.reguacirculo);
            map.circleRuler=false;
        }
    }

}