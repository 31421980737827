import { Container } from "./styles";

export const AuthTemplate: React.FC = ({ children }) => {
  return (
    <>
      <Container>
        <div id="background">
          {/* <div id="user-container"> */}
          {/* Generic content */}
          {children}
          {/* </div> */}
        </div>
      </Container>
    </>
  );
};
