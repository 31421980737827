import * as Styled from './styles';

import {
    Box,
    Grid,
    Typography,
    Paper,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButtonProps
} from '@mui/material';

import {
    EmailOutlined,
    LocalPhoneOutlined,
    LocationOnOutlined,
    AddBoxOutlined,
    LibraryAddOutlined,
    AccountCircle
} from '@mui/icons-material';

import { Link } from 'react-router-dom';

import { theme } from '../../styles/theme';

import { EditProfile } from '../EditProfile';

import { ApiCountry } from '../../models/Country';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

interface UsernameProps {
    url?: string;
    username: string,
    company?: string; 
    email: string;
    phone: string;
    country?:ApiCountry; 
    id?: string;
    isAdmin?: boolean; 
    onClickAddRole: IconButtonProps["onClick"];
    onClickAddView: IconButtonProps["onClick"];
}

export const Username = ({
    url,
    username,
    company,
    email,
    phone,
    id,
    isAdmin,
    country,
    onClickAddRole,
    onClickAddView
}:UsernameProps) => {

    return (
        <Styled.Container>
            <Box component="div" sx={{ padding:'30px', flexGrow: 1, borderRadius: '15px', background: theme.colors.blue }}>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Grid item>
                        <Paper id="photo" elevation={0}>
                            {url?<img src={url} alt="" style={{borderRadius:'50%', height:'140px'}}/>:<AccountCircle />}<br/>
                        </Paper>
                    </Grid>
                    <div id="spacer" style={{padding: "15px"}}></div>
                    <Grid item>
                        <Typography sx={{ cursor: 'pointer' }} id="edit-profile-button">
                            <Link id="edit-button" to={"/admin/users/create?id="+id}>
                                <EditProfile />
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12} id="info">
                    <Grid item xs>
                        <Typography gutterBottom variant="subtitle1" component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                           {username} {isAdmin && <AdminPanelSettingsIcon/>}
                        </Typography>
                        <Typography variant="body2" gutterBottom component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                            {company? company: "No companies"}
                        </Typography>
                        <Typography variant="body2" component="div">
                            Organization Unit
                        </Typography>
                        <Typography variant="body2" component="div">
                            {email?.split('@')[1]?email.split('@')[1]: "No domain"}
                        </Typography>
                        <div id="spacer" style={{padding: "8px"}}></div>
                        <Typography variant="body2" component="div">
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={onClickAddRole}
                                color="inherit"
                            >
                                <AddBoxOutlined />
                                <Typography variant="body2" align="center">
                                    &nbsp; Add Role
                                </Typography>
                            </IconButton>
                            {/* <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                // onClick={handleClick}
                                color="inherit"
                            >
                                <GroupAddOutlinedIcon />
                                <Typography variant="body2" align="center">
                                    &nbsp; Add a Group
                                </Typography>
                            </IconButton> */}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={onClickAddView}
                                color="inherit"
                            >
                                <LibraryAddOutlined />
                                <Typography variant="body2" align="center">
                                    &nbsp; Add View
                                </Typography>
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Paper id="user-details" sx={{padding:2}} elevation={5}>
                        <Typography variant="subtitle1" component="div">
                           <b>Details</b>
                        </Typography>
                        <div id="spacer" style={{padding: "5px"}}></div>
                        <Typography variant="subtitle1" component="div">
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <EmailOutlined />
                                </ListItemIcon>
                                <ListItemText primary={email}/>
                            </ListItem>
                        </Typography>
                        <Typography variant="subtitle1" component="div">
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <LocalPhoneOutlined />
                                </ListItemIcon>
                                <ListItemText primary={phone?phone:"No phone registered."}/>
                            </ListItem>
                        </Typography>
                        {/* <Typography variant="subtitle1" component="div">
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <GroupOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Group Name"/>
                            </ListItem>
                        </Typography> */}
                        <Typography variant="subtitle1" component="div">
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <LocationOnOutlined />
                                </ListItemIcon>
                                <ListItemText primary={country?
                                    <>
                                        {country?.name} - {country?.code_txt}
                                        &nbsp;
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`${country.flag_url}`}
                                            srcSet={`${country.flag_url} 2x`}
                                            alt=""
                                        />
                                    </>
                                    :"No country registered."}/>
                            </ListItem>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            </Box>
        </Styled.Container>
    );

}