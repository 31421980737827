declare const L:any; 

export class LeafletTileLayers {

    private url: string;
    private attribuition: string; 
    private id: string; 
    private subdomains?: Array<string>; 

    constructor(id:string, url:string, attribuition: string, subdomains:Array<string>){
        this.url = url; 
        this.attribuition = attribuition;
        this.id=id; 
        this.subdomains = subdomains; 
    }

    public tileLayer(){

        const tileLayer = L.tileLayer(
            this.url,
            {
                id: this.id,
                attribuition: this.attribuition,
                subdomains: this.subdomains?this.subdomains:[],
            }
        )

        return tileLayer;
        
    }

}