import { 
    useState,
    useCallback, 
    useContext,
    useEffect
} from 'react';

import { 
    Checkbox, 
    FormControlLabel, 
    Grid, 
    IconButton, 
    Slider ,
    styled,
    Paper
} from "@mui/material"

import { 
    Star,
    StarBorder
} from '@mui/icons-material';
import { DragHandle } from '../DragHandle';
import { GlobalMapContext } from '../../routes';
import { LeafletControler } from './utils/leaflet-controller';

const Item = styled(Paper)(({ theme }) =>{ 

    return ({
        ...theme.typography.body1,
        padding: theme.spacing(0),
        // textAlign: 'start',
        height: '100%',
        boxShadow: theme.shadows[0],
        borderRadius: 0,
        background: 'transparent'
    });

});

interface ControllerProps {
    basemap?: boolean; 
    label: string; 
    config?: string; 
    txtCode?: string; 
    setRefreshList?:Function; 
}

export const Controller = ({
    basemap,
    label,
    config,
    txtCode,
    setRefreshList
}:ControllerProps) => {

    const map:any = useContext(GlobalMapContext);

    const [createdClass, setCreatedClass] = useState(false);
    const [leafletController, setLeafletController] = useState<LeafletControler>()

    /**Creating class based on the config of the map! */
    useEffect(()=> {
        if(map!==undefined && !createdClass && basemap){
            setLeafletController(new LeafletControler(map, "base"));
            setCreatedClass(true);
        }

        if(map!==undefined && !createdClass && config!==undefined){
            setLeafletController(new LeafletControler(map, txtCode?txtCode:"" , config, label));
            setCreatedClass(true);
        }
    }, [basemap, createdClass, map, config, leafletController, txtCode, label])
    
    const [hover, setHover] = useState(false);
    const [enable, setEnable] = useState<boolean>(false);

    /**If the layer is already on the map, checkbox is active! */
    useEffect(()=>{

        if(map?._state && !basemap && leafletController !== undefined){
            setEnable(leafletController.isActive());
            if(setRefreshList!==undefined){
                setRefreshList(true);
            }
        }

    }, [enable, map?._state, basemap, map, leafletController, setRefreshList])

    const toggleEnable = useCallback((e)=>{
        setEnable(e.target.checked);
        if(createdClass){
            if(e.target.checked){
                leafletController?.addLayerToMap();
            }else {
                leafletController?.removeFromMap();
            }
        }
    }, [createdClass, leafletController])

    const handleChangeHover = useCallback((value)=>{
        setHover(value);
    }, [])

    /**Manipulating the initial state of opacity */
    const [opacity, setOpacity] = useState(100);

    /**If the layer opacity is already setted, put into the slider! */
    useEffect(()=>{

        if(map?._state && leafletController !== undefined){
            setOpacity(leafletController?.getOpacity()*100);
        }

    }, [opacity, map?._state, basemap, map, leafletController])

    /**Manipulating with the slider component */
    const handleChangeOpacity = useCallback((event:any)=> {
        if(leafletController!==undefined){
            leafletController.setOpacity(event.target.value/100);
            setOpacity(event.target.value);
        } 
    }, [leafletController])

    /**
     * Manipulating by the map with initial state 
    */
    useEffect(()=>{
        if(map?.baseLayersArray!==undefined && basemap){
            map?.baseLayersArray.map((base:any)=>{
                base.on('add', handleInitialStateOpacity); 
                return true; 
            })
        }
    }, [map?.baseLayersArray, basemap, map])

    const handleInitialStateOpacity = (e:any) => {

        /**Getting the target layer that was setted on the map */
        const base = e.target;

        /**Attribute the last opacity of baselayer selected */
        const lastState = base.options?.opacity*100;

        /**Setting the last state of opacity - MAP */
        base.setOpacity(base.options?.opacity);

        /**Setting the last state of opacity - Menu */
        setOpacity(lastState);

    }

    const initialFavoriteValue = basemap?basemap:false; 
    const [favorite, setFavorite] = useState<boolean>(initialFavoriteValue);

    const handleFavorite = () => {
        if(leafletController!==undefined){
            setFavorite(!favorite);
            leafletController.setLayerAsFavorite(!favorite);
            if(setRefreshList!==undefined){
                setRefreshList(true);
            }
        }
    }

    useEffect(()=>{

        if(map?._state && !basemap && leafletController !== undefined){
            setFavorite(leafletController.isFavorite());
        }

    }, [enable, map?._state, basemap, map, leafletController, setRefreshList])

    const maxLengthString = map?._state?.mode ==='list'?27:22;

    return (
     
        <Grid 
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onMouseEnter={() => handleChangeHover(true)}
            onMouseLeave={() => handleChangeHover(false)}
            sx={{
                borderRadius: "8px"
            }}
        >
            
            <Grid item xs={basemap?12:11} spacing={0}>
                <Grid 
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        contain: 'content'
                    }}
                >
                    <div 
                        style={{
                            position: 'fixed',
                            left: '-6px'
                        }}
                    >
                        {!basemap&&
                            hover&&<Item>
                                        <DragHandle />
                                    </Item>
                        }
                    </div>
                    <div 
                        style={{
                            width: '86%'
                        }}
                    >
                        <FormControlLabel 
                            control={<Checkbox 
                                        value={enable || ""} 
                                        onChange={toggleEnable}
                                        disabled={basemap}
                                        defaultChecked={basemap?true:false}
                                        checked={basemap?basemap:enable}
                                        size={"small"}
                                    />} 
                            label={
                                <p 
                                    style={{
                                        fontSize: '12px',
                                        color: 'rgba(0, 0, 0, 0.7)'
                                    }}
                                    title={label.length>maxLengthString?label:undefined}
                                >
                                    {label.length<maxLengthString+1?label:`${label.slice(0, -(label.length-maxLengthString+1)-4)}...`}
                                </p>
                            }
                            sx={{fontSize: '7px', paddingTop: '2px'}}
                        />
                    </div>
                    <div 
                        style={{
                            position: 'fixed',
                            right: '-0px',
                            top: '5px'
                        }}
                    >
                        {!basemap&&
                            (hover||favorite)&& 
                                    <Item>
                                        <IconButton
                                            onClick={handleFavorite}
                                        >
                                            {favorite?<Star style={{ fontSize: '1rem' }}/>:<StarBorder style={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Item>
                        }
                    </div>             
                    {(enable||basemap)&&
                                        <Slider 
                                            size="small"
                                            onChange={handleChangeOpacity}
                                            value={opacity}
                                            sx={{
                                                padding: 0,
                                                paddingBottom: 1.5,
                                                width: '90%'
                                            }}
                                        />
                    }              
                </Grid>
            </Grid>
        </Grid>
        
    )

}