import * as Styled from './styles';
import { theme } from '../../styles/theme';

import {
    Grid,
    Typography,
    Paper,
    Divider
} from '@mui/material';

import moment from 'moment';

interface LoginActivityPropst {
    firstLoginDate: Date|undefined; 
    lastAccessDate: Date|undefined; 
}

export const LoginActivity = ({
    firstLoginDate,
    lastAccessDate
}:LoginActivityPropst) => {

    /**Will enter a get inside a useEffect to get user based in jwt token here! */

    return (
        <Styled.Container>
            <Typography id="title" component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                &nbsp; Login Activity
            </Typography>
                <Paper id="paper-admin" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography id="content" component="div">
                            <b>First access to site</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id="content" component="div">
                            {firstLoginDate?
                                moment(firstLoginDate.toLocaleString('en-GB')).format('MMMM Do YYYY, h:mm:ss a')
                                :
                                "Not logged yet."
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id="content" component="div">
                            <b>Last action on map</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id="content" component="div">
                            {lastAccessDate?
                                moment(lastAccessDate.toLocaleString('en-GB')).format('MMMM Do YYYY, h:mm:ss a')
                                :
                                "Not logged yet."
                            }
                        </Typography>
                    </Grid>
                </Grid>
                </Paper>
            
        </Styled.Container>
    );

};