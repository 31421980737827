import { stringToColor } from '../../components/Table/utils';
import Avatar from '@mui/material/Avatar';

export const columns = [
    { field: 'icon', 
      headerName: 'Icon', 
      width: 95, 
      editable: false, 
      filterable: false,
      renderCell: (params:any) => (
        <Avatar sx={{bgcolor: stringToColor(params.row.name) }}>
          {
            `${params.row.name?.charAt(0).toUpperCase()||""}`
          }
        </Avatar>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 95,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
      filterable: false,
      renderCell : (params:any) => (
        <div>
          <a href={`users/${params.id}`}>{params.value}</a>
        </div>
      )
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 125,
      editable: false,
      filterable: false,
      renderCell : (params:any) => (
        <div>
          {params.value?
            <img
              loading="lazy"
              width="20"
              src={`${params.value.flag_url}`}
              srcSet={`${params.value.flag_url} 2x`}
              alt=""
            />
            :
            "Not defined"
          }
        </div>
      )
    },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'number',
      width: 200,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      width: 300,
      editable: false,
    },
    {
      field: 'is_admin',
      headerName: 'Is Admin?',
      type: 'boolean',
      width: 100,
      editable: false
    }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue(params.id, 'name') || ''} ${
    //       params.getValue(params.id, 'lastName') || ''
    //     }`,
    // },
];