import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.primary}!important;
        /* height:100vh; */
        overflow: hidden;
        position: relative; 
        padding-bottom: 100px;

        .MuiToolbar-root {
            background: ${theme.colors.blue};
            display: flex;
            justify-content: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        form {
            margin: 20px 0; 
        }

        .MuiButtonBase-root[aria-label="ChevronLeftIcon"]{
            color: ${theme.colors.primary};
            left: 220px;
            position: absolute; 
        }
    `}
`;

export const Logo = styled.div`
    max-width: 197px; 
`;
