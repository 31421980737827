// const STATUS = {
//     DRAFTING: 1,
//     SIGNING: 2,
//     RTD: 3,
//     RGI: 4,
//     TO_BE_RESCINDED: 5,
//     RESCINDED: 6,
//     TRANSFERED: 7,
//     CANCELLED: 8
// };

const TEXT_STATUS = [
    'Not defined',
    'Drafting',
    'Signing',
    'Registered TD (Titles and Deeds)',
    'Registered RE (Real Estate)',
    'To be Rescinded',
    'Rescinded',
    'Transfered',
    'Cancelled'
];

const STYLES = [
    '#cccccc',
    '#d0e3ff',
    '#bbddff',
    '#4c8bca',
    '#2f5092',
    '#ffe699',
    '#c65911',
    '#338255',
    '#9c9ca6'
]

const styleColor = 'display:inline-block;width:15px;height:15px;border-radius:3px;margin:0 4px;';

// const styleEdge = 'display:inline-block;width:15px;height:15px;margin:0 4px;';

export const ContractsLegend = `
    <table cellpadding="8" border="0" width="100%"><tr><td colspan="2" style="padding:2px;"><div style="background-color:lightgray;text-align:center;"><b>Contracts Layer</b></div></td></tr>
        ${TEXT_STATUS.map((text:string, index:number)=>{
            return (`<tr><td><div style="background-color:${STYLES[index]};${styleColor}"></div></td><td>${text}</td></tr>`);
        }).join('')}  
    </table>
`