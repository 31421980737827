import * as Styled from './styles';

import {
    Box,
    OutlinedInput,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Chip,
    SelectProps,
    FormHelperText,
    CircularProgress,
    Grid
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export interface Option{
    id: number|string,
    title: string
}

interface MultipleSelectChipProps {
    title: string,
    name: string, 
    options?: Array<Option>;
    onChange: SelectProps["onChange"];
    onClose?: SelectProps["onClose"];
    value: Array<number|string>;
    error: SelectProps["error"];
}

export const MultipleSelectChip = ({
    title,
    name,
    options,
    onChange,
    onClose,
    value,
    error
}:MultipleSelectChipProps) => {

    return (
        <Styled.Container >
            {options?
            <FormControl sx={{ m: 1, width: '100%' }} error={error}>
                <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    name={name}
                    multiple
                    value={value}
                    onChange={onChange}
                    onClose={onClose}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected:Array<number|string>) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map((value:number|string) => (
                            <Chip key={value} label={options.find((x:Option) => x.id === value)?.title} />
                          ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                  {options.map((option) => (
                      <MenuItem
                          key={option.id}
                          value={option.id}
                          // style={getStyles(option.title, option.id, theme)}
                      >
                          {option.title}
                      </MenuItem>
                  ))}
                </Select>
                <FormHelperText >{error?"This value is required":""}</FormHelperText>
            </FormControl>
            :
            <Grid item xs={12} md={12} sx={{textAlign: 'center'}}>
                <CircularProgress color="inherit"/>
            </Grid>
            }
        </Styled.Container>
    );

}