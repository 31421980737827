import * as Styled from './styles';
import { useState, useEffect } from 'react';

import {
    Box,
    Paper,
    TextField
} from '@mui/material';

import { InfoSelectedRoles } from '../../models/Roles';
import { StepsController } from '../StepsController';

interface RoleRegistrationStepOneProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info?: InfoSelectedRoles;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
}

export const RoleRegistrationStepOne = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    skipped,
    setSkipped,
    setInfo
}:RoleRegistrationStepOneProps) => {

    const [ tempValue, setTempValue ] = useState(info);
    const [ enableNext, setEnableNext ] = useState(true);

    const [ validValue, setValidValue ] = useState({
        title: false,
        description: false
    })

    const [ errors, setErrors ] = useState({
        title: false, 
        description: false,
    })

    const handleTextValue = (e:any) => {
        if(tempValue!==undefined){
            setTempValue({...tempValue, [e.target.name]:e.target.value});
        }
    }

    const checkIfTitleIsValid = (e:any) => {
        if(e.target.value){
            setValidValue({...validValue, title: true})
            setErrors({...errors, title: false})
        }else{
            setValidValue({...validValue, title: false})
            setErrors({...errors, title: true})
        }
    }

    useEffect(() => {
        if(tempValue?.title) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [tempValue]);

    return (
        <Styled.Container>
            <Box
                component="form"
                autoComplete="off"
                noValidate
            >
                <div id='title-create-group'>Role registration</div>
                <Paper elevation={3} id="form-admin">
                    <form  id="form-create-group" noValidate>
                        <TextField
                            fullWidth
                            label="Title"
                            name="title"
                            required
                            autoComplete='off'
                            onChange={handleTextValue}
                            onBlur={checkIfTitleIsValid}
                            error={errors.title}
                            defaultValue={tempValue?.title}
                            helperText={errors.title && "This value is required."}
                        /> 
                    </form >
                    <form  id="form-create-group" noValidate>
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            required
                            autoComplete='off'
                            onChange={handleTextValue}
                            onBlur={checkIfTitleIsValid}
                            error={errors.description}
                            defaultValue={tempValue?.description}
                            helperText={errors.description && "This value is required."}
                        /> 
                    </form >
                </Paper>
            </Box>
            <StepsController 
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                tempValue={tempValue}
            />
        </Styled.Container>
    );

}