import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Table,
    TableContainer,
    Paper,
    Typography,
    Divider,
    LinearProgress
} from '@mui/material';

import axios from 'axios';

import { theme } from '../../styles/theme';

import { CollapseItem } from '../CollapseItem';
import { Roles } from '../../models/Roles';
import { Views } from '../../models/Views';
import { AuthContext } from "../../routes/admin";
import { useParams } from 'react-router-dom';

interface TableContainerPaperProps {
    info: Array<Roles|Views>;
    target: "views" | "roles";
}

export const TableContainerPaper = ({
    info,
    target
}:TableContainerPaperProps) => {

    let { user } = useParams();

    const context:any = useContext(AuthContext);

    const [data, setData] = useState<Array<Views|Roles>>();

    const route = target==='views'?`/api/users/${user}/views`:`/api/users/${user}/roles`;

    useEffect(() => {
    
        if(context.user?.accessToken){
            axios.get(`${process.env.REACT_APP_AUTH_SERVICE}${route}`, {
                headers: {
                    Authorization: `Bearer ${context.user?.accessToken}`
                }
            })
            .then((value)=>{
                setData(value.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.user?.accessToken])
    
    if(!data) {
        return (
            <Styled.Container>
                <Typography id="title" component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                    &nbsp; {target==='views'?'Views':'Roles'}
                </Typography>
                <Paper id="paper-admin" elevation={3}>
                    <LinearProgress color="inherit"/>
                </Paper>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Typography id="title" component="div" sx={{fontSize: theme.fonts.sizes.small}}>
                &nbsp; {target==='views'?'Views':'Roles'}
            </Typography>
            <Paper id="paper-admin" elevation={3}>
                <TableContainer>
                    <Table aria-label="collapsible table">
                        
                        {data.map((oneData)=>{
                            return  <>
                                        <CollapseItem 
                                            id={oneData.id}
                                            title={oneData.title}
                                            description={oneData.description}
                                        />
                                        <Divider />
                                    </>
                        })}
                    </Table>
                </TableContainer>
            </Paper>
        </Styled.Container>
    );

}
