import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import {
    Box,
    Stack,
    Paper,
    styled
} from '@mui/material';

import axios from 'axios';

import useQuery from '../../hooks/query';

import { HorizontalLinearStepper } from '../../components/HorizontalLinearStepper';
import { RoleRegistration } from '../../components/RoleRegistration';
import { InfoSelectedRoles } from '../../models/Roles';
import { Permission } from '../../models/Permissions';
import { AuthContext } from '../../routes/admin';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const CreateRoles = () => {

    const context:any = useContext(AuthContext);

    const idValue = useQuery().get("id"); 
    const [error, setError] = useState(false);
    const edit = idValue === null || idValue === "" ? false : true; 
    
    const [roles, setRoles] = useState<InfoSelectedRoles>({
        id: null,
        title: "",
        description: ""
    });

    useEffect(()=> {
        
        if(edit){
            const endpoint = process.env.REACT_APP_AUTH_SERVICE + '/api/roles/'+idValue; 
            axios.get(endpoint, {
                    params: {
                        ID: 12345
                    },
                    headers: {
                        Authorization: `Bearer ${context.user?.accessToken}`
                    }
                })
                .then(function (response) {
                    
                    const { id, title, description, permissions } = response.data; 
                
                    setRoles({
                        id: id, 
                        title: title, 
                        description: description,
                        permissions: permissions.map((permission:Permission)=> permission.id)
                    })
                })
                .catch(function (error) {
                    setError(true);
                });
        }
        
    }, [])

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const steps = ['Informations about your roles', 'Select permissions for your role', 'Review your created role'];

    if(error){
        return(
            <Styled.Container>
                <Box sx={{ width: '100%' }} >
                    <Stack direction="row" spacing={2} id="top-tab-admin">
                        <Item id="tab-admin-title" elevation={0} >No roles found to edit</Item>
                    </Stack>
                </Box>
            </Styled.Container>
        )
    }

    return (
        <Styled.Container>
            <Box sx={{ width: '100%' }} >
                <Stack direction="row" spacing={2} id="top-tab-admin">
                    <Item id="tab-admin-title" elevation={0} >{edit?"Edit":"Create"} Roles</Item>
                </Stack>
                <HorizontalLinearStepper 
                    steps={steps}
                    activeStep={activeStep}
                    skipped={skipped}
                />
                <RoleRegistration 
                    id={idValue}
                    edit={edit}
                    steps={steps}
                    hrefOrig="/admin/roles"
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    info={roles}
                    setInfo={setRoles}
                    skipped={skipped}
                    setSkipped={setSkipped}
                />
            </Box>
        </Styled.Container>
    );

}