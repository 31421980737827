import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #top-tab-collapse{
            margin-top: 0;
        }

        #tab-component-title{
            background: transparent!important;
        }

        #list-component{
            padding: 0 30px;
            margin-bottom: 30px;
            margin-top: 5px;
            max-height: 650px;
            overflow-y: auto;
        }

        svg[data-testid="ArrowRightIcon"], svg[data-testid="ArrowDropDownIcon"] {
            color: ${theme.colors.dark};
        }

        #user-title-component{
            font-size: ${theme.fonts.sizes.small};
            padding: 0; 
        }
    `}
`;