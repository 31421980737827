import Avatar from '@mui/material/Avatar';

export const stringToColor = (string:string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}
  
export const createData =  (name:string, icon:string, email:string, href:string) => {

    const componentEmail = <a href="/admin/users/cod">{email}</a> 
    
    const splitName = name.split(" ");

    const firstLetter = splitName[0]!==undefined?splitName[0]?.charAt(0):"";
    const secondLetter = splitName[1]!==undefined?splitName[1]?.charAt(0):"";
    
    const iconAvatar = <Avatar sx={{bgcolor: stringToColor(name) }}>{firstLetter+secondLetter}</Avatar>

    return { name, icon: iconAvatar, email: componentEmail };
}