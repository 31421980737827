//@ts-nocheck
import styled from 'styled-components';

export const HomeTopPageBox = styled.div`
    width: 70rem;
    position: relative;
    display: inline-block;
    width: 48.4rem;
    /* margin-top: 12.9rem; */
    margin-top: 55px;
    margin-left: 100px;
    padding-top: 35px; 
    @media (min-width: ${props => props.theme.screenSizes.for_big_desktop_up}) {
        margin-top: 55px;
        margin-left: 150px;
    };
    &::before {
        content: "";
        position: absolute;
        top: -5px;
        left: -98px;
        width: 633px;
        height: 1px;
        background-color: #fff;
        opacity: .3;
    }
    &::after {
        content: "";
        position: absolute;
        top: -71px;
        left: -26px;
        width: 1px;
        height: 336px;
        background-color: #fff;
        opacity: .3;
    }
    div.content {
        display: block;
        position: relative;
        background-color: rgba(8, 139, 174, .3);
        width: 45vw;
        padding: 2.5rem 3.2rem 1rem;
        font-size: 1.2rem;
        color: #fff;
        margin-left: 3rem;
        margin-top: 2rem;
        border-radius: 10px; 
        P {
            margin-bottom: 2rem;
        }
        transform: translateY(55%);
        &::before {
            content: "";
            position: absolute;
            top: -35px;
            left: -55px;
            width: 96px;
            height: 1px;
            background-color: #fff;
            opacity: .3;
        }
        &::after {
            content: "";
            position: absolute;
            top: -51px;
            left: -22px;
            width: 1px;
            height: 96px;
            background-color: #fff;
            opacity: .3;
        } 
        @media (min-width: ${props => props.theme.screenSizes.for_tablet_landscape_up}) {        
            width: 28rem;
            margin-left: 8rem;
            margin-top: 2rem;
        }
        @media (min-width: ${props => props.theme.screenSizes.for_desktop_up}) {        
            width: 28rem;
            margin-left: 8rem;
            margin-top: 2rem;
        }
        @media (min-width: ${props => props.theme.screenSizes.for_big_desktop_up}) {        
            width: 34rem;
            margin-left: 8rem;
            margin-top: 0rem;
        }
    }
`;