import 'leaflet.utm/L.LatLng.UTM';

export class LeafletTransformations {

    private numberFormat(number:any, decimals:any, decPoint:string|undefined, thousandsSep:string|undefined) {
        decimals = Math.abs(decimals) || 0;
        number = parseFloat(number);

        if (!decPoint || !thousandsSep) {
            decPoint = ',';
            thousandsSep = '.';
        }

        const elevated:any = '1e';

        var roundedNumber = Math.round(Math.abs(number) * (elevated + decimals)) + '';
        var numbersString:string | number = decimals ? (roundedNumber.slice(0, decimals * -1) || 0) : roundedNumber;
        var decimalsString = decimals ? roundedNumber.slice(decimals * -1) : '';
        var formattedNumber = "";

        while (typeof numbersString === 'string' && numbersString.length > 3) {
            formattedNumber = thousandsSep + numbersString.slice(-3) + formattedNumber;
            numbersString = numbersString.slice(0, -3);
        }

        if (decimals && decimalsString.length === 1) {
            while (decimalsString.length < decimals) {
                decimalsString = decimalsString + decimalsString;
            }
        }

        return (number < 0 ? '-' : '') + numbersString + formattedNumber + (decimalsString ? (decPoint + decimalsString) : '');
    }

    public formatDistance (val:number) {
        
        var sv;
        if (val<10000){
            sv=this.numberFormat(val, 1, ',', '.')+'m';
        }else{
            sv=this.numberFormat(val/1000, 3, ',', '.')+'km';
        }
        return sv;
        
    }

    public formatArea (val:number){
        var sv;
        if (val<1000000){
            sv=this.numberFormat(val, 1, ',', '.')+'m²';
        }else{
            sv=this.numberFormat(val/1000000, 3, ',', '.')+'km²';
        }
        return sv;
    }

    public formatHectares(val:number){
        var sv=this.numberFormat(val, 4, ',', '.');
	    return sv;
    }

    private decToDeg(decimalDegree:number|undefined){
        var sgn=decimalDegree!==undefined&&decimalDegree>0?"":"-";
        var d=decimalDegree!==undefined?Math.abs(decimalDegree):0;
        var degrees=Math.floor(d);
        d=(d-degrees)*60;
        var minutes=Math.floor(d);
        d=(d-minutes)*60;
        var seconds=d.toFixed(3);
        return sgn+degrees+"º"+minutes+"'"+seconds+'"';
    };

    public formatLatLng(unit:string, latlng:any){

        switch(unit){
            case 'deg':
                return `${this.decToDeg(latlng?.lat)} / ${this.decToDeg(latlng?.lng)} `;
            case 'utm':
                return latlng?.utm().toString();
            default:
                return `${latlng?.lat.toFixed(8)} / ${latlng?.lng.toFixed(8)}`;
        }

    }

}