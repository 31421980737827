import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    #background {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      background-image: url("https://res.cloudinary.com/leonardosilva/image/upload/v1631544574/background-image_qbotio.jpg");
      background-color: #cccccc;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    #user-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      background-color: #fefefe;
      align-items: center;
      box-shadow: 0 0 1em gray;
      border-radius: 10px;
      width: 335px;
      padding: 10px;
      opacity: 0.92; 
    }

    p {
      padding: 10px;
    }

    form {
      display: flex;
      width: 90%;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .forgot-password {
      padding: 10px 30px;
      margin-bottom: 30px;
      color: ${theme.colors.secondary};
      cursor: pointer;
    }

    .copyright{
      padding: 10px 30px;
      color: ${theme.colors.gray};
    }

    .txt-field {
      margin: 5px 0;
      width: 100%;
    }

    #not-found-title{
      font-size: 18px; 
    }

    #not-found-text{
      text-align: center; 
      margin: 10px;
    }

    .user-not-found-error{
      font-size: 150px; 
      color: ${theme.colors.secondary};
    }

    .btn-login {
      width: 100%;
      height: 40px;
      background-color: ${theme.colors.secondary};
      margin: 15px 0;
    }

    .btn-second {
      width: 100%;
      height: 40px;
      color: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondary};
    }

    .btn-second:disabled {
      width: 100%;
      height: 40px;
      color: ${theme.colors.gray};
      border: 1px solid ${theme.colors.gray};
    }

    .btn-second:hover {
      border: 1px solid ${theme.colors.secondary};
    }

    .btn {
      margin: 5px;
      width: 90%;
    }
    #btn-sign-in {
      color: white;
      background-color: ${theme.colors.blue};
      border: 1px solid ${theme.colors.blue};

      :hover {
        color: ${theme.colors.blue};
        background-color: white;
        border: 1px solid ${theme.colors.blue};
      }
    }
    #btn-sign-up {
      color: ${theme.colors.blue};
      background-color: white;
      border: 1px solid ${theme.colors.blue};

      :hover {
        color: white;
        background-color: ${theme.colors.blue};
      }
    }
    #btn-sign-in-mfa {
      color: white;
      background-color: ${theme.colors.blue};
      border: 1px solid ${theme.colors.blue};

      :hover {
        color: ${theme.colors.blue};
        background-color: white;
        border: 1px solid ${theme.colors.blue};
      }
    }

    #cdv-logo {
      max-width: 250px;
      width: auto;
      height: auto;
      padding: 10px 30px;
      // border-radius: 50%;
    }
  `}
`;
