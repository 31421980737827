import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`

        height: 100%;
        padding-top: 35px;

        button {
            margin: 3px;
            background: ${theme.colors.primary};
            border-radius: 2px;
            padding: 0px;
        }

        button:hover {
            background: ${theme.colors.grayBg}
        }

        button > svg {
            color: black;
        }

    `}
`;