import { glepColors } from "./leaflet-styles";

const contractsText:any = {
	0: 'Not defined',
	1: "Drafting",
	2: "Signing",
	3: "Registered TD (Titles and Deeds)",
	4: "Registered RE (Real Estate)",
	5: "To be Rescinded",
	6: "Rescinded",
	7: "Transfered",
	8: "Cancelled"
};

export class ContractsMethods {

    public convertDataToFeatures (data:any) {

        const featuresByBoundingBox = data.map((glebe:any)=>{ 

            const geometry = glebe.glep1geom?glebe.glep1geom:glebe.prop1geom;
            const type = "Feature";

            const properties = {
                id: glebe.ctrt1_cod_txt,
                name: glebe.prop1_nom,
                owner: glebe.ctrt1_prio_cache, 
                status: contractsText[glebe.status],
                statusCode: glebe.status
            };
            
            const feature = {
                type: type,
                properties: properties,
                geometry: geometry
            }
            
            return feature;     
        });

        return featuresByBoundingBox; 

    }

    public createStyleByFeature(feature:any) {

        const status:any = feature.properties.statusCode;

        return {
            weight: 1.5,
            fillColor: glepColors[status],
            fillOpacity: 0.8,
            color: 'white',
            pane: 'ctrt'
        }

    }

}