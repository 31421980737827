import { useContext, useEffect, useState } from 'react';

import { SortableList } from '../SortableList';
import { arrayMove } from 'react-sortable-hoc';
import { GroupItem } from '../GroupItem';
import { GlobalMapContext } from '../../routes';
import { LeafletGroupLayers } from './leaflet-group-layers';

export interface Group {
    id: string|null;
    name: string; 
}

export interface GroupLayersProps {
    onlyActives?: boolean;
    onlyFavorites?: boolean; 
    search?: string; 
}

export const GroupLayers = ({
    onlyActives,
    onlyFavorites,
    search
}:GroupLayersProps) => {

    const map:any = useContext(GlobalMapContext);

    const [groupController, setGroupController] = useState<LeafletGroupLayers>();
    const [group, setGroup] = useState<Array<Group>>();

    useEffect(()=>{
        if(map?._state!==undefined) {
            setGroupController(new LeafletGroupLayers(map)); 
        }
    },[map, map?._state])
    
    useEffect(()=>{
        if(groupController!==undefined){
            groupController.setZIndex();
            setGroup(groupController.getGroupLayers());
        }
    }, [groupController])
    
    const onSortEndGroup = ({oldIndex, newIndex}:any) => {
        if(group!==undefined && groupController!==undefined){
            setGroup(arrayMove(group, oldIndex, newIndex));
            groupController.setGroupLayers(arrayMove(group, oldIndex, newIndex));
        }
    }

    return(
        <SortableList
            onSortEnd={onSortEndGroup}
            helperClass="SortableHelper"
            axis="y"
            lockAxis="y"
            lockToContainerEdges
            useDragHandle
        >
            {group?.map((value:any, index:number) => (
                <GroupItem
                    key={`group-item-${value.id}`}
                    value={value.name}
                    index={index}
                    id={value.id}
                    onlyActives={onlyActives}
                    onlyFavorites={onlyFavorites}
                    search={search}
                />
            ))}
        </SortableList>        
    )

}