import { useContext, useEffect, useState } from "react";
import CircleRulerIcon from './assets/circle-ruler.png'
import { StraightenOutlined, ZoomIn, ZoomOut, Language } from "@mui/icons-material"
import { Grid, IconButton, Paper } from "@mui/material"
import { theme } from "../../styles/theme"
import * as Styled from './styles';
import { styled } from "@mui/material";

/** Importing leaflet draw! */
import 'leaflet-draw/dist/leaflet.draw-src';
import 'leaflet-draw/dist/leaflet.draw-src.css'

/** Importing leaflet ruler! */
import 'leaflet-ruler/src/leaflet-ruler.js';
import 'leaflet-ruler/src/leaflet-ruler.css';

/** Importing leaflet graticule! */
import 'leaflet-graticule/Leaflet.Graticule';

/** Importing class to controle circle ruler */
import { CircleRuler } from "./utils/leaflet-circle-ruler";

import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';

import $ from 'jquery';
import { AuthContext } from "../../routes/security";
import axios from "axios";
import { LeafletCenterGeometry } from "../../templates/Map/utils/leaflet-center-geometry";

declare const L: any;

const Item = styled(Paper)(({ theme }) =>{ 

    return ({
        ...theme.typography.body1,
        padding: theme.spacing(0),
        textAlign: 'center',
        // color: theme.palette.text.primary,
        height: '100%',
        boxShadow: theme.shadows[0],
        borderRadius: 0,
        background: 'transparent',
        maxHeight: 40
    });

});

interface ToolbarProps {
    map?: any;
    setBoundsRectangle: Function; 
    setStart: Function; 
    setInitialPoint: Function; 
    start: boolean; 
    initialPoint?: L.LatLngBounds; 
}

/**
 * States on map
 * 
 * 1. lineRuler
 * 2. circleRuler
 * 3. zoomByWindowIn
 * 4. zoomByWindowOut
 */

export const Toolbar = ({
    map,
    setBoundsRectangle,
    start, 
    setStart,
    setInitialPoint,
    initialPoint
}:ToolbarProps) => {

    // map?.addControl(drawControl);
    /**Create zoom by window! */
    const [zoomIn, setZoomIn]=useState<boolean>(false);
    const [zoomOut, setZoomOut]=useState<boolean>(false);

    const handleZoomIn = () => {
        if(map){
            map.zoomByWindowIn = !zoomIn;
            if(map.zoomByWindowOut){
                handleZoomOut();
            }
        }

        setZoomIn(!zoomIn);

        if(map?.zoomByWindowIn){
            $('#zoom-in-controller').css('background', 'gray'); 
        } else {
            $('#zoom-in-controller').css('background', theme.colors.primary); 
        }
    }

    const handleZoomOut = () => {

        if(map){
            map.zoomByWindowOut = !zoomOut;
            if(map.zoomByWindowIn){
                handleZoomIn();
            }
        }

        setZoomOut(!zoomOut);

        if(map?.zoomByWindowOut){
            $('#zoom-out-controller').css('background', 'gray'); 
        } else {
            $('#zoom-out-controller').css('background', theme.colors.primary); 
        }
    }

    useEffect(()=>{

        if(zoomIn || zoomOut){
            map?.on('mousedown', startZoom);
            map?.on('mouseup', stopZoom);
            map?.on('mousemove',moveZoom);
        }

        return () =>{
            map?.off('mousedown', startZoom);
            map?.off('mouseup', stopZoom);
            map?.off('mousemove',moveZoom);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[zoomIn, zoomOut])

    const startZoom = (e:any) =>{
       
        
        setInitialPoint(L.latLngBounds(e.latlng, e.layerPoint));
        setStart(true);
        setBoundsRectangle(L.latLngBounds(e.latlng, e.layerPoint));
        map?.dragging.disable();

    }

    const stopZoom = (e:any) =>{

        if(zoomIn){
            const bounds = L.latLngBounds(e.latlng, e.layerPoint);

            if(initialPoint!==undefined){
                const expressionBounds:L.LatLngBoundsExpression = [[initialPoint.getSouthWest().lat, initialPoint.getSouthWest().lng],
                [bounds.getNorthEast().lat, bounds.getNorthEast().lng]];

                map?.fitBounds(expressionBounds);
            }

            setStart(false);
            setZoomIn(false);

            if(map){
                map.zoomByWindowIn = false;
                $('#zoom-in-controller').css('background', theme.colors.primary); 
            }
        }

        if(zoomOut){

            var zoom = map?.getZoom();

            if(zoom!==undefined){
                map?.setView(e.latlng, zoom-2);
            }

            setZoomOut(false);
            setStart(false);
            setZoomOut(false);
            if(map){
                map.zoomByWindowOut = false;
                $('#zoom-out-controller').css('background', theme.colors.primary); 
            }
        }
        
        map?.dragging.enable();
        
    }

    const moveZoom = (e:any) => {
        setBoundsRectangle(L.latLngBounds(e.latlng, e.layerPoint));
    }

    /**Create a ruler leaflet with local package! */
    const [ruler, setRuler]=useState<boolean>(false);
    const [rulerClass, setRulerClass]=useState<any>();

    useEffect(()=>{

        if(!rulerClass && map){

            map.createPane('regua');
            const reguaPane = map.getPane('regua'); 
            reguaPane.style.zIndex = '1003';

            map.createPane('ttregua');
            const ttReguaPane = map.getPane('ttregua'); 
            ttReguaPane.style.zIndex = '1004';

            const Ruler = new L.Control.Ruler({
                position: 'topright',         // Leaflet control position option
                circleMarker: {               // Leaflet circle marker options for points used in this plugin
                color: 'blue',
                radius: 2,
                pane: 'regua'
                },
                lineStyle: {                  // Leaflet polyline options for lines used in this plugin
                color: 'blue',
                dashArray: '1,6',
                pane: 'regua'
                },
                lengthUnit: {                 // You can use custom length units. Default unit is kilometers.
                display: 'km',              // This is the display value will be shown on the screen. Example: 'meters'
                decimal: 2,                 // Distance result will be fixed to this value. 
                factor: null,               // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)  
                label: 'Distance:'           
                },
                angleUnit: {
                display: '&deg;',           // This is the display value will be shown on the screen. Example: 'Gradian'
                decimal: 2,                 // Bearing result will be fixed to this value.
                factor: null,                // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
                label: 'Bearing:'
                },
                pane:'regua',
                ttpane: 'ttregua'
            });

            setRulerClass(Ruler);
        }
    }, [rulerClass, map]);

    const handleRuler = () => {

        if(!ruler){
            let reguaPane = map.getPane('regua');
            if(!reguaPane) {            
                const reguaPane = map.createPane('regua'); 
                reguaPane.style.zIndex = '1003';
            }
            rulerClass?.enable(map);
            map.lineRuler = true;
            if(map.circleRuler && circleRulerController) {
                circleRulerController?.toggleCircleRuler();
                $('#circle-ruler-toggle').css('background', theme.colors.primary);
            }

            setRuler(true);
        }else{
            rulerClass?.disable(map);
            map.lineRuler = false;
            setRuler(false);
        }

        if(map?.lineRuler){
            $('#leaflet-ruler-controller').css('background', 'gray');
        } else {
            $('#leaflet-ruler-controller').css('background', theme.colors.primary);
        }

    }

    /**Create a graticule based on a package! */
    const [graticule, setGraticule] = useState<boolean>(false);
    const [graticuleClass, setGraticuleClass] = useState<any>();

    useEffect(()=>{
        const graticule = L.latlngGraticule({
            zoomIntervals:[
                {start:0,end:3,interval:20},
                {start:4,end:5,interval:10},
                {start:6,end:7,interval:5},
                {start:8,end:10,interval:1},
                {start:11,end:12,interval:0.5},
                {start:13,end:13,interval:0.2},
                {start:14,end:14,interval:0.1},
                {start:15,end:15,interval:0.05},
                {start:16,end:16,interval:0.02},
                {start:17,end:17,interval:0.01},
                {start:18,end:20,interval:0.005}
            ]
        });
        setGraticuleClass(graticule);
    },[])

    const handleGraticule = () => {

        if(!graticule){
            setGraticule(true);
            graticuleClass.addTo(map);
            map.graticule = true; 
        }else{
            setGraticule(false);
            graticuleClass.removeFrom(map);
            map.graticule = false; 
        }

        if(map?.graticule){
            $('#graticule-toggle').css('background', 'gray');
        } else {
            $('#graticule-toggle').css('background', theme.colors.primary);
        }

    }

    const [circleRulerController, setCircleRuler] = useState<any>();

    useEffect(()=>{
        if(circleRulerController===undefined && map!==undefined) {
            const classCircleRuler = new CircleRuler(map);
            setCircleRuler(classCircleRuler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map])

    const handleCircleRuler = () => {
        
        circleRulerController?.toggleCircleRuler();
        if(map.lineRuler && rulerClass){
            rulerClass.disable();
            map.lineRuler = false;
            $('#leaflet-ruler-controller').css('background', theme.colors.primary);
            setRuler(false);
        }

        if(map?.circleRuler){
            $('#circle-ruler-toggle').css('background', 'gray');
        } else {
            $('#circle-ruler-toggle').css('background', theme.colors.primary);
        }
    }

    const handleInfo = () => {

        if(map?.info){
            map.info = false; 
        } else {
            map.info = true; 
        }

        if(map?.info){
            $('#info-toggle').css('background', 'gray');
        } else {
            $('#info-toggle').css('background', theme.colors.primary);
        }

        if (map?.info) $('.leaflet-tooltip-pane').show(); else $('.leaflet-tooltip-pane').hide();

    }

    const handleTooltip = () => {

        if(map?.tooltip){
            map.tooltip = false; 
        } else {
            map.tooltip = true; 
        }

        if(map?.tooltip){
            $('#tooltip-toggle').css('background', 'gray');
        } else {
            $('#tooltip-toggle').css('background', theme.colors.primary);
        }

        if (!map?.tooltip) $('.leaflet-tooltip-pane').show(); else $('.leaflet-tooltip-pane').hide();

    }

    const { user } = useContext(AuthContext);

    const centerFocus = () => {
        
        axios.get(`${process.env.REACT_APP_WEB_SERVICE}/api/lands/boundingbox/user?geojson=true`,{
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then((res)=>{

            const {
                polygon
            } = res.data;

            const centerByGeometry = new LeafletCenterGeometry(map);
            
            centerByGeometry.centerByGeometry(polygon);

        })
        .catch((error)=>console.log(error)); 

    }
    

    return(
        <Grid 
            container
            direction="column"
            sx={{
                width: 30,
                background: theme.colors.secondary
            }}
        >
            <Styled.Container>
                <Grid item xs container direction="column" spacing={1}>
                    <Grid item xs>
                        <Item>
                            <IconButton
                                onClick={handleZoomIn}
                                title={"Zoom In"}
                                id="zoom-in-controller"
                            >
                                <ZoomIn />
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleZoomOut}
                                title={"Zoom Out"}
                                id="zoom-out-controller"
                            >
                                <ZoomOut />
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleRuler}
                                title={"Line Ruler"}
                                id="leaflet-ruler-controller"
                            >
                                <StraightenOutlined />
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleCircleRuler}
                                title={"Circle Ruler"}
                                id={'circle-ruler-toggle'}
                            >
                                <img   
                                    src={CircleRulerIcon} 
                                    alt={"Circle Ruler"} 
                                    width="20px"
                                    style={{
                                        padding: '2.5px'
                                    }}
                                />
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleGraticule}
                                title={"Toggle Graticule"}
                                id={'graticule-toggle'}
                            >
                                <Language />
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleInfo}
                                title={"Toogle Info"}
                                id={'info-toggle'}
                            >
                                <GpsFixedIcon/>
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={handleTooltip}
                                title={"Toggle Tooltip"}
                                id={'tooltip-toggle'}
                            >
                                <ShareLocationIcon/>
                            </IconButton>
                        </Item>
                        <Item>
                            <IconButton
                                onClick={centerFocus}
                                title={"Center Focus"}
                                id={'center-focus'}
                            >
                                <FilterCenterFocusIcon/>
                            </IconButton>
                        </Item>
                    </Grid>
                </Grid>
            </Styled.Container>
        </Grid>
       
    )

}