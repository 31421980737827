import { STYLES_LANDS } from "../../../../templates/Map/utils/Lands/leaflet-styles";

export const getStatusCustomersByDominio = (status:number):number=>{

    let statusCustomers;
    switch(status){
        case 2:
            statusCustomers = 1;
            break; 
        case 3:
            statusCustomers = 0;
            break; 
        case 4:
            statusCustomers = 2;
            break;
        case 5:
            statusCustomers = 2;
            break; 
        case 6:
            statusCustomers = 2;
            break; 
        case 7:
            statusCustomers = 1;
            break; 
        case 9:
            statusCustomers = 2;
            break; 
        default:
            statusCustomers = 2;
    }

    return statusCustomers;

}

const TEXT_STATUS = {
    0:'Partner',
    1:'Contracted',
    2:'Contracting'
};

const styleColor = 'display:inline-block;width:15px;height:15px;border-radius:3px;margin:0 4px;';

const styleEdge = 'display:inline-block;width:10px;height:10px;margin:0 4px;';

const styleName = 'padding:2px;';

//Status GEO 
const TEXT_STATUS_GEO = {
    0:'Not Georeferenced',
    1:'Outdated',
    // 2:'Not Georeferenced/Not Authorized',
    // 3:'Not Georeferenced/Not Defined',
    2:'Pending Registry RE',
    // 5:'Outdated/Not Authorized',
    // 6:'Outdated/Not Defined',
    3:'Registered RE'
};

const BORDER_STATUS_GEO:any = {
    '0':'solid 2px #bfbfbf',
    // '2':'dashed 2px #ff0000',
    // '3':'dashed 2px #ff5555',
    '1':'solid 2px #ff9933',
    // '5':'dashed 2px #ff9933',
    // '6':'dashed 2px #ffbb66',
    '2':'dashed 2px #0000ff',
    '3':'solid 2px #0000ff'
};

export const LandsLegend = `
    <table cellpadding="8" border="0" width="100%"><tr><td colspan="2" style="padding:2px;"><div style="background-color:lightgray;text-align:center;"><b>Lands Layer</b></div></td></tr>
        ${Object.entries(TEXT_STATUS).map((entries:Array<string>)=>{
            const index = entries[0];
            const text = entries[1];
            return (`<tr>
                        <td style="width: 5px;">
                            <div style="background-color:${STYLES_LANDS[index]};${styleColor}"></div>
                        </td>
                        <td>
                            ${text}
                        </td>
                    </tr>`
            );
        }).join('')}  
        
        <tr><td colspan="2"><b>Status GEO</b></td>
        ${Object.entries(TEXT_STATUS_GEO).map((entries:Array<string>)=>{

            // console.log(entries);
            const index = entries[0];
            const text = entries[1];
            return(`<tr><td style="width: 5px;"><div style="background-color:#ffffff;border:${BORDER_STATUS_GEO[index]};${styleEdge}"></div></td><td style=${styleName}>${text}</td></tr>`)
        }).join('')}
    </table>
`