import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    #background {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      background-image: url("https://res.cloudinary.com/leonardosilva/image/upload/v1631544574/background-image_qbotio.jpg");
      background-color: #cccccc;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    // #user-container {
    //   align-items: center;
    //   display: flex;
    //   flex-direction: column;
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   background-color: #fefefe;
    //   align-items: center;
    //   box-shadow: 0 0 1em gray;
    //   border-radius: 10px;
    //   padding: 20px;
    // }

    // p {
    //   padding: 10px;
    // }

    // .btn {
    //   margin: 10px;
    //   color: white;
    //   background-color: ${theme.colors.blue};

    //   :hover {
    //     background-color: ${theme.colors.darkBlue};
    //   }
    // }
    // #profile-picture {
    //   max-width: 250px;
    //   width: auto;
    //   height: auto;
    //   padding: 20px 30px;
    //   border-radius: 50%;
    // }
  `}
`;
