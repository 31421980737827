import * as Styled from './styles';

import {
    Paper,
    IconButton,
    TextField
} from '@mui/material';

import {
    Search,
    Clear
} from '@mui/icons-material';

interface SearchProps {
    children: any;
    width?: string;
    search: string|undefined;
    handleChangeSearch: any; 
    clearSearch: any; 
}

export const SearchArea = ({
    children,
    width='100%',
    search,
    handleChangeSearch,
    clearSearch
}:SearchProps) =>{

    const handleSubmit = (e:any) => {
        e.preventDefault();
    }

    return (
        <Styled.Container>
            <Paper
                component="form"
                sx={{display: 'flex', alignItems: 'center', width: width }}
                onSubmit={handleSubmit}
                elevation={1}
            >    
                <TextField
                    sx={{ flex: 1 }}
                    placeholder={children}
                    onChange={handleChangeSearch}
                    value={search}
                    id="search"
                    autoComplete='off'
                    InputProps={{
                        startAdornment: (
                            <Search />
                        ),
                        endAdornment: (
                            <IconButton
                              title="Clear"
                              aria-label="Clear"
                              size="small"
                              style={{ visibility: search ? 'visible' : 'hidden' }}
                              onClick={clearSearch}
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                        )
                    }}
                />
            </Paper>
        </Styled.Container>
    );
}