import * as Styled from './styles';

import {
    Paper,
    Stack,
    styled,
    Divider,
} from '@mui/material';

import { ListTransfer } from '../../components/ListTransfer';
import { CollapseComponent } from '../../components/CollapseComponent';
import { useState } from 'react';
import { ListTransferAergs } from '../../components/ListTransferAergs';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export const Import = () => {

    const [reload, setReload] = useState<boolean>(false);

    return (
        <Styled.Container>
            <Stack direction="row" spacing={2} id="top-tab-admin">
                <Item id="tab-admin-title" elevation={0} >Import</Item>
                <div></div>
            </Stack>
            <Paper elevation={2} id="form-admin">
            <CollapseComponent 
                title="Complex"
                defaultState={true}
                children={  <ListTransfer
                                target={"complex"}
                                reload={reload}
                                setReload={setReload}
                            />}
            />
            <Divider />
            <CollapseComponent 
                title="Farms"
                defaultState={false}
                children={  <ListTransfer
                                target={"farms"}
                                reload={reload}
                                setReload={setReload}
                            />}
            />
            <Divider />
            <CollapseComponent 
                title="Layers"
                defaultState={false}
                children={  <ListTransfer
                                target={"layers"}
                            />}
            />
            <Divider />
            <CollapseComponent 
                title="Wind Turbines"
                defaultState={false}
                children={  <ListTransferAergs
                            />}
            />
            </Paper>
        </Styled.Container>
    );

}
