import { Alert, Button, Snackbar, TextField } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';

import * as Yup from "yup";
import { 
    useEffect, 
    useRef, 
    useState, 
} from 'react';
import { useNavigate } from 'react-router-dom';

import EmailIcon from '@mui/icons-material/Email';

import axios from 'axios';

import 'yup-phone';

interface DeviceVerificationProps {
    accessToken?: any; 
    sessionUser?: any; 
}

type FormEnrollPhoneValues = { verificationCode: string };

interface MessageFormat {
    text: string; 
    severity: "error"|"warning"|"info"|"success";
}

export const DeviceVerification = ({
    accessToken, 
    sessionUser
}:DeviceVerificationProps) => {

    const {
        email
    } = sessionUser;

    /**ERROR MESSAGES */
    /**
     * 1. Wrong Code (auth/invalid-verification-code)
     * 2. Too Many Requests (auth/too-many-requests)
     * 3. Code Expired (auth/code-expired)
     * 4. Requires recent login (auth/requires-recent-login)
     * 5. Recaptcha Verifier has been destroyed (auth/internal-error)
     */
    const [message, setMessage] = useState<MessageFormat>({
        text: "",
        severity: "error"
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleCloseErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessage(false);
    }

    const showErrorMessage = ({text, severity}:MessageFormat) => {
        setErrorMessage(true);
        setMessage({
            text,
            severity
        });
    }

    const navigate = useNavigate();

    const [activeSendNewVerificationCode, setActiveSendNewVerificationCode] = useState(false);

    const refEnrollPhone = useRef<FormikProps<FormEnrollPhoneValues>>(null);

    const refCode = useRef<any>(null);

    useEffect(()=>{
        sendVerificationCode();
    }, [])

    function sendVerificationCode (){
        axios.get(`${process.env.REACT_APP_AUTH_SERVICE}/api/device`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(()=>{
            setActiveSendNewVerificationCode(false);
            showErrorMessage({
                text: "A verification code was sent, please await 10 seconds to send a new verification code.",
                severity: 'info'
            });
            setTimeout(()=>{
                setActiveSendNewVerificationCode(true);
                showErrorMessage({
                    text: "Please check you phone to see if you receive a verification code, if you did not send a new verification code.",
                    severity: 'warning'
                });
            }, 10000)
        })
        .catch((e)=>{

            const { error } = e.response.data; 

            if(error === `auth/already-verified`){
                navigate(`/home`);
            } else {
                setActiveSendNewVerificationCode(false);
                showErrorMessage({
                    text: "Error trying to send a verification code, please try again in 10 seconds.",
                    severity: 'error'
                });
            }
        })
    }

    function checkVerificationCode (verificationCode: string) {
        axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/api/device`, {
            verificationCode: verificationCode
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then((res)=>{
            navigate(`/home`);
        })
        .catch((e)=>{
            const { error, message } = e.response.data; 

            if(error === `auth/verification-code-expired`){
                setActiveSendNewVerificationCode(true);
            }
                
            showErrorMessage({
                text: message,
                severity: 'error'
            });
        })
    }

    return (
        <>
            <div
                id="not-found-title"
            >
                2-Step Verification
            </div>
            <EmailIcon 
                className="user-not-found-error"
                sx={{
                    fontSize: '120px',
                    padding: '5px'
                }}
            />
            <p>
                <b>Insert the verification code</b>
            </p>
            <p>
                A message was sent to with the verification code to {email}:
            </p>
            <Formik
                innerRef={refEnrollPhone}
                enableReinitialize={true}
                initialValues={{
                verificationCode: "",
                }}
                validateOnChange={false}
                onSubmit={(values, actions) => {

                    const { verificationCode } = values;

                    checkVerificationCode(verificationCode);

                }}
                validationSchema={Yup.object().shape({
                verificationCode: Yup.string().required("Required field."),
                })}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                <Form noValidate>
                    <TextField
                        required
                        autoFocus={true}
                        disabled={false}
                        inputRef={refCode}
                        autoComplete='off'
                        className="txt-field"
                        variant="outlined"
                        label="Verification Code"
                        id="verificationCode"
                        name="verificationCode"
                        value={values.verificationCode}
                        helperText={
                            errors.verificationCode && touched.verificationCode
                                ? errors.verificationCode
                                : ""
                        }
                        error={
                            errors.verificationCode && touched.verificationCode
                                ? true
                                : false
                        }
                        inputProps={{
                            max: 999999,
                            maxlength: 6
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Button
                        variant="contained"
                        id='btn-next'
                        className="btn-login"
                        type="submit"
                    >
                        Confirm
                    </Button>
                    <Button
                        disabled={!activeSendNewVerificationCode}
                        variant="outlined"
                        id='btn-send-verification'
                        className="btn-second"
                        onClick={undefined}
                    >
                        Send new Verification Code
                    </Button>
                </Form>
                )}
            </Formik>
            <Snackbar
                open={errorMessage}
                autoHideDuration={3000}
                onClose={handleCloseErrorMessage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert onClose={handleCloseErrorMessage} severity={message.severity} sx={{ width: '100%' }}>
                    {message.text}
                </Alert>
            </Snackbar>
        </>
    )

}