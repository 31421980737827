import { getStatusCustomersByDominio } from "../../../../mapComponents/Controller/utils/legend/lands";
import { STYLES_AUT_GEO_CUSTOMERS, STYLES_LANDS } from "./leaflet-styles";

export class LandsMethods {

    public convertDataToFeatures (data:any) {

        const featuresByBoundingBox = data.map((land:any)=>{ 

            const geometry = land.prop1Geom;
            const type = "Feature";
            
            const prop1Dominio = land?.prop1Dominio === null ? 0 : land?.prop1Dominio;  
            const prop1GeoStatus = land?.prop1GeoStatus === null ? 4 : land?.prop1GeoStatus;
            
            const properties = {
                id: land.prop1Cod,
                name: land.prop1Nom,
                owner: land.prop1PrioCache, 
                contracts: land.prop1CtrtCache?JSON.parse(land.prop1CtrtCache):"", 
                geoStatus: prop1GeoStatus,
                dominio: prop1Dominio
            };
            
            const feature = {
                type: type,
                properties: properties,
                geometry: geometry
            }
            
            return feature;     
        });

        return featuresByBoundingBox; 

    }

    public createStyleByFeature(feature:any) {

        const geoStatus:any = feature.properties.geoStatus;

        const dominio:any = getStatusCustomersByDominio(feature.properties.dominio); 

        const autGeo:string = `${geoStatus}`; //On the future can include the autGeo Columns

        return {
            weight: 1.5,
            fillColor: STYLES_LANDS[dominio],
            fillOpacity: 0.8,
            pane: 'newprop',
            ...STYLES_AUT_GEO_CUSTOMERS[autGeo]
        }

    }

}