import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        padding: 50px;

        #form-create-group{
            padding: 40px; 
            height: 40px;
        }
        #title-create-group{
            font-size: ${theme.fonts.sizes.small};
            background: ${theme.colors.grayBg};
            color: ${theme.colors.dark};
            padding: 20px 20px;
            box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
        }
    `}
`;