import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #edit-profile{
            color: ${theme.colors.dark}; 
            background: ${theme.colors.primary};
            border-radius: 5px;
        }
    `}
`;
