import { useContext, useState } from 'react';
import * as Styled from './styles';

import {
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Fade
} from '@mui/material';

import {
    AccountCircle
} from '@mui/icons-material';


import 'firebase/auth';
import { auth } from '../../templates/Auth/config';
import { useNavigate } from 'react-router-dom';
import { useAuthListener } from '../../hooks';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

interface UserprofileProps {
    mobile?: boolean;
};

export const Userprofile = ({
    mobile
}:UserprofileProps) => {

    const { sessionUser } = useAuthListener();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const navigate = useNavigate();

    const handleLogout = () => {
        auth.signOut();
        navigate("/login");
    }

    const openAdminPanel = () => {

        window.open('/admin', '_blank')

    }

    const name = sessionUser?.name?sessionUser?.name:undefined; 
    const photoURL = sessionUser?.picture?sessionUser?.picture:undefined;
    const isAdmin = sessionUser?.is_admin;

    return (
        <Styled.Container>
            {mobile===true?
                <>
                <Styled.TextProfile>
                    {photoURL?<img src={photoURL} alt="" style={{borderRadius:'50%', height:'32px'}}/>:<AccountCircle />}
                    &nbsp;
                    <Typography variant="body2" color="textSecondary" align="center">
                        {name}
                    </Typography>
                </Styled.TextProfile>
                {isAdmin&&
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton
                            onClick={openAdminPanel}
                        >
                            <AdminPanelSettingsIcon />
                        </IconButton>
                    </div>
                }    
                </>
                :
                <>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        {photoURL?<img src={photoURL} alt="" style={{borderRadius:'50%', height:'32px'}}/>:<AccountCircle />}
                        &nbsp;
                        <Typography variant="body2" color="textSecondary" align="center">
                            {name}
                        </Typography>
                    </IconButton>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'id': 'btn-username',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem> */}
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                    {isAdmin&&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <IconButton
                                onClick={openAdminPanel}
                            >
                                <AdminPanelSettingsIcon />
                            </IconButton>
                        </div>
                    }
                </>
            }
        </Styled.Container>
    );

}