import MouseIcon from '@mui/icons-material/Mouse';
import WorkIcon from '@mui/icons-material/Work';
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';

export const transformInfoOnHtml = (codTxt:string, data:any, downloadKml:any) => {

    let content = <></>; 

    if(data!== undefined){

        const info = data.info; 

        if(info || info?.length > 0){
            switch(codTxt){
                case 'newprop':
                    content = 
                        <div
                            id={`content-${codTxt}-${info.prop1Cod})`}
                            style={{
                                borderTop: 'solid 1px gray'
                            }}
                        >
                            <div>
                                <b style={{color: 'red'}}>{info.prop1Cod}</b> - <b>{info.prop1Nom}</b>
                                {downloadKml&&<LoadingButton
                                        id={`content-${codTxt}-${info.prop1Cod}`}
                                        onClick={downloadKml}
                                        loadingPosition="end"
                                        variant="outlined"
                                        sx={{
                                            fontSize: 12
                                        }}
                                        startIcon={
                                            <DownloadIcon
                                            />
                                        }
                                    > 
                                        KML
                                    </LoadingButton> 
                                }
                            </div>
                            <div>
                                Matricula: {info.prop1AreaMatr?info.prop1AreaMatr: 'Não definido'}
                            </div>
                            <div>
                                Área: {info.prop1Area?<>geometria <b>{info.prop1Area.toFixed(2)} ha</b></>: 'Não definido'}
                            </div>
                            <div>
                                Proprietário: {info.prop1PrioCache?<b>{info.prop1PrioCache}</b>:'Não definido'}
                            </div>
                        </div>
                    break; 
                case 'aerg':
                    content = 
                        <>
                            {info.map((oneInfo:any, index:number)=>{
                                return(
                                    <div
                                        key={`content-${codTxt}-${index}`}
                                        id={`content-${codTxt}-${index}`}
                                        style={{
                                            borderTop: 'solid 1px gray'
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    border: `${index===0&&'dashed 1px gray'}`,
                                                    backgroundColor: `${oneInfo.aerg1Portfolio?'#d5edfb':''}`
                                                }}
                                            >
                                                Name: {oneInfo.aerg1Nom?oneInfo.aerg1Nom:""} 
                                                &nbsp; {oneInfo.aerg1Portfolio&&<><WorkIcon sx={{fontSize: 12}}/><b>Portfolio</b></>} 
                                                &nbsp; {index===0?<><MouseIcon sx={{fontSize: 12}}/><b>Clicked</b></>:""}
                                            </div>
                                            <div>Lat/lng: <b>{oneInfo.aerg1Geom?.coordinates[1]}/{oneInfo.aerg1Geom?.coordinates[0]}</b></div>
                                            <div>Complex (Eng.): <b>{oneInfo.aerg1CompName}</b></div>
                                            <div>Project (Eng.): <b>{oneInfo.aerg1Proj}</b></div>
                                            <div>Wind Model: <b>{oneInfo.aerg1Wm}</b> - Cenário: <b>{oneInfo.aerg1Cena}</b></div>
                                            <div>Machine: <b>{oneInfo.aerg1Maq}</b></div>
                                            <div>Power: <b>{oneInfo.aerg1Pot} kW</b></div>
                                            <div>Height: <b>{oneInfo.aerg1Alt} m</b></div>
                                            <div>Capacity Factor: <b>{oneInfo.aerg1Fc} %</b></div>
                                            <div>Annual Energy: <b>{oneInfo.aerg1NetYield.toFixed(2)} MWh/ano</b></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    break; 
                case 'ctrt':
                    content = 
                        <>
                            {info.map((oneInfo:any, index:number)=>{
                                return (
                                    <div
                                        key={`content-${codTxt}-${index}`}
                                        id={`content-${codTxt}-${index}`}
                                        style={{
                                            borderTop: 'solid 1px gray'
                                        }}
                                    >
                                        <div><b>{oneInfo.ctrt1Cod}</b> - <b>{oneInfo.ctrt1CodTxt}</b></div>
                                        <div>Fazenda ({oneInfo.prop1Cod}): {oneInfo.prop1Nom}</div>
                                        <div>Área: <b>{oneInfo.glep1Area?oneInfo.glep1Area.toFixed(2):oneInfo.prop1Area?.toFixed(2)}</b></div>
                                        <div>Área gleba: <b>{oneInfo.glep1Area?oneInfo.glep1Area.toFixed(2):"Toda a propriedade"}</b> {oneInfo.prop1AreaDoc?`documental ${oneInfo.prop1AreaDoc}`:""}</div>
                                        <div>Cessionário(s): <b>{oneInfo.ctrt1CessCache}</b></div>
                                    </div>
                                )
                            })}
                        </>
                    break; 
                default:
                    content = 
                        <>
                            {info.map((oneInfo:any, index:number)=>{

                                const entries:Array<any> = Object.entries(oneInfo);

                                return (
                                    <div
                                        key={`content-${codTxt}-${index}`}
                                        id={`content-${codTxt}-${index}`}
                                        style={{
                                            borderTop: 'solid 1px gray'
                                        }}
                                    >
                                        {entries.map(([key, value]:any)=>{

                                            if(typeof key !== 'object' && typeof value !== 'object')
                                                return(
                                                    <div><b>{key}</b>:{value}</div>
                                                )
                                            else 
                                                return (
                                                    <></>
                                                )
                                        })}
                                    </div>
                                )
                            })}
                        </>
            }
        }

        if(data.info && data.info.length !== 0) {
            return (
                <div key={`info-${codTxt}`} id={`info-${codTxt}`}>
                    <div 
                        id={`title-${codTxt}`}
                        style={{
                            marginTop: 5,
                            backgroundColor: 'lightgray',
                            padding: '1px 2px'
                        }}
                    >
                        {data?.title}
                    </div>
                    {content} 
                </div>
            )
        } 

    } 
        
    return <></>;

}