import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        button{
            color: ${theme.colors.dark} !important
        }
    `}
    display: flex; 
`;

export const TextProfile = styled.div`
    display: flex; 
    align-items: center!important; 
`; 
