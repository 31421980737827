import { useState } from 'react';
import * as Styled from './styles';

import {
    IconButton,
    Menu,
    MenuItem,
    Fade
} from '@mui/material';

import {
    MoreVert,

} from '@mui/icons-material';

import {
    useNavigate
} from 'react-router-dom';

import { Userprofile } from '../Userprofile';
import { SearchArea } from '../SearchArea';

export const MenuMobile = () => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Styled.Container>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                MenuListProps={{
                    'aria-labelledby': 'menu-mobile',
                    'id':'menu-mobile'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem><SearchArea children="Search"/></MenuItem>
                <Userprofile />
                <MenuItem onClick={()=>{navigate("/home")}}>Home</MenuItem>
                <MenuItem onClick={()=>{navigate("/map")}}>Map</MenuItem>
                {/* <MenuItem onClick={()=>{navigate("/reports")}}>Reports</MenuItem> */}
                <MenuItem onClick={()=>{navigate("/contact")}}>Contact</MenuItem>
                <MenuItem onClick={()=>{navigate("/guide")}}>Guide</MenuItem>
            </Menu>
        </Styled.Container>
    );
    
};