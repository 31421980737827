import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Paper,
    Grid,
    CircularProgress,
    Divider
} from '@mui/material';

import { DataGridProps } from '@mui/x-data-grid';

import axios from 'axios';

import { Table } from '../Table';
import { InfoSelectedViews } from '../../models/Views';
import { StepsController } from '../StepsController';
import { CollapseComponent } from '../CollapseComponent';
import { Farms } from '../../models/Farms';
import { Complex } from '../../models/Complex';
import { AuthContext } from '../../routes/admin';

const columnsLayers = [
    {
        field: 'id',
        headerName: 'ID',
        width: 300,
        editable: false, 
        filterable: false
    },
    {
        field: 'txt_code',
        headerName: 'Text Code',
        width: 300,
        editable: false, 
        filterable: true
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: false, 
        filterable: true
    },
    {
        field: 'is_active',
        headerName: 'Active?',
        width: 300,
        editable: false, 
        filterable: true
    },
    {
        field: 'is_public',
        headerName: 'Public?',
        width: 300,
        editable: false, 
        filterable: true
    }
]

const columnsComplex = [
    {
        field: 'id',
        headerName: 'ID',
        width: 150,
        editable: false, 
        filterable: false
    },
    // {
    //     field: 'key_big',
    //     headerName: 'Key BIG',
    //     width: 300,
    //     editable: false, 
    //     filterable: true
    // },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: false, 
        filterable: true
    }
]

const columnsFarms = [
    {
        field: 'id',
        headerName: 'ID',
        width: 150,
        editable: false, 
        filterable: false
    },
    // {
    //     field: 'key_big',
    //     headerName: 'Key BIG',
    //     width: 300,
    //     editable: false, 
    //     filterable: true
    // },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: false, 
        filterable: true
    },
    {
        field: 'complexId',
        headerName: 'Complex ID',
        width: 300,
        editable: false, 
        filterable: true
    }
]

const columnsAergs = [
    { 
        field: 'id', 
        headerName: 'Site ID', 
        width: 80 
    },
    {
        field: 'aerg1Count',
        headerName: 'Count',
        width: 75,
        editable: false
    },
    {
        field: 'aerg1Cena',
        headerName: 'Scenery',
        width: 80,
        editable: false,
    },
    {
        field: 'aerg1CompName',
        headerName: 'Complex',
        width: 100,
        editable: false
    },
    {
        field: 'aerg1Proj',
        headerName: 'Project',
        width: 85,
        editable: false
    },
    {
        field: 'aerg1Portfolio',
        headerName: 'Port.',
        width: 60,
        editable: false
    },
    {
        field: 'stag1Cod',
        headerName: 'Status',
        width: 70,
        editable: false
    }
];

interface ViewRegistrationStepTwoProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number;
    setActiveStep: Function;
    info?: InfoSelectedViews;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
}

export const ViewRegistrationStepTwo = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped
}:ViewRegistrationStepTwoProps) => {

    const context:any = useContext(AuthContext);

    const [ token, setUser ]= useState(context.user?.accessToken);

    const [ tempValue, setTempValue ] = useState<any>(info);
    const [ enableNext, setEnableNext ] = useState(false);
    const [ selectedViews, setSelectedViews ] = useState({
        layers: info?.layers, 
        complex: info?.complex,
        farms: info?.farms,
        site: info?.site
    });

    // const [ permissions, setPermissions ] = useState();

    const [ validValue, setValidValue ] = useState(false);

    const handlePermissionsLayers:DataGridProps["onSelectionModelChange"] = (value) => {
        setSelectedViews({...selectedViews, layers: value})
    }

    const handlePermissionsComplex:DataGridProps["onSelectionModelChange"] = (value) => {

        setSelectedViews({...selectedViews, complex: value})

    }

    const handlePermissionsFarms:DataGridProps["onSelectionModelChange"] = (value) => {
        setSelectedViews({...selectedViews, farms: value})
    }

    const handlePermissionsSites:DataGridProps["onSelectionModelChange"] = (value) => {
        setSelectedViews({...selectedViews, site: value})
    }

    // useEffect(()=> {
    //     setPermissions(mockPermissions)
    // }, [])

    useEffect(() => {
        if(tempValue!==undefined){
            setTempValue({...tempValue, 
                ...selectedViews
            })
        }

        if(selectedViews.complex?.length || 
            selectedViews.farms?.length ||
            selectedViews.site?.length ||
            selectedViews.layers?.length){
                setValidValue(true)
            }else{
                setValidValue(false)
            }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedViews])

    useEffect(() => {
        if(validValue) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [validValue])

    const [layers, setLayers] = useState(undefined);
    const [complex, setComplex] = useState<Array<Complex>|undefined>(undefined);
    const [farms, setFarms] = useState<Array<Farms>|undefined>(undefined);
    const [sites, setSites] = useState<Array<any>|undefined>(undefined); 

    /**Select farms by complex */
    useEffect(()=>{

        const filteredFarms = farms?.filter((farm:any)=>{

            return selectedViews.complex?.indexOf(farm.complexId) !== -1
        })

        const selectFarms = filteredFarms?.map((farm)=>{
            return farm.id;
        })

        setSelectedViews({...selectedViews, farms: selectFarms})
            

    }, [selectedViews["complex"], farms])

    /**Select complex by farms */
    useEffect(()=>{

        // console.log(selectedViews.farms);
        // console.log(complex)

        const farmsSelected = farms?.filter((farm:any)=>{
            return selectedViews.farms?.indexOf(farm.id)!==-1
        })
        
        const complexAllSelected = complex?.filter((complex:any)=>{

            const farmsSelectedByComplex = farmsSelected?.filter((farm:any)=>{ return farm.complexId === complex.id})

            if(farmsSelectedByComplex?.length === complex.farms.length){
                return true; 
            }else{
                return false; 
            }

        })

        const selectedComplex = complexAllSelected?.map((complex)=>{
            return complex.id
        })

        if(selectedViews.complex?.length!==undefined && (selectedComplex && selectedComplex?.length > selectedViews.complex?.length)){
            setSelectedViews({...selectedViews, complex: selectedComplex})
        }

    }, [selectedViews["farms"]])

    const [loading, setLoading] = useState(false);

    useEffect(()=> {

        const endpointLayers = process.env.REACT_APP_AUTH_SERVICE+'/api/layers';
        const endpointComplex = process.env.REACT_APP_AUTH_SERVICE+'/api/complex';
        const endpointFarms = process.env.REACT_APP_AUTH_SERVICE+'/api/farms';
        const endpointAergs = process.env.REACT_APP_WEB_SERVICE+'/api/aergs/site/customers'; 

        async function contentLayers(){
            axios.get(endpointLayers, {
                params: {
                   ID: 12345
                },
                headers: {
                   Authorization: `Bearer ${token}`
                }
            })
            .then(function (response) {
                
                setLayers(response.data.map((layer:any)=> layer));

            })
            .catch(function (error) {
                console.log(error);
            });
        }

        async function contentComplex(){

            axios.get(endpointComplex, {
                     params: {
                        ID: 12345 
                     },
                     headers: {
                        Authorization: `Bearer ${token}`
                     }
                })
                .then(function (response) {

                    const complex:Array<Complex> = response.data.map((complex:Complex)=>{

                        const { id, name, farms } = complex;

                        return {
                            id,
                            name, 
                            farms
                        };

                    })
                    setComplex(complex); 
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        async function contentFarms(){

            axios.get(endpointFarms, {
                     params: {
                        ID: 12345 
                     },
                     headers: {
                        Authorization: `Bearer ${token}`
                     }
                })
                .then(function (response) {

                    const farms:Array<Farms> = response.data.map((farm:Farms)=>{

                        const { id, name, complex } = farm;

                        return{
                            id: id,
                            // key_big: key_big,
                            name: name, 
                            complexId: complex?.id
                        };

                    }); 

                    setFarms(farms); 
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        async function contentAergs() {

            axios.get(endpointAergs, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res)=>{

                const sites = res.data.map((site:any)=>{
                    return {
                        id: site.aerg1SiteId,
                        ...site
                    }
                })

                setSites(sites); 

                /**Last function */
                setLoading(true);

            })
            .catch(function (error) {
                console.log(error);
            });

        }
        
        contentLayers();
        contentComplex();
        contentFarms();
         /**Last function */
        contentAergs();

    }, [])

    useEffect(()=> {

        if(loading) {
            const allValues:any= {
                allLayers: layers,
                allFarms: farms, 
                allComplex: complex, 
                allSites: sites
            }
    
            setTempValue({...selectedViews, ...allValues })
        }

        return(()=>{
            setLoading(false);
        })

    }, [loading])

    console.log(layers)
    return (
        <Styled.Container>
            <div id='title-create-group'>Views Table</div>
            <Paper elevation={3} id="form-admin">
                <Grid container spacing={2}>
                    <Grid item xs>
                        <CollapseComponent 
                            title="Layers"
                            children={  layers ? 
                                        <Table 
                                            rows={layers} 
                                            columns={columnsLayers} 
                                            onSelectionModelChange={handlePermissionsLayers}
                                            selectionModel={selectedViews.layers}
                                            loading={layers === undefined}
                                        />
                                        :
                                        <CircularProgress />
                                    }
                            defaultState={true}
                        />
                        <Divider />
                        <CollapseComponent
                            title="Complex"
                            children={  complex ?
                                        <Table 
                                            loading={complex === undefined}
                                            rows={complex} 
                                            columns={columnsComplex} 
                                            onSelectionModelChange={handlePermissionsComplex}
                                            selectionModel={selectedViews.complex}
                                        />
                                        :
                                        <CircularProgress />
                                    }
                        />
                        <Divider />
                        <CollapseComponent
                            title="Farms"
                            children={  farms ?    
                                        <Table 
                                            loading={farms === undefined}
                                            rows={farms} 
                                            columns={columnsFarms} 
                                            onSelectionModelChange={handlePermissionsFarms}
                                            selectionModel={selectedViews.farms}
                                            complexSelected={selectedViews?.complex}
                                        />
                                        :
                                        <CircularProgress />
                                    }
                        />
                        <Divider />
                        <CollapseComponent
                            title="Wind Turbines"
                            children={  sites ?
                                        <Table 
                                            rows={sites} 
                                            columns={columnsAergs} 
                                            onSelectionModelChange={handlePermissionsSites}
                                            selectionModel={selectedViews.site}
                                            loading={sites === undefined}
                                        />
                                        :
                                        <CircularProgress />
                                    }
                        />
                    </Grid>
                </Grid>
            </Paper>
            <StepsController 
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                tempValue={tempValue}
            />
        </Styled.Container>
    );

}