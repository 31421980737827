import * as Styled from './styles';

import {
    Toolbar,
    Divider,
    IconButton,
    IconButtonProps
} from '@mui/material';

import { 
    ChevronLeft
} from '@mui/icons-material';

import { Logo } from '../Logo';
import { MenuAdminLogo } from '../MenuAdminLogo';
import { ListItemAdmin } from '../ListItemAdmin';
import { AdminFooter } from '../AdminFooter';

interface AdminDrawerProps{
    handleDrawerClose: IconButtonProps["onClick"]
}

export const AdminDrawer = ({
    handleDrawerClose
}:AdminDrawerProps) => {

    return (
        <Styled.Container>
            <Toolbar>
                <Styled.Logo>
                    <a href="/home">
                        <Logo whiteColor={true}/>
                    </a>
                </Styled.Logo>
                <IconButton
                    size="large"
                    edge="start"
                    aria-label="ChevronLeftIcon"
                    sx={{ mr: 2 }}
                    onClick={handleDrawerClose}
                >
                    <ChevronLeft />
                </IconButton>
            </Toolbar>  
            <MenuAdminLogo />
            <Divider />
            <div id="scroll">
                <ListItemAdmin />
            </div>
            <Styled.Footer>
                <Divider />
                <AdminFooter />
            </Styled.Footer>            
        </Styled.Container>
    );

}