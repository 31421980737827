import React, { createContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthListener } from '../../hooks';
import { Loading } from '../../templates/Loading';

import { auth } from "../../templates/Auth/config";
import axios from 'axios';

export interface User {
    displayName: string | null, 
    email: string | null, 
    emailVerified: boolean,
    photoURL: string | null, 
    uid: string, 
    phoneNumber: string | null,
    accessToken?: string
}

export interface Auth {
    loggedIn?: boolean; 
    checkingStatus: User | null;
    user: Function | null;
    sessionUser: any; 
}

const InitialContextUser:any = {
    loggedIn: false,
    checkingStatus: null,
    user:null,
    sessionUser: undefined
}

export const AuthContext = createContext(InitialContextUser);

export default function PrivateRoute({children}:{ children: JSX.Element }){

    const { loggedIn, checkingStatus, user, sessionUser, emailVerified } = useAuthListener();

    const location = useLocation();

    const [state, changeState] = useState<Auth>()

    const [docusign, setDocusign] = useState(false);
    
    useEffect(()=>{

        changeState({
            loggedIn,
            checkingStatus,
            user,
            sessionUser
        });

        if(!docusign && sessionUser && (!sessionUser?.ds_envelopeId || !sessionUser?.ds_completed))
            redirectToDocusign()

    }, [checkingStatus, loggedIn, sessionUser, user])

    //If the checking status, session user and user isn't loaded show the loading page
    if(checkingStatus || !sessionUser || !user ){
        return <Loading/>;
    }

    /**If the device isn't verified logout the user */
    if(!sessionUser?.device_verified){
        auth.signOut();
        return <Navigate to={"/login"} state={{ from: location }}/>
    }

    const redirectToDocusign = async () => {
        const endpoint = process.env.REACT_APP_AUTH_SERVICE + '/docusign/embedded-envelope'; 
        axios.get(endpoint, {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`
            }
        })
        .then(function (response) {

            const { url } = response.data; 

            window.location.href = url;

            console.log(true)

            setDocusign(true)
        })
        .catch(function (error) {
            auth.signOut();
        });
    }

    //If the docusign document isn't assigned redirect user to the docusign page
    if(!sessionUser?.ds_envelopeId || !sessionUser?.ds_completed){
        return <Loading/>;
    }

    
    if(loggedIn && emailVerified) {
        return (
            <AuthContext.Provider value={state}>
                {children}
            </AuthContext.Provider>
        ); 
    }

    return <Navigate to={"/login"} state={{ from: location }}/>

}