import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        #btn-remove{
            color: ${theme.colors.dark}
        }

        #btn-remove:disabled {
            color: ${theme.colors.gray}
        }
    `}
`;
