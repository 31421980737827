import * as Styled from './styles';

import {
    AppBar,
    Box,
    Toolbar,
    Divider,
    IconButton,
    Typography,
    IconButtonProps
} from '@mui/material';

import {
    Menu
} from '@mui/icons-material';

import { Logo } from '../Logo';
import { Navbar } from '../Navbar';
import { SearchArea } from '../SearchArea';
import { Userprofile } from '../Userprofile';
import { LanguageSelector } from '../LanguageSelector';
import { MenuMobile } from '../MenuMobile';

import useMediaQuery from '@material-ui/core/useMediaQuery';

/*
    Reference: https://mui.com/components/app-bar/
    Row-direction: https://stackoverflow.com/questions/35778855/material-ui-how-do-you-make-a-horizontal-list
*/

interface HeaderProps{
    open: boolean; 
    handleOpen?: IconButtonProps["onClick"];
    omitOpenButton?: boolean; 
}

export const Header = ({
    open,
    handleOpen,
    omitOpenButton
}:HeaderProps) => {

    const matches = useMediaQuery('(min-width:1280px)');

    return (
        <Styled.Container>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" className={open?'opened-menu':""}>
                    <Toolbar>
                        {!open?
                            <>
                                {!omitOpenButton?<IconButton
                                                    size="large"
                                                    edge="start"
                                                    color="inherit"
                                                    aria-label="menu"
                                                    sx={{ mr: 2 }}
                                                    onClick={handleOpen}
                                                >
                                                    <Menu />
                                                </IconButton>
                                                :
                                                <>
                                                </>}
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                    <a href="/home">
                                        <Styled.Logo>
                                            <Logo />
                                        </Styled.Logo>
                                    </a>
                                </Typography> 
                            </>
                            :
                            ""
                        }  
                        
                        {!matches?
                            <>
                                <Typography component="div" sx={{ flexGrow: 1 }}>
                                {" "}
                                </Typography> 
                                <MenuMobile />
                            </>
                        :
                        <>
                            <Typography component="div" sx={{ flexGrow: 4}}>
                                <Navbar />
                            </Typography> 
                            <SearchArea children="Search"/>
                            <Divider orientation="vertical" flexItem variant="middle"/>    
                            <Userprofile />
                            {/* <Divider orientation="vertical" flexItem variant="middle"/>     
                            <LanguageSelector /> */}
                        </>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        </Styled.Container>
    );

}