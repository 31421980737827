import { createGlobalStyle, css } from "styled-components";

export const GlobalStyled = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        ${({ theme })=> css`
            font-family: ${theme.fonts.family.default}!important;
        `}
    }

    html {
        /* font-size: 62.5%;  */
    }

    body{
        /* font-size: 10rem; */ 
        ${({ theme })=> css`
            background: ${theme.colors.mainBg};
        `}
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    #logo {
        max-width: 197px;
        margin: -45px
    }

    #btn-username{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    #btn-username > li{
        padding: 10px;
        width: 100%;   
        justify-content: center; 
    }

    #menu-mobile{
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    #menu-mobile > li{
        padding: 20px;
        width: 100%;   
        justify-content: center; 
    }
    
    #menu-mobile > a > li{
        padding: 20px;
        width: 100%;   
        justify-content: center; 
    }

    div > a {
        text-decoration: none;
        color: inherit;
        /* width: 100%;    */
        align-items: center; 
    }

    button > a {
        text-decoration: none;
        color: inherit;
        /* width: 100%;    */
        align-items: center; 
    }

    ul[aria-labelledby="demo-multiple-chip-label"]{
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    ul[aria-labelledby="demo-multiple-chip-label"] > li{
        justify-content: start;
        width: 100%;
        padding-left: 10px;
        height: 30px;
    }

    .MuiDialog-container > div{
        min-width: 500px;
    }

    .SortableList {
        color:inherit; 
    }

    .SortableHelper {
        box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 1), 0 -5px 5px -5px rgba(0, 0, 0, 1);
        background-color: 'white';
        cursor: row-resize;
        color: inherit; 
        position: 'absolute';
        z-index: 10000;
    }

    .SortableItem {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%; /* Value fixed in variable drawerWidth in /Navbar/styles.js */
        /* height: 60px; */
        padding: 20 0px;
        background-color: #FFF;
        ${({ theme })=> css`
            border-bottom: 2px solid #EFEFEF;
        `}    
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #333;
        font-weight: 500;
        ${({ theme })=> css`
            background: ${theme.colors.primary}; 
        `}
    }

    .DragIndicator{
        cursor: ns-resize;
        padding-right: 15px;
    }
`