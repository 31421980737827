import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Paper,
    Grid,
    CircularProgress,
    Divider
} from '@mui/material';

import axios from 'axios';

import { CollapseComponent } from '../CollapseComponent';
import { Table } from '../Table';
import { StepsController } from '../StepsController';
import { InfoSelectedRoles, Roles } from '../../models/Roles';

import { Permission } from '../../models/Permissions';
import { AuthContext } from '../../routes/admin';

const columns = [
    {
        field: 'id',
        headerName: 'id',
        width: 300,
        editable: false, 
        filterable: false
    },
    {
        field: 'resource',
        headerName: 'Resource',
        width: 300,
        editable: false, 
        filterable: false
    },
    {
        field: 'target',
        headerName: 'Target',
        width: 300,
        editable: false, 
        filterable: false
    },
    {
        field: 'verb',
        headerName: 'Verb',
        width: 300,
        editable: false, 
        filterable: false
    }
]

interface RoleRegistrationStepTwoProps {
    steps: Array<string>;
    hrefOrig: string; 
    activeStep: number; 
    setActiveStep: Function;
    info?: InfoSelectedRoles;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
}

export const RoleRegistrationStepTwo = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped
}:RoleRegistrationStepTwoProps) => {

    const context:any = useContext(AuthContext);

    const [ token, setUser ]= useState(context.user?.accessToken);

    const [ tempValue, setTempValue ] = useState<any>(info);
    const [ enableNext, setEnableNext ] = useState(false);
    const [ selectedPermissions, setSelectedPermissions ] = useState(info?.permissions);

    const [ validValue, setValidValue ] = useState(false);

    const handleGeneralPermissions = (value:any) => {
        setSelectedPermissions(value)
    }

    useEffect(() => {
        if(tempValue!==undefined){
            setTempValue({...tempValue, permissions: selectedPermissions})
        }

        if(selectedPermissions!==undefined){
            if(selectedPermissions.length > 0){
                setValidValue(true)
            }else{
                setValidValue(false)
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPermissions])

    useEffect(() => {
        if(validValue) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [validValue])

        
    const [ permissions, setPermissions ] = useState();
    
    useEffect(()=> {
    
        async function getPermissions(){
            const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/permissions';

            axios.get(endpoint, {
                    params: {
                        ID: 12345
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    setPermissions(response.data);
                    setTempValue({...tempValue, allPermissions: response.data})
                })
                .catch(function (error) {
                    alert("Error trying load permissions!");
                });
        }
        
        getPermissions();
        
    }, [])
    
    if(!permissions){
        <Styled.Container>
            <div id='title-create-group'>Permissions Table</div>
            <Grid container spacing={2}>
                <Grid item xs>
                    <CircularProgress color="inherit"/>
                </Grid>
            </Grid>
        </Styled.Container>
    }
    
    return (
        <Styled.Container>
            <div id='title-create-group'>Permissions Table</div>
            <Paper elevation={3} id="form-admin">
                <Grid container spacing={2}>
                    <Grid item xs>
                        <CollapseComponent 
                            title="List"
                            children={permissions?<Table 
                                                            rows={permissions} 
                                                            columns={columns} 
                                                            onSelectionModelChange={handleGeneralPermissions}
                                                            selectionModel={selectedPermissions}
                                                        />
                                                        :
                                                        <CircularProgress />
                                                    }
                            defaultState={true}
                        />
                        {/* <Divider />
                        <CollapseComponent
                            title="Lands"
                            children={permissions?.land?<Table 
                                                            rows={permissions.land} 
                                                            columns={columns} 
                                                            onSelectionModelChange={handlePermissionsLands}
                                                            selectionModel={selectedPermissions.land}
                                                        />
                                                        :
                                                        <CircularProgress />
                                    }
                        />
                        <Divider />
                        <CollapseComponent
                            title="Farms"
                            children={permissions?.farm?<Table 
                                                            rows={permissions?.farm} 
                                                            columns={columns} 
                                                            onSelectionModelChange={handlePermissionsFarms}
                                                            selectionModel={selectedPermissions.farm}
                                                        />
                                                        :
                                                        <CircularProgress />
                                                    }
                        />
                        <Divider />
                        <CollapseComponent
                            title="Contracts"
                            children={permissions?.contract?<Table 
                                                                rows={permissions.contract} 
                                                                columns={columns} 
                                                                onSelectionModelChange={handlePermissionsContracts}
                                                                selectionModel={selectedPermissions.contract}
                                                            />
                                                            :
                                                            <CircularProgress />
                                                        }
                        /> */}
                    </Grid>
                </Grid>
            </Paper>
            <StepsController 
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                tempValue={tempValue}
            />
        </Styled.Container>
    );

}