import * as Styled from './styles';

import {
    AddBoxOutlined,
    GroupAddOutlined,
    LibraryAddOutlined
} from '@mui/icons-material';

import {
    Button, ButtonProps
} from '@mui/material';

export interface CreateButtonProps {
    type: "groups"|"roles"|"views"|"layers",
    onClick?: ButtonProps["onClick"]
}

export const CreateButton = ({
    type,
    onClick
}:CreateButtonProps) => {

    const Icon = ({type}:CreateButtonProps) => {

        let icon; 

        switch(type){
            case 'groups': 
                icon = <GroupAddOutlined />
                break; 
            case 'roles': 
                icon = <AddBoxOutlined />
                break;
            case 'views':
                icon = <LibraryAddOutlined />
                break;
            default: 
                icon = <AddBoxOutlined />
        }

        return icon; 
    }

    return (
        <Styled.Container>
            <Button id="btn-create" onClick={onClick}>
                <Icon type={type} /> &nbsp; Create
            </Button>
        </Styled.Container>
    );
}