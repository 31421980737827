import { Navigate } from 'react-router-dom';
// import { FirebaseContainer } from '../../templates/Auth/FirebaseContainer';
import { useAuthListener } from '../../hooks';
import { Loading } from '../../templates/Loading';
import { SignIn } from '../../templates/SignIn';

export default function AuthRoute(props:any){

    const { loggedIn, checkingStatus, emailVerified, sessionUser } = useAuthListener();

    return (
        <>
            {
                checkingStatus && !sessionUser?
                    <Loading/>
                :
                    loggedIn && emailVerified && sessionUser?.device_verified?
                        <Navigate to={props.redirectTo} />
                        :
                        <SignIn />
            }
        </>

    )
}