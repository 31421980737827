import { useContext, useEffect, useState } from 'react';
import * as Styled from './styles';

import { GlobalMapContext } from '../../routes';

import {
    Toolbar,
    Divider,
    IconButton,
    IconButtonProps,
    Grid,
    FormGroup,
    FormControlLabel,
    Switch,
    Tab,
    Box
} from '@mui/material';

import { 
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';

import { 
    ChevronLeft,
} from '@mui/icons-material';

import { Logo } from '../../components/Logo';
import { SearchArea } from '../SearchArea';
import { Controller } from '../Controller';
import { ListLayers } from '../ListLayers';
import { GroupLayers } from '../GroupLayers';

import { LeafletDrawerController } from './leaflet-drawer-controller';

interface MapDrawerProps{
    handleDrawerClose: IconButtonProps["onClick"]
}

export const MapDrawer = ({
    handleDrawerClose
}:MapDrawerProps) => {

    const map:any = useContext(GlobalMapContext);

    const [value, setValue] = useState<'group'|'list'>();
    const [drawerController, setDrawerController] = useState<LeafletDrawerController>();
    const [createdClass, setCreatedClass] = useState(false);

    /** Method to administrate the creation of class */
    useEffect(()=>{

        if(map!==undefined && !createdClass && drawerController===undefined){
            setDrawerController(new LeafletDrawerController(map)); 
            setCreatedClass(true);
        }
        
    }, [createdClass, drawerController, map]);

    /** Method to save on the map the state of Tab Component */
    const handleChange = (event:any, newValue:'group'|'list') => {
        setValue(newValue);
        if(drawerController!==undefined){
            drawerController.setModeMap(newValue)
        }
    };

    /** Setting the initial value of mode map based on the configurations saved */
    useEffect(()=>{

        if(drawerController!==undefined){
            setValue(drawerController?.getModeMap());
        }

    }, [drawerController]); 

    const [onlyActives, setOnlyActives] = useState<boolean>();

    /** Method to save on the map the state of Switch component */
    const handleChangeActiveLayers = (e:any) => {

        if(drawerController!==undefined){
            drawerController.toggleOnlyActiveLayers(e.target.checked);
            setOnlyActives(e.target.checked);
        }

    }

    /**Setting the initial value of active layers */
    useEffect(()=>{

        if(drawerController!==undefined){
            setOnlyActives(drawerController?.getOnlyActiveLayers());
        }

    }, [drawerController]); 

    const [onlyFavorites, setOnlyFavorites] = useState<boolean>();

    /** Method to save on the map the state of Switch component */
    const handleChangeFavoriteLayers = (e:any) => {

        if(drawerController!==undefined){
            drawerController.toggleOnlyFavoriteLayers(e.target.checked);
            setOnlyFavorites(e.target.checked);
        }

    }

    /**Setting the initial value of favorite layers */
    useEffect(()=>{

        if(drawerController!==undefined){
            // console.log(drawerController?.getOnlyFavoriteLayers());
            setOnlyFavorites(drawerController?.getOnlyFavoriteLayers());
        }

    }, [drawerController]); 

    /**Search layers */
    const [search, setSearch] = useState<string>();

    const handleChangeSearch = (e:any) => {
        setSearch(e.target.value)
    }

    const clearSearch = () => [
        setSearch("")
    ]

    return (
        <Styled.Container>
            <Toolbar>
                <Styled.Logo>
                    <a href="/home">
                        <Logo whiteColor={true}/>
                    </a>
                </Styled.Logo>
                <IconButton
                    size="large"
                    edge="start"
                    aria-label="ChevronLeftIcon"
                    sx={{ mr: 2 }}
                    onClick={handleDrawerClose}
                >
                    <ChevronLeft />
                </IconButton>
            </Toolbar>  
            <SearchArea 
                children="Search layers"
                search={search}
                handleChangeSearch={handleChangeSearch}
                clearSearch={clearSearch}
            />
            <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" sx={{paddingLeft: '25px', paddingBottom: '10px'}}> 
                <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={onlyActives?onlyActives:false}
                                    onChange={handleChangeActiveLayers}
                                    size= {"small"}
                                />
                            } 
                            label={<div style={{fontSize: '15px'}}>Actives</div>}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={onlyFavorites?onlyFavorites:false}
                                    onChange={handleChangeFavoriteLayers}
                                    size= {"small"}
                                />
                            } 
                            label={<div style={{fontSize: '15px'}}>Favorites</div>}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <div id="basemap-control">
                <Divider />
                <Controller 
                    basemap
                    label="Basemap Control"
                />
                <Divider />
            </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value?value:"group"}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="tab-controller">
                                <Tab label="Group" value="group" />
                                <Tab label="List" value="list" />
                            </TabList>
                        </Box>
                        <TabPanel value="group" sx={{padding: '5px 3px'}}>
                            <div id="scroll">
                                <GroupLayers
                                    search={search}
                                    onlyActives={onlyActives?onlyActives:false}
                                    onlyFavorites={onlyFavorites?onlyFavorites:false}
                                />
                            </div> 
                        </TabPanel>
                        <TabPanel value="list" sx={{padding: '0 3px'}}>
                            <div id="scroll">
                                <ListLayers 
                                    search={search}
                                    onlyActives={onlyActives?onlyActives:false}
                                    onlyFavorites={onlyFavorites?onlyFavorites:false}
                                />
                            </div> 
                        </TabPanel>
                    </TabContext>
                </Box>
                    
        </Styled.Container>
    );

}