//@ts-nocheck
import React from 'react';
import { useInView } from 'react-intersection-observer';
import P from 'prop-types';
import { Container, SlideTextLeft, SlideTextRight, SlideTextLeft2 } from './styles';

import 'animate.css';

// import '../../styles/animations.css';

export default function TitleSideSlide(props:any) {
    const title_list = props.title_list;
    // const subtitle = props.subtitle;
    
    // const ref_title1 = useRef();
    // const ref_title2 = useRef();
    // const ref_title3 = useRef();
    // const isVisible_title1 = useOnScreen(ref_title1);
    // const isVisible_title2 = useOnScreen(ref_title2);
    // const isVisible_title3 = useOnScreen(ref_title3);

    const { ref, inView } = useInView({ triggerOnce: true });
    // const { ref_NoticiaItem2, isVisible_NoticiaItem2 } = useInView({ threshold: 0, });       

    // useEffect(() => {
    //     const handleScroll = () => {
    //         // console.log(window.scrollY);
    //         console.log(inView);

    //     }  

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

if (title_list.length === 1)
        return (
            <Container margin_left={props.margin_left} ref={ref}> 
                <SlideTextLeft color={props.color} className={inView ? 'animate__animated animate__slideInLeft' : ''} isTitle={props.isTitle}>{title_list[0]}</SlideTextLeft>
                {/* {subtitle && <SlideSubtitle>{subtitle}</SlideSubtitle>} */}
            </Container>
        );
    else if (title_list.length === 2)
        return (
            <Container margin_left={props.margin_left} ref={ref}> 
                <SlideTextLeft color={props.color} font_size1={props.font_size1} className={inView ? 'animate__animated animate__slideInLeft' : ''} isTitle={props.isTitle} mobile_first_title_font_size={props.mobile_first_title_font_size}>{title_list[0]}</SlideTextLeft>
                <SlideTextRight color={props.color} font_size2={props.font_size2} className={inView ? 'animate__animated animate__slideInRight' : ''} second_title_margin_top={props.second_title_margin_top} second_title_margin_left={props.second_title_margin_left} title={props.title} mobile_second_title_font_size={props.mobile_second_title_font_size} mobile_second_title_margin_left={props.mobile_second_title_margin_left}>{title_list[1]}</SlideTextRight>
                {/* {subtitle && <SlideSubtitle>{subtitle}</SlideSubtitle>} */}
            </Container>
        );
    else if (title_list.length === 3)
        return (
            <Container margin_left={props.margin_left} ref={ref}> 
                <SlideTextLeft color={props.color} className={inView ? 'animate__animated animate__slideInLeft' : ''} isTitle={props.isTitle} mobile_first_title_font_size={props.mobile_first_title_font_size}>{title_list[0]}</SlideTextLeft>
                <SlideTextRight color={props.color} className={inView ? 'animate__animated animate__slideInRight' : ''} isTitle={props.isTitle} second_title_margin_top={props.second_title_margin_top} second_title_margin_left={props.second_title_margin_left} title={props.title} mobile_second_title_font_size={props.mobile_second_title_font_size} mobile_second_title_margin_left={props.mobile_second_title_margin_left}>{title_list[1]}</SlideTextRight>
                <SlideTextLeft2 color={props.color} className={inView ? 'animate__animated animate__slideInLeft' : ''} isTitle={props.isTitle}>{title_list[2]}</SlideTextLeft2>
                {/* <SlideSubtitle>{subtitle}</SlideSubtitle> */}
            </Container>
        );
}

TitleSideSlide.propTypes = {
    title_list: P.array.isRequired,
    subtitle: P.string,
};