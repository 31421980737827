import { Box } from '@mui/material';
import './styles.css';

export const PageNotFound = () => {

    return (
        <div id="background">
            <div id="loading-container">
                <img src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}/background-images/color.svg`} style={{maxWidth:"250px" ,width:"auto", height:"auto", padding: "20px 30px"}} alt="Logo CdV" />
                <Box
                    sx={{
                        width: '100%',
                        paddingBottom: '20px',
                        fontSize: 15,
                        textAlign: 'center'
                    }}
                >
                    <h1
                        style={{
                            display: 'inline-block',
                            borderRight: '1px solid rgba(0, 0, 0,.3)',
                            margin: 0,
                            marginRight: '20px',
                            padding: '10px 23px 10px 0',
                            fontSize: '24px',
                            fontWeight: 500,
                            verticalAlign: 'top'
                        }}
                    >
                        404
                    </h1>
                    <div
                        style={{
                            display: 'inline-block',
                            textAlign: 'left',
                            lineHeight: '49px',
                            height: '49px',
                            verticalAlign: 'middle'
                        }}
                    >
                        <h2
                            style={{
                                fontSize: '14px',
                                fontWeight: 'normal',
                                lineHeight: 'inherit',
                                margin: 0,
                                padding: 0
                            }}
                        >
                            This page could not be found.
                        </h2>
                    </div>
                </Box>
            </div>
        </div>
    )

}