import React from 'react';
import * as Styled from './styles';

import {
    Button,
    ButtonProps
} from '@mui/material';

import {
    Add
} from '@mui/icons-material';

interface EditButtonProps {
    onClick?: ButtonProps["onClick"];
}

export const EditButton = ({
    onClick
}:EditButtonProps) => {
    
    return (
        <Styled.Container>
            <Button id="btn-create" onClick={onClick}>
                <Add/> &nbsp; Edit
            </Button>
        </Styled.Container>
    );

}