import * as Styled from './styles';

import {
    Box,
    Button
} from '@mui/material';

import {
    ModeEditOutlineOutlined
} from '@mui/icons-material';

export const EditProfile = () => {

    return (
        <Styled.Container>
            <Box component="div">
                <Button id="edit-profile" >
                    <ModeEditOutlineOutlined />
                    &nbsp; 
                    Edit Profile
                </Button>
            </Box>
        </Styled.Container>
    );

}

