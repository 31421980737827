// var stags=['conc','nova','opcli','lic','desist','desenv','emlic','leilao','viab','transf','constr','desenvo'];

declare const L:any; 

export class WindTurbinesMethods {

    public convertDataToFeatures (data:any) {

        const featuresByBoundingBox = data.map((aerg:any)=>{

            const geometry = aerg.aerg1Geom; 
            const type = "Feature";
            // const status = aerg.stag1Cod; 

            const properties = {
                id: aerg.aerg1Cod,
                maq: aerg.aerg1Maq,
                fc: aerg.aerg1Fc.toFixed(2),
                complex: aerg.aerg1CompName,
                project: aerg.aerg1Proj
                // status: status
            };

            const feature = {
                type: type,
                properties: properties,
                geometry: geometry
            };

            return feature;

        });

        return featuresByBoundingBox; 

    }

    /**This function is called as a callback in point to layer method */
    public iconTagByStatus(feature:any,latlng:any){

        // const status = feature.properties.status - 1; 

        const icon = L.icon({
            iconUrl: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/wind-turbines/images/14x20/aeg14x20-desenv.png`,
            iconSize: [14, 20],
            iconAnchor: [7, 20],
            popupAnchor: [7, 0]
        });

        return L.marker(latlng,{icon: icon, pane: 'aerg'});
    }

}