import { Button, Toolbar } from '@mui/material';
import React, {
    useState 
} from 'react';

import * as Styled from './styles';

import { 
    Document, 
    Page 
} from 'react-pdf';

interface DocumentPdfProps {
    pdfUrl: string;
}

export const DocumentPdf = ({
    pdfUrl
}:DocumentPdfProps) => {

    const [numPages, setNumPages] = useState<number|null>(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }:any) => {
        setNumPages(numPages);
    }

    function changePage(offset:number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const [pageScale, setPageScale] = useState<number>(1.5);

    function lessScale() {
        if(pageScale > 0.4)
            setPageScale(prevPageScale =>prevPageScale - 0.1)
    }

    function plusScale() {
        if(pageScale < 2)
            setPageScale(prevPageScale =>prevPageScale + 0.1)
    }
    
    return (
        <Styled.Background>
            <Toolbar />
            <Styled.Document>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} options={{ workerSrc: "/pdf.worker.js" }}>
                    <Page 
                        pageNumber={pageNumber} 
                        scale={pageScale}
                    />
                    <Styled.PageControls
                        className='document-controller-top'
                    >
                        <Button type="button" disabled={(pageScale * 100).toFixed(0) === '80'} onClick={lessScale}>
                            -
                        </Button>
                        <span>
                            {(pageScale * 100).toFixed(0)} %
                        </span>
                        <Button
                            type="button"
                            disabled={((pageScale * 100).toFixed(0) === '200')}
                            onClick={plusScale}
                        >
                            +
                        </Button>
                    </Styled.PageControls>
                    <Styled.PageControls
                        className='document-controller-bottom'
                    >
                        <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                            {"<"}
                        </Button>
                        <span>
                            {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                        </span>
                        <Button
                            type="button"
                            disabled={numPages !== null && (pageNumber >= numPages)}
                            onClick={nextPage}
                        >
                            {">"}
                        </Button>
                    </Styled.PageControls>
                </Document>
            </Styled.Document>
        </Styled.Background>
    );

}