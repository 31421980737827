import { useState, useEffect, useContext } from 'react';
import * as Styled from './styles';

import {
    Box,
    Grid,
    Paper,
    Typography,
    List,
    ListItem, 
    Divider
} from '@mui/material';

import { Navigate } from 'react-router-dom';

import axios from 'axios';
import { InfoSelectedRoles } from '../../models/Roles';
import { AuthContext } from "../../routes/admin";
import { CollapseComponent } from '../CollapseComponent';
import { StepsController } from '../StepsController';

interface RoleRegistrationStepThreeProps {
    steps: Array<string>;
    hrefOrig: string;
    activeStep: number; 
    setActiveStep: Function;
    info?: InfoSelectedRoles;
    setInfo: Function;
    skipped: Set<any>;
    setSkipped: Function;
    id: string|null; 
    edit: boolean;
}

export const RoleRegistratioStepThree = ({
    steps,
    hrefOrig,
    activeStep,
    setActiveStep,
    info,
    setInfo,
    skipped,
    setSkipped,
    id,
    edit
}:RoleRegistrationStepThreeProps) => {

    const context:any = useContext(AuthContext);

    const [ token, setUser ]= useState(context.user?.accessToken);

    const [ tempValue, setTempValue ] = useState<any>(info);
    const [ enableNext, setEnableNext ] = useState(false);

    const [roles, setRoles] = useState();
    const [views, setViews] = useState();
    const [redirect, setRedirect] = useState(false);
    const [sentRequest, setSentRequest] = useState(false);

    const filteredPermissions = tempValue?.allPermissions.filter((onePermission:any)=>tempValue?.permissions?.indexOf(onePermission.id)!==-1);

    async function sendRequestCreate (){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles';

        if(!sentRequest){
            setSentRequest(true);
            axios.post(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a view!")
                })
            ;
        }
    }

    async function sendRequestUpdate(){

        const endpoint = process.env.REACT_APP_AUTH_SERVICE+'/api/roles/'+id;

        if(!sentRequest){
            setSentRequest(true);

            axios.put(endpoint, {
                        ...info
                    },
                    {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(function (response) {
                    setRedirect(true)
                })
                .catch(function (error) {
                    alert("Error trying to insert a view!")
                    setSentRequest(false)
                });
        }
    }

    return (
        <Styled.Container>
            <Box
                component="form"
                autoComplete="off"
                noValidate
            >
            <div id='title-create-group'>Review your created role</div>
            <Paper elevation={3} id="form-admin">
               <Grid container spacing={2}>
                    <Grid item xs>
                        <Typography gutterBottom component="div">
                            General Info
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            ID: {tempValue?.id}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Title: {tempValue?.title}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Description: {tempValue?.description}
                        </Typography>
                        <div id="space"/>
                        <Divider />
                        <div id="space"/>
                        <Typography gutterBottom component="div">
                            Permissions
                        </Typography>
                        <CollapseComponent 
                            title="List"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {filteredPermissions!==undefined&&filteredPermissions.length>0?filteredPermissions?.map((permission:any)=>{
                                                return <ListItem>{`${permission.target}.${permission.resource}.${permission.verb}`}</ListItem>
                                            }):
                                            <ListItem>No permissions found</ListItem>
                                            }
                                        </List>}                     
                        />
                        {/* <CollapseComponent 
                            title="Lands"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {tempValue.permissions.land.length>0?tempValue.permissions.land.map((permission)=>{
                                                return <ListItem>{permission}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />
                        <CollapseComponent 
                            title="Farms"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {tempValue.permissions.farm.length>0?tempValue.permissions.farm.map((permission)=>{
                                                return <ListItem>{permission}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />
                        <CollapseComponent 
                            title="Owners"
                            defaultState={true}
                            children={  <List
                                            sx={{ width: '100%', fontSize: '15px' }}
                                        >
                                            {tempValue.permissions.owner.length>0?tempValue.permissions.owner.map((permission)=>{
                                                return <ListItem>{permission}</ListItem>
                                            }):<ListItem>No permissions found</ListItem>}
                                        </List>}
                        />  */}
                    </Grid>
                </Grid> 
            </Paper>
            </Box> 
            <StepsController 
                edit={edit}
                steps={steps}
                hrefOrig={hrefOrig}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                info={info}
                setInfo={setInfo}
                skipped={skipped}
                setSkipped={setSkipped}
                enableNext={enableNext}
                enableCreate={!redirect}
                tempValue={tempValue}
                sendRequestCreate={edit?sendRequestUpdate:sendRequestCreate}
            />
            {redirect?
                <Navigate to={hrefOrig}/>
                :
                ""
            }
        </Styled.Container>
    );

}