import * as Styled from './styles';

import { 
    AdminPanelSettingsOutlined
} from '@mui/icons-material';

export const MenuAdminLogo = () => {
    return (
        <Styled.Container>
            <AdminPanelSettingsOutlined /> 
            <span>Admin</span>
        </Styled.Container>
    );
};
