import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        span{
            font-size: ${theme.fonts.sizes.small};
            color: ${theme.colors.dark};
        }

        svg{
            transform: scale(1.5);
            padding-right: 15px;
            color: ${theme.colors.dark};
        }
    `}
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 20px 30px 20px;
`;
